import actions from "@/store/modules/student/actions";
import mutations from "@/store/modules/student/mutations";
import getters from "@/store/modules/student/getters";

export default {
  namespaced: true,
  state() {
    return {};
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
};
