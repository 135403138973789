<template>
  <v-container>
    <form @submit.prevent="handleUpdateCard">
      <v-overlay
        :value="loading || parentLoading"
        class="align-center justify-center"
        style="z-index: 10"
        absolute
        opacity="0.1"
      >
        <v-progress-circular
          color="primary"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <div
        class="pa-1 x-mark d-flex"
        :class="{
          mobile: $vuetify.breakpoint.smAndUp,
          isFromHistory: isFromHistory,
        }"
      >
        <v-icon
          v-if="isFromHistory"
          color="black"
          size="18"
          @click="$router.go(-1)"
          >mdi-skip-backward</v-icon
        >
        <img
          v-else
          @click="$emit('close')"
          src="@/assets/icons/cancel.svg"
          :class="isFromHistory && 'isFromHistory'"
        />
      </div>
      <v-container class="form-input-wrap">
        <!-- SELECT CARD GROUP & CARD TYPE -->
        <v-row>
          <v-col
            v-if="isProCard"
            md="4"
            sm="4"
            class="pb-0 px-1"
            :class="isProCard && $vuetify.breakpoint.mdAndDown && 'w-33'"
          >
            <v-select
              :items="PRO_FOLDER_LIST"
              item-text="folderName"
              item-value="folderId"
              v-model="selectedFolderId"
              label="Chọn thư mục"
              variant="solo"
            ></v-select>
          </v-col>
          <v-col
            :md="isProCard ? 4 : 6"
            :sm="isProCard ? 4 : 6"
            class="pb-0 px-1"
            :class="isProCard && $vuetify.breakpoint.mdAndDown && 'w-33'"
          >
            <v-select
              :items="groupCardList"
              item-text="groupName"
              item-value="id"
              v-model="groupCardIdSelected"
              label="Chọn bộ thẻ"
              variant="solo"
            ></v-select>
          </v-col>
          <v-col
            :md="isProCard ? 4 : 6"
            :sm="isProCard ? 4 : 6"
            class="pb-0 px-1"
          >
            <v-select
              :items="cardTypes"
              v-model="formValue.typeCard"
              label="Chọn kiểu thẻ"
              variant="solo"
            ></v-select>
          </v-col>
        </v-row>
        <!-- End of SELECT CARD GROUP & CARD TYPE -->

        <v-row class="ma-0">
          <v-col cols="12" md="12" class="pl-0">
            <h6>Từ</h6>
            <div class="mt-1">
              <ckeditor
                :editor="editor.editorForWord"
                v-model="formValue.wordFormatted"
                :config="editorConfig"
                @ready="(editor) => onEditorReady(editor, 'word')"
              ></ckeditor>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="formValue.typeCard == 1 || formValue.typeCard == 4"
          class="ma-0"
        >
          <v-col cols="12" md="12" class="pl-0">
            <h6>Phiên âm</h6>
            <div class="mt-1">
              <input
                type="text"
                :class="$vuetify.breakpoint.smAndDown ? 'smd' : ''"
                class="border-focus-blue"
                v-model.trim="formValue.spelling"
                autoComplete
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" md="12" class="pl-0">
            <h6>Nghĩa</h6>
            <div class="mt-1">
              <ckeditor
                :editor="editor.editorForMean"
                v-model="formValue.meanFormatted"
                :config="editorConfig"
                @ready="(editor) => onEditorReady(editor, 'mean')"
              ></ckeditor>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="formValue.typeCard == 1 || formValue.typeCard == 4"
          class="ma-0"
        >
          <v-col cols="12" md="12" class="pl-0">
            <h6>Audio</h6>
            <div class="mt-1">
              <v-file-input
                ref="fileInput"
                chips
                show-size
                truncate-length="25"
                accept="audio/*"
                label="Tải lên file audio thay thế"
                v-model="formValue.audio"
                @change="changeAudioFile"
              ></v-file-input>
            </div>
            <div>
              <audio
                controls
                id="audio"
                :src="formValue.audioLink"
                type="audio/*"
              >
                Your browser does not support the audio element.
              </audio>
              <p class="audio-name">{{ audioName }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="formValue.typeCard == 1 || formValue.typeCard == 4"
          class="ma-0"
        >
          <v-col cols="12" md="12" class="pl-0">
            <h6>Từ loại</h6>
            <div class="mt-1">
              <input
                type="text"
                :class="$vuetify.breakpoint.smAndDown ? 'smd' : ''"
                class="border-focus-blue"
                v-model.trim="formValue.wordType"
                autoComplete
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" md="12" class="pl-0">
            <h6>Ví dụ</h6>
            <div class="mt-1">
              <ckeditor
                :editor="editor.editorForExample"
                v-model="formValue.exampleFormatted"
                :config="editorConfig"
                @ready="(editor) => onEditorReady(editor, 'example')"
                class="textarea"
              ></ckeditor>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-row
        v-if="showSubmitBtn"
        class="d-flex ps-rlt"
        style="margin-bottom: 10px"
      >
        <v-col cols="12" md="6" sm="6" class="pb-0 d-flex justify-center">
          <ButtonComponent
            :disable="!isActiveSubmitBtn"
            title="Update"
            type="submit"
          />
        </v-col>
        <v-col cols="12" md="6" sm="6" class="pb-0 d-flex justify-center">
          <ButtonComponent
            :disable="!isActiveSubmitBtn"
            title="Preview"
            @click="preview"
          />
        </v-col>
        <div
          v-if="showPreview"
          class="ps-abs preview-pc"
          v-click-outside="
            () => {
              showPreview = false;
            }
          "
        >
          <CardPreviewContent :currentCard="formValue" :formType="'EDIT'" />
        </div>
      </v-row>
    </form>
  </v-container>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import viewToPlainText from "@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { formatBreakLineInEditor } from "@/uttil";
import { FIELDS_NEED_EDITOR, PRO_FOLDER_LIST } from "@/constants/index";
import CardPreviewContent from "@/components/CardPreviewContent.vue";

export default {
  components: { ButtonComponent, CardPreviewContent },
  data() {
    return {
      loading: false,
      cardTypes: [
        { value: 1, text: "Kiểu 1" },
        { value: 2, text: "Kiểu 2" },
        { value: 3, text: "Kiểu 3" },
      ],
      groupCardIdSelected: 0,
      formValue: {
        typeCard: 1,
        word: "",
        wordFormatted: "",
        spelling: null,
        mean: "",
        meanFormatted: "",
        audio: null,
        wordType: null,
        example: "",
        exampleFormatted: "",
      },
      formValueDefault: {
        typeCard: 1,
        word: "",
        wordFormatted: "",
        spelling: null,
        mean: "",
        meanFormatted: "",
        audio: null,
        wordType: null,
        example: "",
        exampleFormatted: "",
      },
      editor: {
        editorForWord: Editor,
        editorForMean: Editor,
        editorForExample: Editor,
      },
      editorInstance: {
        word: null,
        mean: null,
        example: null,
      },
      editorConfig: {},
      audioName: "",
      fieldsNeedEditor: FIELDS_NEED_EDITOR,
      PRO_FOLDER_LIST,
      showPreview: false,
      selectedFolderId: null,
    };
  },
  props: {
    folderId: {
      type: [String, Number],
      default: "",
    },
    currentCardSetId: {
      type: [String, Number],
      default: "",
    },
    selectedCardId: {
      type: [String, Number],
      default: "",
    },
    selectedStudentId: {
      type: [String, Number],
      default: "",
    },
    isProCard: {
      type: Boolean,
      default: false,
    },
    showSubmitBtn: {
      type: Boolean,
      default: true,
    },
    parentLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("admin", ["getCardSets", "getAllProGroupCard"]),
    ...mapState("card", ["cardDetail"]),
    isFromHistory() {
      return this.$route.name === "browse" && this.$route.query.detailCardId;
    },
    isPro() {
      return this.$route.query.tab === "manage-pro-card-set";
    },
    groupCardList() {
      return this.isProCard || this.isPro
        ? this.getAllProGroupCard(
            this.selectedFolderId,
            this.$route.params.studentId || this.selectedStudentId
          )
        : this.getCardSets;
    },
    // TODO
    isActiveSubmitBtn() {
      return !!this.groupCardIdSelected;
    },
    hasChanged() {
      return Object.keys(this.formValue).some(
        (field) => this.formValue[field] !== this.defaultFormValue[field]
      );
    },
    isTypeCard2() {
      return this.cardDetail.typeCard == 4 || this.cardDetail.typeCard == 5;
    },
  },
  async created() {
    this.loading = true;
    this.isProCard
      ? await this.fetchProClasses()
      : await this.fetchCardSets(this.folderId || this.cardDetail.folderId);
    this.formValue = {
      ...this.cardDetail,
      typeCard: this.isTypeCard2
        ? this.cardDetail.typeCard == 4
          ? 1
          : 2
        : this.cardDetail.typeCard,
    };
    if (this.formValue.audioLink)
      this.audioName = this.formValue.audioLink?.split("/").at(-1);
    this.groupCardIdSelected = this.formValue.groupCardId;
    this.selectedFolderId = this.folderId || this.cardDetail.folderId;
    this.loading = false;
  },
  beforeDestroy() {
    if (this.$route.name !== "browse") this.SET_CARD_DETAIL({});
  },
  watch: {
    selectedCardId: {
      handler: function () {
        this.formValue = {
          ...this.cardDetail,
          typeCard: this.isTypeCard2
            ? this.cardDetail.typeCard == 4
              ? 1
              : 2
            : this.cardDetail.typeCard,
        };
        if (this.formValue.audioLink)
          this.audioName = this.formValue.audioLink?.split("/").at(-1);
        this.groupCardIdSelected = this.formValue.groupCardId;
        this.selectedFolderId = this.formValue.folderId;
      },
      immediately: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions("card", ["updateCardDetail", "fetchAllCards"]),
    ...mapActions("student", ["updateProCardDetail"]),
    ...mapActions("admin", ["fetchCardSets", "fetchProClasses"]),
    ...mapMutations("card", ["SET_CARD_DETAIL"]),
    onEditorReady(editor, fieldName) {
      this.editorInstance[fieldName] = editor;
      // if (this.$route.query.detailCardId) {
      //   setTimeout(() => {
      //     console.log(this.editorInstance.word);
      //     this.editorInstance.word.editing.view.focus();
      //   }, 2000);
      // }
    },
    async handleUpdateCard() {
      this.loading = true;
      this.fieldsNeedEditor.forEach((fieldName) => {
        this.formValue[`${fieldName}Formatted`] = formatBreakLineInEditor(
          this.formValue[`${fieldName}Formatted`]
        );
        this.formValue[fieldName] = formatBreakLineInEditor(
          viewToPlainText(
            this.editorInstance[fieldName].editing.view.document.getRoot()
          )
        );
      });
      const newTypeCard =
        this.isTypeCard2 && !this.isProCard
          ? this.cardDetail.typeCard
          : this.formValue.typeCard;
      let formData = new FormData();
      for (const field in this.formValueDefault) {
        formData.append(
          field,
          field === "typeCard" ? newTypeCard : this.formValue[field]
        );
      }
      this.formValue.groupCardId = this.groupCardIdSelected;
      formData.append("groupCardId", this.groupCardIdSelected);
      this.isPro || this.isProCard
        ? await this.updateProCardDetail({
            id: this.selectedCardId,
            form: formData,
          })
        : await this.updateCardDetail({
            id: this.selectedCardId,
            form: formData,
          });
      if (this.isProCard) {
        this.$emit("updateProCard");
        return;
      }
      formData.delete("groupCardId");
      await this.fetchAllCards({
        id: this.currentCardSetId,
        isPro: this.isPro,
      });
      this.loading = false;
    },
    changeAudioFile() {
      let media = URL.createObjectURL(this.formValue.audio);
      let audio = document.getElementById("audio");
      audio.src = media;
      this.audioName = this.formValue.audio.name;
      this.formValue.audioLink = media;
    },
    preview() {
      this.fieldsNeedEditor.forEach((fieldName) => {
        this.formValue[`${fieldName}Formatted`] = formatBreakLineInEditor(
          this.formValue[`${fieldName}Formatted`]
        );
        this.formValue[fieldName] = formatBreakLineInEditor(
          viewToPlainText(
            this.editorInstance[fieldName].editing.view.document.getRoot()
          )
        );
      });
      this.showPreview = !this.showPreview;
    },
  },
  mounted() {
    const handleKeyPressed = (e) => {
      if (this.showPreview) {
        e.preventDefault();
        return;
      }
      if (e.key === "Enter" && e.ctrlKey) {
        this.handleUpdateCard();
      }
      if (e.key === "F3" || e.keyCode === 114) {
        e.preventDefault();
        const fileInput = this.$refs.fileInput;
        if (fileInput) {
          fileInput.$refs.input.click();
        }
      }
    };
    window.addEventListener("keydown", handleKeyPressed);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("keydown", handleKeyPressed);
    });
  },
};
</script>

<style lang="scss" scoped>
.preview-pc {
  z-index: 100;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);
}
form {
  padding-bottom: 2px;
  display: block;
  position: relative;
  border: 2px solid transparent;

  .x-mark {
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 12px;
    top: 0px;
    gap: 10px;
    padding-top: 0px !important;
    &.mobile {
      right: 8px;
      img {
        width: 15px;
        height: 15px;
      }
    }
    &.isFromHistory {
      top: -7px;
    }
    img {
      cursor: pointer;
      width: 10px;
      height: 10px;
      &.isFromHistory {
        width: 13px;
        height: 13px;
      }
    }
  }
}

.form-input-wrap {
  max-height: calc(100vh - 277px);
  overflow: auto;
  margin-bottom: 10px;
  padding: 0 25px;
  margin-top: 12px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #bdb8b8;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #726f6f;
    border-radius: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.border-focus-blue:focus-visible {
  border-color: hsl(218, 81.6%, 56.9%) !important;
  outline: none !important;
}

.audio-name {
  font-size: 12px;
  margin-bottom: 0px;
  margin-left: 10px;
}
</style>

<style lang="scss">
.ck.ck-editor__editable_inline {
  border: 1px solid #d9dfea !important;
  background-color: #f9fbfc;
  border-radius: 12px !important;

  &:focus-visible {
    border-color: hsl(218, 81.6%, 56.9%) !important;
    outline: none !important;
  }

  &.textarea {
    height: 120px;
  }
}
</style>

<style lang="sass" scoped>
::v-deep .v-btn
  min-width: 150px !important
input
  width: 100%
  padding: 16px 0 16px 16px
  border: 1px solid #D9DFEA
  border-radius: 12px
  background-color: #F9FBFC
  min-width: 305px
  &.smd
    min-width: unset
</style>
