<template>
  <div class="container">
    <div
      class="d-flex folder-table-header"
      @click="showDropdownStudentList = !showDropdownStudentList"
    >
      <div
        v-text="currentStudentName === '' ? 'Chọn bộ thẻ' : currentStudentName"
      ></div>
      <img :src="getImgUrl('icons/arrow-down.svg')" />
    </div>
    <transition name="fade">
      <div v-if="showDropdownStudentList" class="folder-table-list">
        <div class="folder-table-row mt-4">
          <v-text-field
            v-model.trim="searchText"
            label="Nhập tên học sinh cần tìm kiếm..."
            class="border-focus-blue ma-2 w-100"
            @input="$emit('searchStudent', searchText)"
          ></v-text-field>
        </div>
        <div
          v-for="student in students"
          :key="student.id"
          :title="student.fullName"
          class="folder-table-row cursor-pointer"
          @click="chooseStudent(student.id, student.fullName, student.folders)"
        >
          <p class="folder-name text-overflow">
            {{ student.fullName }}
          </p>
        </div>
        <div v-if="!students.length" class="folder-table-row">
          <p class="folder-name text-overflow">Không có học sinh phù hợp!</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDropdownStudentList: true,
      currentStudentName: "",
      searchText: "",
    };
  },
  props: {
    classId: {
      type: [String, Number],
    },
    students: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    classId: {
      handler() {
        this.currentStudentName = "Danh sách học sinh";
      },
      immediate: true,
    },
  },
  methods: {
    async chooseStudent(id, name, folders) {
      this.currentStudentName = name;
      this.$emit("chooseStudent", { id, folders });
      this.showDropdownStudentList = false;
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 8px 0 0;
  position: relative;
}
.folder-table-header {
  background-color: #453fe3;
  height: 48px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 10px;
  cursor: pointer;
}

.folder-table-list {
  position: absolute;
  background: white;
  border: 1.5px solid #453fe3;
  border-top: none;
  border-radius: 4px;
  width: 100%;
  max-height: 70vh;
  overflow: auto;
  z-index: 2;
  .folder-table-row {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    &.cursor-pointer:hover {
      background-color: #ccc;
    }
    .folder-name {
      margin: 0;
      padding: 0 10px;
    }
  }
}
</style>
