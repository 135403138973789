<template>
  <v-row justify="center">
    <v-dialog
      :value="showDialogValue"
      content-class="assign-dialog"
      max-width="700"
      persistent
      @click:outside="handleClickOutside"
    >
      <v-card class="pa-8">
        <v-card-text class="pa-0 mb-7">
          <!-- SELECT CARD GROUP & CARD TYPE -->
          <v-row>
            <v-col
              md="4"
              sm="4"
              class="pb-0 px-1"
              :class="$vuetify.breakpoint.mdAndDown && 'w-33'"
            >
              <v-select
                :items="PRO_FOLDER_LIST"
                item-text="folderName"
                item-value="folderId"
                v-model="selectedFolderId"
                label="Chọn thư mục"
                variant="solo"
              ></v-select>
            </v-col>
            <v-col
              md="4"
              sm="4"
              class="pb-0 px-1"
              :class="$vuetify.breakpoint.mdAndDown && 'w-33'"
            >
              <v-select
                :items="groupCardList"
                item-text="groupName"
                item-value="groupCardId"
                v-model="selectedGroupCardId"
                label="Chọn bộ thẻ"
                variant="solo"
              >
                <!-- <template v-if="$isProStudent" v-slot:prepend-item>
                  <input
                    type="text"
                    :class="$vuetify.breakpoint.smAndDown ? 'smd' : ''"
                    class="border-focus-blue ma-2 w-auto"
                    v-model.trim="newProCardGroup"
                    autoComplete
                    placeholder="Nhập tên bộ thẻ..."
                    @blur="newProCardGroupInputFocus = false"
                    @focus="newProCardGroupInputFocus = true"
                  />
                  <v-divider class="mt-2"></v-divider>
                </template> -->
              </v-select>
            </v-col>
            <v-col
              md="4"
              sm="4"
              class="pb-0 px-1"
              :class="$vuetify.breakpoint.mdAndDown && 'w-33'"
            >
              <v-select
                :items="cardTypes"
                v-model="cardTypeSelected"
                label="Chọn kiểu thẻ"
                variant="solo"
              ></v-select>
            </v-col>
          </v-row>
          <!-- End of SELECT CARD GROUP & CARD TYPE -->
        </v-card-text>
        <v-card-actions class="d-flex align-center pa-0">
          <v-spacer></v-spacer>
          <v-btn class="cancel-btn" elevation="0" @click="$emit('closeDialog')">
            <h6>Hủy</h6>
          </v-btn>

          <ButtonComponent
            title="Ok"
            class="ml-3"
            @click="showDialogConfirm = true"
          />
        </v-card-actions>
      </v-card>
      <DialogComponent
        v-if="showDialogConfirm"
        typeDialog="confirm"
        description="Bạn chắc chắn thay đổi?"
        :showDialogValue="showDialogConfirm"
        @closeDialog="showDialogConfirm = false"
        @confirmRequest="handleConfirmRequest"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import DialogComponent from "@/components/ui/DialogComponent.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { PRO_FOLDER_LIST } from "@/constants/index";
export default {
  components: { ButtonComponent, DialogComponent },
  props: {
    showDialogValue: {
      type: Boolean,
      default: false,
    },
    detailCardIds: {
      type: Array,
      default: () => [],
    },
    studentId: {
      type: String || Number,
      default: "",
    },
  },
  data() {
    return {
      showDialogConfirm: false,
      PRO_FOLDER_LIST,
      selectedFolderId: PRO_FOLDER_LIST[1].folderId,
      selectedGroupCardId: "",
      cardTypes: [
        { value: 1, text: "Kiểu 1" },
        { value: 2, text: "Kiểu 2" },
        { value: 3, text: "Kiểu 3" },
      ],
      cardTypeSelected: 1,
    };
  },
  computed: {
    ...mapGetters("list", [
      "getListCardGroupInFolder",
      "studentGetAllProGroupCard",
    ]),
    ...mapGetters("admin", []),
    ...mapState("admin", []),
    groupCardList() {
      const arr = this.selectedFolderId
        ? this.getListCardGroupInFolder(this.selectedFolderId)
        : this.studentGetAllProGroupCard;
      return arr;
    },
  },
  methods: {
    ...mapActions("admin", ["editMultipleProCard"]),
    async handleConfirmRequest() {
      await this.editMultipleProCard({
        folderId: this.selectedFolderId,
        groupCardId: this.selectedGroupCardId,
        typeCard: this.cardTypeSelected,
        detailCardIds: this.detailCardIds,
        userId: this.studentId,
      });
      this.showDialogConfirm = false;
      this.$emit("confirmRequest");
    },
    handleClickOutside(e) {
      e.preventDefault();
      if (e.target.className.includes("v-overlay__scrim")) {
        this.showDialogConfirm = true;
      } else return;
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .assign-dialog,
::v-deep .v-window {
  overflow: visible !important;
}
</style>

<style lang="sass" scoped>
.cancel-btn
  background-color: transparent !important
  text-transform: initial
  color: #453FE3
.v-card
  border-radius: 12px !important
</style>
