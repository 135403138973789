<template>
  <div class="container">
    <div class="d-flex class-table-header" @click="toggleClassList()">
      <div v-text="className === '' ? 'Danh sách lớp' : className"></div>
      <img :src="getImgUrl('icons/arrow-down.svg')" />
    </div>
    <transition name="fade">
      <div v-if="showDropdownClassList" class="class-table-list">
        <div
          v-for="proClass in classList"
          :key="proClass.classId"
          class="class-table-row cursor-pointer"
          @click="
            openClass(proClass.classId, proClass.className, proClass.students)
          "
        >
          <p class="class-name">
            {{ proClass.className }}
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      showDropdownClassList: false,
      className: "",
      classList: [],
    };
  },
  props: {
    refreshCount: {
      type: Number,
      default: 0,
    },
    classId: {
      type: [Number, String],
      default: 0,
    },
    currentClassName: {
      type: String,
      default: "",
    },
    students: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("admin", ["getAllProClasses"]),
  },
  watch: {
    async refreshCount() {
      await this.toggleClassList();
      if (this.classId)
        this.openClass(
          this.classId,
          this.currentClassName,
          this.classList.find((classroom) => classroom.classId === this.classId)
            .students,
          true
        );
    },
  },
  created() {
    this.toggleClassList();
  },
  methods: {
    ...mapActions("admin", ["fetchProClasses"]),
    openClass(classId, className, studentList, isRefresh = false) {
      this.className = className;
      this.$emit("chooseClass", { classId, studentList, isRefresh });
      this.showDropdownClassList = false;
    },
    async toggleClassList() {
      if (!this.showDropdownClassList) {
        await this.fetchProClasses();
        this.classList = JSON.parse(JSON.stringify(this.getAllProClasses));
      }
      this.showDropdownClassList = !this.showDropdownClassList;
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 8px 0 0;
  position: relative;
}
.class-table-header {
  background-color: #453fe3;
  height: 48px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 10px;
  cursor: pointer;
}

.class-table-list {
  position: absolute;
  background: white;
  border: 1.5px solid #453fe3;
  border-top: none;
  border-radius: 4px;
  width: 100%;
  z-index: 3;
  max-height: 400px;
  overflow: auto;
  .class-table-row {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    &.cursor-pointer:hover {
      background-color: #ccc;
    }
    .class-name {
      margin: 0;
      padding: 0 10px;
      z-index: 2;
    }
  }
}
</style>
