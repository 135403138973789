export default {
  SET_IS_LOGGED_IN(state, payload) {
    state.isLoggedIn = payload.status;
    if (payload.status) {
      state.account = payload.account;
    } else {
      state.account = {};
    }
  },
  SET_ERROR(state, payload) {
    state.error = payload.error;
    switch (payload.errorCode) {
      case "404":
        state.errorMessage = "Username không tồn tại";
        break;
      case "401":
        state.errorMessage = "Sai mật khẩu";
        break;
      case "500":
        state.errorMessage = "Something went wrong!!";
        break;
      case "403":
        state.errorMessage = "Không có quyền truy cập";
        break;
      case "400":
        state.errorMessage = "Tên đăng nhập hoặc mật khẩu sai";
        break;
      default:
        state.errorMessage = "";
        break;
    }
  },
};
