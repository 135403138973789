<template>
  <div>
    <v-btn
      :class="[
        isGrayBtn ? 'gray-btn' : 'blue-btn',
        disable && 'disable',
        // $route.name == 'cardDetailsAnswer' && 'relearn-btn',
        isBrowseButton && 'mobile',
        classBtn,
      ]"
      @click="handleClickBtn"
      elevation="0"
      :type="type"
      :style="showBadge && { padding: '0 10px !important;' }"
    >
      <v-badge v-if="showBadge" style="display: flex" color="success">
        <h6 v-if="!smallText" v-html="title"></h6>
        <p v-else v-html="title"></p>
        <img :src="getIcon(appendIcon)" class="ml-3" v-if="appendIcon" />
      </v-badge>
      <template v-else>
        <img :src="getIcon(prependIcon)" class="mr-2" v-if="prependIcon" />
        <h6 v-if="!smallText" v-html="title"></h6>
        <p v-else v-html="title"></p>
        <img :src="getIcon(appendIcon)" class="ml-2" v-if="appendIcon" />
      </template>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "button",
    },
    title: {
      type: String,
      default: "",
    },
    classBtn: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    isGrayBtn: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    showBadge: {
      type: Boolean,
      default: false,
    },
    smallText: {
      type: Boolean,
      default: false,
    },
    isBrowseButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickBtn() {
      this.$emit("click");
    },
    getIcon(name) {
      return require("@/assets/icons/" + name + ".svg");
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  width: 96px;
  height: 40px !important;
  font-size: 19px;
}
.submit-answer.blue-btn {
  padding: 12px 20px !important;
  font-size: 16px;
  font-weight: 600;
}
p {
  margin: 0;
}

.relearn-btn {
  height: 40px !important;
  width: 111px;
  border-radius: 8px !important;
  &.mobile {
    width: 100px;
    padding: 0px 8px;
    .mr-2 {
      margin-right: 4px !important;
    }
  }
  h6 {
    font-weight: 600;
    font-size: 16px;
  }
}
.disable {
  cursor: not-allowed;
  background-color: grey !important;
  pointer-events: none;
}
</style>

<style lang="sass" scoped>
button
  color: #FFFFFF !important
  border-radius: 12px !important
  text-transform: initial
  height: 48px !important
  min-width: 82px !important
  z-index: 2
.blue-btn
  background-color: #453FE3 !important
  padding: 0 8px !important
  &.disable
    background-color: grey !important
.gray-btn
  background-color: #384961 !important
h6
  color: #FFFFFF !important
</style>
