var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center align-center",class:{
      'mt-4': _vm.$vuetify.breakpoint.smAndUp,
    },on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('pressEnter')}}},[_c('v-col',{class:{
        'pt-4 px-1': _vm.$vuetify.breakpoint.xsOnly,
      },attrs:{"cols":"12","md":"8"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-flex',{staticClass:"d-flex mt-1"},[_c('v-flex',{staticClass:"d-flex align-center mb-2"},[_c('img',{staticStyle:{"cursor":"pointer","width":"40px"},attrs:{"src":require("@/assets/icons/button-back.svg")},on:{"click":function($event){return _vm.$emit('back')}}}),_c('v-card-subtitle',{staticClass:"pa-0 d-flex align-center ml-2"},[_c('p',{staticClass:"mb-0 captionOne"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])])],1),_c('ButtonComponent',{staticClass:"mb-2",attrs:{"title":"Browse","isBrowseButton":"","isGrayBtn":""},on:{"click":function($event){return _vm.$router.push(`/browse/${_vm.studentId}`)}}})],1),_c('v-card-title',{staticClass:"pa-0 align-baseline",class:{
            'd-flex flex-column align-start': _vm.$vuetify.breakpoint.xsOnly,
          }},[_c('h4',{class:[
              'mobile-title' || _vm.$vuetify.breakpoint.smAndDown,
              'text-overflow',
              'cardGroupTitle',
            ],attrs:{"title":_vm.title}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{class:[
              _vm.$vuetify.breakpoint.smAndUp ? '' : 'mobile',
              'text-averta-400',
            ]},[_vm._v(" "+_vm._s(_vm.timeLeft)+" ")])]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticStyle:{"padding":"8px 6px 6px"},attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"layout-card-wrapper overflow-scroll",class:{
                'pa-1': _vm.$vuetify.breakpoint.xsOnly,
                'pb-80':
                  _vm.$route.name == 'cardDetailsAnswer' &&
                  _vm.$vuetify.breakpoint.xsOnly,
                'pb-100':
                  _vm.$route.name == 'cardDetailsAnswer' &&
                  _vm.$vuetify.breakpoint.smAndUp,
                coccoc: _vm.isNormalBrowser,
              },attrs:{"elevation":"0"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"mask"},[_c('v-col',{directives:[{name:"hammer",rawName:"v-hammer:tap",value:(
                    () => {
                      _vm.handleMobileTouch('leftSingleTap');
                    }
                  ),expression:"\n                    () => {\n                      handleMobileTouch('leftSingleTap');\n                    }\n                  ",arg:"tap"},{name:"hammer",rawName:"v-hammer:doubletap",value:(
                    () => {
                      _vm.handleMobileTouch('leftDoubleTap');
                    }
                  ),expression:"\n                    () => {\n                      handleMobileTouch('leftDoubleTap');\n                    }\n                  ",arg:"doubletap"}],ref:"left-col"}),_c('v-col',{directives:[{name:"hammer",rawName:"v-hammer:tap",value:(
                    () => {
                      _vm.handleMobileTouch('rightSingleTap');
                    }
                  ),expression:"\n                    () => {\n                      handleMobileTouch('rightSingleTap');\n                    }\n                  ",arg:"tap"},{name:"hammer",rawName:"v-hammer:doubletap",value:(
                    () => {
                      _vm.handleMobileTouch('rightDoubleTap');
                    }
                  ),expression:"\n                    () => {\n                      handleMobileTouch('rightDoubleTap');\n                    }\n                  ",arg:"doubletap"}],ref:"right-col"})],1):_vm._e(),_c('v-row',{staticClass:"ma-0",class:{
                  'mb-4': _vm.$vuetify.breakpoint.smAndUp,
                }},[_c('v-col',{staticClass:"d-flex justify-space-between pa-0 layout-type-wrapper",attrs:{"cols":"12"}},[_c('h6',{staticClass:"layout-type"},[_vm._v(_vm._s(_vm.type))]),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition","location":"end"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('div',_vm._g(_vm._b({staticClass:"btn-area"},'div',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/menu.svg"),"alt":""}})])]}}])},[_c('v-list',{staticClass:"pa-0 layout-list"},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,staticClass:"px-5 py-1 cursor-pointer",class:item.icon == 'back' && !_vm.havePreviousCard
                            ? 'disable'
                            : '',attrs:{"link":"","disable":_vm.disableActions},on:{"click":function($event){return _vm.$emit(item.emitFunction)}}},[_c('v-img',{attrs:{"src":_vm.getIcon(item.icon)}}),_c('v-list-item-title',{staticClass:"ml-4 d-flex"},[_c('h6',{style:(item.color)},[_vm._v(_vm._s(item.title))])])],1)}),1)],1)],1)],1),_vm._t("default"),_c('div',{staticClass:"mt-2 footer",class:{ mobile: _vm.$vuetify.breakpoint.xsOnly }},[(_vm.haveFooter)?_c('v-divider',{staticClass:"mb-2"}):_vm._e(),(_vm.haveFooter)?_c('FooterCardView',{attrs:{"disableActions":_vm.disableActions},on:{"clickRelearn":function($event){return _vm.$emit('clickRelearn')},"clickEasyType":function($event){return _vm.$emit('clickEasyType')},"clickNormalType":function($event){return _vm.$emit('clickGoodType')},"clickHardType":function($event){return _vm.$emit('clickHardType')},"clickDelete":function($event){return _vm.$emit('clickDelete')},"clickCloneType":function($event){return _vm.$emit('clickCloneType')}}}):_vm._e()],1)],2)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }