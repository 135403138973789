import actions from "@/store/modules/common/actions";
import mutations from "@/store/modules/common/mutations";
import getters from "@/store/modules/common/getters";

export default {
  namespaced: true,
  state() {
    return {
      isOpenDialog: false,
      configDialog: {
        typeDialog: "",
        description: "",
        autoClose: "",
      },
    };
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
};
