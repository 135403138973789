import { STUDENT_PRO_STATUS_LIST } from "@/constants";
import axios from "@/services/axios";
import TokenService from "@/services/token.service";
import Vue from "vue";

export default {
  async handleLogin(context, payload) {
    await axios
      .post("login", {
        userName: payload.username,
        password: payload.password,
        isAdmin: payload.isAdmin,
      })
      .then((res) => {
        if (res.data.status_code == "200") {
          const accountInfo = (({ id, fullName, role, userName, status }) => ({
            id,
            fullName,
            role,
            userName,
            status,
          }))(res.data.data);
          Vue.prototype.$isProStudent =
            res.data.data.status === STUDENT_PRO_STATUS_LIST.PRO;
          Vue.prototype.$isOldProStudent =
            res.data.data.status === STUDENT_PRO_STATUS_LIST.USED_TO_PRO;
          TokenService.setAccount(accountInfo);
          TokenService.setNewToken(
            res.data.data.accessToken,
            res.data.data.refreshToken
          );
          context.commit("SET_IS_LOGGED_IN", {
            status: true,
            account: accountInfo,
          });
          context.commit("SET_ERROR", {
            error: false,
            errorCode: "",
          });
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        context.commit("SET_ERROR", {
          error: true,
          errorCode: error.response.data.status_code,
        });
      });
  },
  async handleLogout(context) {
    await axios
      .post("logout", {
        userId: TokenService.getAccount().id,
      })
      .then((res) => {
        if (res.status == "200") {
          TokenService.removeAccount();
          context.commit("SET_IS_LOGGED_IN", { status: false });
          Vue.prototype.$isProStudent = false;
          Vue.prototype.$isOldProStudent = false;
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error.response?.data.message || error);
      });
  },
  async handleRefreshToken() {
    await axios
      .post("refresh-token", {
        userId: TokenService.getAccount().id,
        refreshToken: TokenService.getLocalRefreshToken(),
      })
      .then((res) => {
        if (res.data.status_code == "200") {
          TokenService.setNewToken(
            res.data.data.accessToken,
            res.data.data.refreshToken
          );
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error.response?.data.message || error);
      });
  },
};
