<template>
  <div class="container">
    <div
      class="d-flex folder-table-header"
      @click="showDropdownCardSetList = !showDropdownCardSetList"
    >
      <div
        v-text="currentCardSetName === '' ? 'Chọn bộ thẻ' : currentCardSetName"
      ></div>
      <img :src="getImgUrl('icons/arrow-down.svg')" />
    </div>
    <transition name="fade">
      <div
        v-if="showDropdownCardSetList"
        class="folder-table-list overflow-scroll"
      >
        <div v-for="folder in folders" :key="folder.id" class="folder-card-set">
          <div
            :title="folder.name"
            class="folder-table-row cursor-pointer pr-3"
            @click.self="chooseFolder(folder.id, folder.name)"
          >
            <p class="folder-name text-overflow font-weight-bold">
              {{
                folder.name +
                " (" +
                folder.groupCards.reduce(
                  (accumulator, current) => accumulator + current.numberOfCards,
                  0
                ) +
                ")"
              }}
            </p>
            <v-icon
              @click="toggleCardSetListInFolder(folder.name)"
              size="small"
              color="green-darken-2"
              >{{
                showFolder[folder.name]
                  ? "mdi-arrow-up-left-bold"
                  : "mdi-arrow-down-right-bold"
              }}</v-icon
            >
          </div>
          <template v-if="showFolder[folder.name]">
            <div
              v-for="set in folder.groupCards"
              :key="set.id"
              :title="set.groupName"
              class="folder-table-row cursor-pointer"
              @click.self="chooseCardSet(set.id, set.groupName, folder.id)"
            >
              <p
                v-if="!set.isEditing"
                class="folder-name text-overflow"
                @dblclick="toggleRenameCardSet(set.id, set.groupName)"
              >
                {{ set.groupName + " (" + set.numberOfCards + ")" }}
              </p>
              <input
                v-else
                id="rename-card-set"
                type="text"
                class="add-new-folder"
                @keyup.enter="updateCardSetName(set.id, set.groupName)"
                @blur="quitRenameCardSet(set.id)"
                v-model="set.groupName"
              />
              <div class="button-group d-flex">
                <v-icon
                  title="Di chuyển bộ thẻ"
                  style="color: blue"
                  @click="moveCartSet(set)"
                  >mdi-folder-move-outline</v-icon
                >
                <v-icon title="Duplicate bộ thẻ" @click="duplicate(set)"
                  >mdi-content-copy</v-icon
                >
                <v-icon
                  title="Nạp bộ thẻ"
                  style="color: green"
                  @click="openAssignDialog(set)"
                  >mdi-ballot-outline</v-icon
                >
                <img
                  class="cursor-pointer"
                  title="Xóa bộ thẻ"
                  :src="getImgUrl('icons/trash.svg')"
                  @click="onDeleteCardSetDialog(set.id)"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </transition>
    <DialogComponent
      v-if="isShowDialogDelete"
      typeDialog="confirm"
      description="Bạn chắc chắn muốn xóa bộ thẻ?"
      :showDialogValue="isShowDialogDelete"
      @closeDialog="isShowDialogDelete = false"
      @confirmRequest="onDeleteCardSet"
    />
    <DialogAssignProCard
      v-if="isShowDialogAssign"
      :showDialogValue="isShowDialogAssign"
      :group-card-id="assigningGroupCardId"
      @closeDialog="isShowDialogAssign = false"
      :showSelect="true"
      :totalCards="assignMaxCards"
    />
    <DialogMoveCard
      v-if="isShowDialogMove"
      :showDialogValue="isShowDialogMove"
      :group-card-id="movingGroupCardId"
      :from-student-id="studentId"
      @refresh="$emit('refresh')"
      @closeDialog="isShowDialogMove = false"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DialogComponent from "@/components/ui/DialogComponent.vue";
import DialogAssignProCard from "@/components/ui/DialogAssignProCard.vue";
import DialogMoveCard from "@/components/ui/DialogMoveCard.vue";

export default {
  components: {
    DialogComponent,
    DialogAssignProCard,
    DialogMoveCard,
  },
  data() {
    return {
      showDropdownCardSetList: true,
      showFolder: {
        Listening: true,
        Reading: true,
        Speaking: true,
        Writing: true,
      },
      editingCardSetName: "",
      currentCardSetName: "",
      // cardSets: [],
      newCardSetName: "",
      isShowDialogDelete: false,
      isShowDialogAssign: false,
      isShowDialogMove: false,
      deletingCardSetId: "",
      assigningGroupCardId: "",
      movingGroupCardId: "",
      assignMaxCards: 0,
    };
  },
  props: {
    studentId: {
      type: [String, Number],
    },
    folders: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    cardSets() {
      let cardSets = [];
      this.folders.forEach((folder) => {
        cardSets = [...cardSets, ...folder.groupCards];
      });
      return cardSets;
    },
  },
  watch: {
    studentId: {
      handler() {
        this.currentCardSetName = "Chọn bộ thẻ";
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("admin", [
      "createNewCardSet",
      "duplicateCardSet",
      "updateProCardSet",
      "fetchProClasses",
      "fetchFolderList",
      "fetchUnassignedClasses",
    ]),
    ...mapActions("student", ["updateProCardSet", "studentDeleteProGroupCard"]),
    ...mapActions("card", ["fetchAllCards", "fetchAllCardsOfProFolder"]),
    async chooseFolder(folderId, folderName) {
      this.currentCardSetName = folderName;
      await this.fetchAllCardsOfProFolder({
        folderId: folderId,
        studentId: this.studentId,
      });
      this.$emit("chooseCardSet", null);
    },
    toggleCardSetListInFolder(folderName) {
      this.showFolder[folderName] = !this.showFolder[folderName];
    },
    async updateCardSetName(id, newCardSetName) {
      if (newCardSetName != "") {
        try {
          await this.updateProCardSet({
            cardSetId: id,
            cardSetName: newCardSetName,
          });
          this.editingCardSetName = newCardSetName;
          document.getElementById("rename-card-set").blur();
        } catch (error) {
          alert(error);
          return error;
        }
      }
    },
    toggleRenameCardSet(setId, cardSetName) {
      this.$set(
        this.cardSets.find((set) => set.id == setId),
        "isEditing",
        true
      );
      this.editingCardSetName = cardSetName;
      this.$nextTick(() => {
        document.getElementById("rename-card-set").focus();
      });
    },
    quitRenameCardSet(setId) {
      const currentSet = this.cardSets.find((set) => set.id == setId);
      currentSet.isEditing = false;
      currentSet.groupName = this.editingCardSetName;
    },
    async chooseCardSet(id, name, folderId) {
      this.currentCardSetName = name;
      await this.fetchAllCards({ id, isPro: true });
      this.$emit("chooseCardSet", id, folderId);
    },
    // async addCardSet() {
    //   if (this.newCardSetName != "") {
    //     await this.createNewCardSet({
    //       groupName: this.newCardSetName,
    //       folderId: this.folderId,
    //     });
    //     await this.fetchProClasses();
    //     this.newCardSetName = "";
    //   }
    // },
    async moveCartSet(set) {
      await this.fetchFolderList();
      this.movingGroupCardId = set.id;
      this.isShowDialogMove = true;
    },
    async duplicate(set) {
      await this.duplicateCardSet({
        groupName: set.groupName + " (copy)",
        folderId: set.folderId,
        groupCardId: set.id,
      });
      await this.fetchProClasses();
      this.$emit("refresh");
    },
    async onDeleteCardSet() {
      await this.studentDeleteProGroupCard(this.deletingCardSetId);
      await this.fetchProClasses();
      this.isShowDialogDelete = false;
      this.$emit("refresh");
    },
    onDeleteCardSetDialog(setId) {
      this.deletingCardSetId = setId;
      this.isShowDialogDelete = true;
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
    async openAssignDialog(set) {
      await this.fetchUnassignedClasses(set.id);
      this.assigningGroupCardId = set.id;
      this.assignMaxCards = set.numberOfCards;
      this.isShowDialogAssign = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 8px 0 26px;
  position: relative;
}

.folder-table-header {
  background-color: #453fe3;
  height: 48px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 10px;
  cursor: pointer;
}

.folder-table-list {
  position: absolute;
  background: white;
  border: 1.5px solid #453fe3;
  border-top: none;
  border-radius: 4px;
  width: 100%;
  max-height: calc(70vh - 20px);
  overflow: auto;

  .folder-table-row {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;

    &.cursor-pointer:hover {
      background-color: #ccc;
    }

    .folder-name {
      margin: 0;
      padding: 0 10px;
    }

    .button-group {
      justify-content: center;
      align-items: center;
      gap: 3px;

      p {
        padding: 0px;
        text-align: center;
        margin: 0px;
      }

      * {
        width: 20px;
        transition: transform 0.2s;
      }

      *:hover {
        transform: scale(1.2);
      }
    }
  }

  .add-new-folder {
    padding: 0 5px;

    &:focus {
      outline: none;
    }
  }
}
</style>

<style lang="scss">
input:focus-visible {
  border-color: hsl(218, 81.6%, 56.9%) !important;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
