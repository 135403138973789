<template>
  <LayoutList
    :title="isAdmin ? 'Các bộ thẻ của ' + name : 'Danh sách bộ thẻ'"
    :learningResult="learningResult"
    :loading="loading"
    @goBrowse="handleGoBrowse"
    @onCardCreated="onCardCreated"
    v-slot="slotParent"
  >
    <TableComponentV2
      :items="listCardGroups"
      :headers="$vuetify.breakpoint.smAndUp ? tableHeaders : tableHeadersMobile"
      @navigate="handleNavigateToLearnCard"
      @delete="handleDelete"
      @study="handleStudy"
      @rename="openDialogRenameProCardGroup"
      @randomStudy="handleRandomStudy"
      @reAssign="handleReAssign"
      @createNewGroupCard="showDialogCreateGroupCard = true"
      @createNewCard="handleAddCardForm(slotParent)"
      @showProCardGroups="handleShowProCardGroups"
    />

    <transition-group name="fade" v-if="$isProStudent">
      <template v-if="showDialogCreateProCard">
        <FormAddNewCard
          v-show="showAddForm"
          class="form-add small-screen"
          key="add"
          @minimize="showAddForm = false"
          @close="showDialogCancelAddCard = true"
          @onCardCreated="onCardCreated"
          @createNewCardGroup="handleCreateProGroupCard"
        />
      </template>
    </transition-group>
    <v-menu
      :set="(openActions = false)"
      v-if="
        $route.name !== 'manageStudentGroupCard' &&
        $vuetify.breakpoint.mdAndDown &&
        $isProStudent
      "
      v-model="openActions"
      offset-y
      transition="slide-y-transition"
      location="end"
    >
      <template v-slot:activator="{ attrs, on }">
        <div v-bind="attrs" v-on="on" class="menu-list-btn">
          <v-icon color="blue" class="cursor-pointer hover-scale icon"
            >mdi-plus-circle-outline</v-icon
          >
        </div>
      </template>
      <v-list class="pa-0 layout-list" style="min-width: 180px">
        <v-list-item
          class="px-5 py-1"
          link
          @click="showDialogCreateGroupCard = true"
        >
          <v-list-item-title class="d-flex align-center">
            <v-icon color="#3887FE" class="mr-2"
              >mdi-book-plus-multiple-outline</v-icon
            >
            Tạo bộ thẻ mới
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          class="px-5 py-1"
          link
          @click="handleAddCardForm(slotParent)"
        >
          <v-list-item-title class="d-flex align-center">
            <v-icon color="#1bb763" class="mr-2">mdi-card-plus-outline</v-icon>
            Tạo thẻ mới
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <DialogComponent
      v-if="$isProStudent && showDialogCreateGroupCard"
      typeDialog="student-create-card-group"
      :showDialogValue="showDialogCreateGroupCard"
      :itemsSelect="proFolderArrConst"
      @closeDialog="showDialogCreateGroupCard = false"
      @confirmRequest="handleCreateProGroupCard"
    />
    <DialogComponent
      v-if="$isProStudent && showDialogRenameGroupCard"
      typeDialog="student-rename-card-group"
      :showDialogValue="showDialogRenameGroupCard"
      :initialGroupName="deletingGroupCard.groupName"
      @closeDialog="showDialogRenameGroupCard = false"
      @confirmRequest="handleRenameProGroupCard"
    />
    <!-- <DialogComponent
      v-if="showConfirmCancelCreateCardGroup"
      typeDialog="confirm"
      :showDialogValue="showConfirmCancelCreateCardGroup"
      description="Bạn có thay đổi chưa lưu, bạn chắc chắn muốn huỷ tạo bộ thẻ Pro chứ?"
      @closeDialog="showConfirmCancelCreateCardGroup = false"
      @confirmRequest="handleCloseDialog"
    /> -->
    <DialogComponent
      v-if="showDialogDelete"
      typeDialog="confirm"
      :showDialogValue="showDialogDelete"
      @closeDialog="handleCloseDialog"
      @confirmRequest="
        $isProStudent ? handleDeleteProGroupCard() : handleUnassignGroupCard()
      "
    />
    <DialogComponent
      v-if="showDialogRemind"
      typeDialog="remind"
      :showDialogValue="showDialogRemind"
      @closeDialog="handleCloseDialog"
      :maxInputStudy="maxInputStudy"
      :isReviewAheadProFolder="isReviewAheadProFolder"
      :cardGroupsInProFolder="cardGroupsInProFolder"
    />
    <DialogComponent
      v-if="showDialogCustomRemind"
      typeDialog="customRemind"
      :showDialogValue="showDialogCustomRemind"
      @closeDialog="handleCloseDialog"
      :maxInputStudy="maxInputStudy"
    />
    <DialogAssignCard
      v-if="isShowDialogAssign"
      :showDialogValue="isShowDialogAssign"
      :groupCardId="dialogGroupCard"
      @closeDialog="cleardialogGroupCardId"
      :showSeclect="false"
      :totalCards="assigninMaxCards"
      :groupCard="groupCard"
    />
    <DialogComponent
      v-if="showDialogCancelAddCard"
      :showDialogValue="showDialogCancelAddCard"
      typeDialog="confirm"
      description="Loại bỏ thay đổi và không tạo thẻ nữa?"
      @closeDialog="handleCloseDialog"
      @confirmRequest="handleCancelAddCard"
    />
  </LayoutList>
</template>

<script>
import LayoutList from "@/components/layout/LayoutList.vue";
import DialogComponent from "@/components/ui/DialogComponent.vue";
import DialogAssignCard from "@/components/ui/DialogAssignCard.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import TableComponentV2 from "@/components/ui/TableComponentV2.vue";
import { getListMenuGroupCard } from "@/uttil/menu";
import { MENU_GROUP_CARD } from "@/constants/menu";
import {
  GROUP_CARD_TYPE_STORE_NAME,
  group_card_type,
} from "@/constants/groupcard";
import { setLocalStore } from "@/uttil/localStorage";
import {
  LIST_VIEW_TABLE_HEADERS,
  MOBILE_LIST_VIEW_TABLE_HEADERS,
  PRO_FOLDER_LIST,
} from "@/constants";
import FormAddNewCard from "../admin/FormAddNewCard.vue";
export default {
  name: "ListView",
  components: {
    DialogComponent,
    LayoutList,
    TableComponentV2,
    DialogAssignCard,
    FormAddNewCard,
  },
  data() {
    return {
      showDialogDelete: false,
      showDialogCreateGroupCard: false,
      showDialogRenameGroupCard: false,
      showDialogCreateProCard: false,
      showDialogRemind: false,
      showDialogCustomRemind: false,
      tableHeaders: LIST_VIEW_TABLE_HEADERS,
      tableHeadersMobile: MOBILE_LIST_VIEW_TABLE_HEADERS,
      deletingGroupCard: "",
      maxInputStudy: 1,
      dialogGroupCard: "",
      isShowDialogAssign: false,
      assigninMaxCards: 0,
      loading: true,
      groupCard: {},
      proFolderArrConst: PRO_FOLDER_LIST,
      listCardGroups: [],
      showAddForm: false,
      showDialogCancelAddCard: false,
      folderIdAddingGroupCardTo: "",
      isReviewAheadProFolder: false,
      cardGroupsInProFolder: [],
    };
  },
  computed: {
    ...mapGetters("list", ["getListCardGroup"]),
    ...mapGetters("auth", ["getLoggedAccount"]),
    ...mapState("card", [
      "currentGroupCardId",
      "doneHomeWork",
      "isOpenPopUpHomeWork",
    ]),
    ...mapState("list", [
      "isLoading",
      "slotParentEventController",
      "newProCardGroupPayload",
    ]),
    isListForProStudent() {
      return this.getListCardGroup?.proFolders?.length || this.$isProStudent;
    },
    isAdmin() {
      return this.$route.meta.role;
    },
    studentId() {
      return this.$route.params.studentId;
    },
    name() {
      return this.getListCardGroup.fullName;
    },
    learningResult() {
      return this.getListCardGroup.learningResult;
    },
  },
  watch: {
    slotParentEventController() {
      this.handleCreateProGroupCard(this.newProCardGroupPayload);
    },
  },
  async mounted() {
    this.loading = true;
    this.SET_CURRENT_CARD({ id: 0 });
    await this.fetchStudentCardGroupList({ studentId: this.studentId });
    await this.fetchTimeStudy({
      studentId: this.studentId,
      role: this.isAdmin,
    });
    await this.setTypeLearn(-1);
    this.initListCardGroups();
    this.SET_DONE_HOME_WORK(
      this.getListCardGroup.isCompleteHomeWork && !this.isOpenPopUpHomeWork
    );
    this.loading = false;
  },
  methods: {
    ...mapMutations("card", [
      "SET_CURRENT_GROUP_CARD_ID",
      "SET_PREVIOUS_CARD_ID",
      "SET_SHOW_SUCCESS",
      "SET_NEXT_CARD",
      "SET_NUM_NEW_CARDS_LEARNING",
      "SET_NUM_NEW_CARDS_WHEN_STARTING_LEARNING",
      "SET_NUM_RVS_CARDS_LEARNING",
      "SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING",
    ]),
    ...mapActions("list", [
      "fetchStudentCardGroupList",
      "unassignGroupCard",
      "deleteRandomStudy",
      "fetchTimeStudy",
    ]),
    ...mapActions("student", ["updateProCardSet"]),
    ...mapActions("card", ["setTypeLearn"]),
    ...mapActions("student", [
      "studentCreateProCardGroup",
      "studentDeleteProGroupCard",
    ]),
    ...mapMutations("card", [
      "SET_CURRENT_CARD",
      "SET_DONE_HOME_WORK",
      "SET_IS_ƠPEN_POP_UP_HOME_WORK",
    ]),
    ...mapMutations("common", ["SET_DIALOG"]),
    initListCardGroups() {
      const cardGroups = this.getListCardGroup?.cardGroups || [];
      const cardGroupRows = cardGroups.map((item) => {
        if (item?.id) {
          return {
            ...item,
            actions: getListMenuGroupCard(item.type, MENU_GROUP_CARD),
            showMenu: false,
          };
        }
      });
      if (!this.isListForProStudent) {
        this.listCardGroups = cardGroupRows;
        return;
      } else {
        const proFolders = this.getListCardGroup?.proFolders || [];
        const proFolderRows = proFolders.map((folder) => {
          const proCardGroups = folder.cardGroups.map((group) => {
            return {
              ...group,
              actions: getListMenuGroupCard(
                this.$isProStudent
                  ? group_card_type.PRO_GROUP_CARD
                  : group_card_type.PRO_GROUP_CARD_FOR_OLD_PRO_STUDENT,
                MENU_GROUP_CARD
              ),
              showMenu: false,
              isProGroupCard: true,
            };
          });
          const isShowMenu =
            this.listCardGroups.find(
              (row) => row.isFolder && row?.folderId === folder.folderId
            )?.showMenu || folder.folderId === this.folderIdAddingGroupCardTo;
          return {
            ...folder,
            actions: getListMenuGroupCard(
              group_card_type.FOLDER,
              MENU_GROUP_CARD
            ),
            cardGroups: proCardGroups,
            showMenu: isShowMenu,
            isFolder: true,
          };
        });
        const combinedArray = proFolderRows.concat(cardGroupRows);
        this.listCardGroups = combinedArray || [];

        // keep folder opening after refresh
        this.proFolderArrConst.forEach((proFolderConst) => {
          const proFolderData = this.listCardGroups.find(
            (row) => row.isFolder && row.folderId === proFolderConst.folderId
          );
          if (proFolderData.showMenu) {
            const indexOfProFolderInTable = this.listCardGroups.findIndex(
              (row) => row.isFolder && proFolderConst.folderId === row.folderId
            );
            this.listCardGroups.splice(
              indexOfProFolderInTable + 1,
              0,
              ...this.listCardGroups[indexOfProFolderInTable].cardGroups
            );
          }
        });
      }
    },
    handleShowProCardGroups(index) {
      this.listCardGroups[index].showMenu =
        !this.listCardGroups[index].showMenu;
      if (this.listCardGroups[index].showMenu) {
        this.listCardGroups.splice(
          index + 1,
          0,
          ...this.listCardGroups[index].cardGroups
        );
      } else {
        this.listCardGroups.splice(
          index + 1,
          this.listCardGroups[index].cardGroups.length
        );
      }
    },
    handleNavigateToLearnCard(item) {
      if (!item.totalCards) {
        this.SET_DIALOG({
          description: "Không có thẻ nào!",
        });
        return;
      }
      if (!this.isAdmin && !this.isLoading) {
        this.SET_SHOW_SUCCESS(false);
        this.SET_NEXT_CARD({ id: 0 });
        this.SET_CURRENT_CARD({ id: 0 });
        this.SET_PREVIOUS_CARD_ID(null);
        this.SET_CURRENT_GROUP_CARD_ID(item.id || item.folderId);
        this.SET_NUM_NEW_CARDS_LEARNING(0);
        this.SET_NUM_NEW_CARDS_WHEN_STARTING_LEARNING(item.newCard);
        this.SET_NUM_RVS_CARDS_LEARNING(0);
        this.SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING(item.revisingCard);
        setLocalStore(GROUP_CARD_TYPE_STORE_NAME, item.type);
        if (item.isFolder) {
          const learningGroupCardIds = item.cardGroups
            .map((group) => group.id)
            .join(",");
          this.$router.push(
            `/learn-card/${this.studentId}/${item.folderId}?&learningGroupCardIds=${learningGroupCardIds}`
          );
        } else {
          const isOpenMenu = this.listCardGroups.some(
            (el) => !el.isFolder && el.showMenu
          );
          if (isOpenMenu) return;
          this.$router.push(`/learn-card/${this.studentId}/${item.id}`);
        }
      }
    },
    handleGoBrowse() {
      if (this.isAdmin) {
        this.$router.push(`/browse-admin/student/${this.studentId}`);
      } else this.$router.push(`/browse/${this.studentId}`);
    },
    async handleDelete(item) {
      this.showDialogDelete = true;
      if (this.isAdmin || this.$isProStudent) {
        this.deletingGroupCard = item;
      } else {
        this.SET_CURRENT_GROUP_CARD_ID(item.id);
      }
    },
    async openDialogRenameProCardGroup(item) {
      this.showDialogRenameGroupCard = true;
      this.deletingGroupCard = item;
    },
    async handleCreateProGroupCard(payload) {
      await this.studentCreateProCardGroup(payload);
      this.closeAllDialogAndRefresh();
      this.folderIdAddingGroupCardTo = payload.folderId;
    },
    async handleRenameProGroupCard(proGroupCard) {
      await this.updateProCardSet({
        cardSetId: this.deletingGroupCard.groupCardId,
        cardSetName: proGroupCard.groupName,
        folderId: proGroupCard.folderId,
      });
      this.closeAllDialogAndRefresh();
      this.deletingGroupCard = null;
    },
    async onCardCreated() {
      this.SET_DIALOG({
        description: "Thêm thành công",
        autoClose: true,
      });
      await this.closeAllDialogAndRefresh();
      this.SET_DIALOG(false);
    },
    async closeAllDialogAndRefresh() {
      this.handleCloseDialog();
      await this.fetchStudentCardGroupList({ studentId: this.studentId });
      this.initListCardGroups();
    },
    async handleUnassignGroupCard() {
      if (this.isAdmin) {
        await this.unassignGroupCard(this.deletingGroupCard.id);
        this.deletingGroupCard = "";
      } else {
        // Delete Clone group
        await this.deleteRandomStudy({
          studentId: this.studentId,
          userGroupCardId: this.currentGroupCardId,
        });
        this.SET_CURRENT_GROUP_CARD_ID("");
      }
      this.closeAllDialogAndRefresh();
    },
    async handleDeleteProGroupCard() {
      await this.studentDeleteProGroupCard(this.deletingGroupCard.groupCardId);
      this.deletingGroupCard = "";
      this.closeAllDialogAndRefresh();
    },
    handleStudy(item) {
      this.maxInputStudy = 3;
      this.SET_CURRENT_GROUP_CARD_ID(item.id);
      this.isReviewAheadProFolder = item.isFolder;
      this.cardGroupsInProFolder = item.cardGroups?.map(
        (item) => item.groupCardId
      );
      this.showDialogRemind = true;
    },
    handleRandomStudy(item) {
      const groupCardClone = this.listCardGroups.findIndex(
        (item) => item.type == group_card_type.GROUP_CARD_CLONED_FROM_SAVED
      );
      if (groupCardClone >= 0) return;
      const totalCard = item?.totalCards;
      if (!totalCard) return;
      this.maxInputStudy = totalCard;
      this.SET_CURRENT_GROUP_CARD_ID(item.id);
      this.showDialogCustomRemind = true;
    },
    handleCloseDialog() {
      this.showDialogCustomRemind = false;
      this.showDialogRemind = false;
      this.showDialogDelete = false;
      this.showDialogCreateGroupCard = false;
      this.showDialogRenameGroupCard = false;
      this.showDialogCancelAddCard = false;
    },
    handleReAssign(item) {
      this.dialogGroupCard = item.groupCardId;
      this.groupCard = item;
      this.assigninMaxCards = item.totalCards;
      this.isShowDialogAssign = true;
    },
    cleardialogGroupCardId() {
      this.isShowDialogAssign = false;
      this.dialogGroupCard = "";
      this.groupCard = {};
    },
    handleCloseDialogComplete() {
      this.SET_DONE_HOME_WORK(false);
      this.SET_IS_ƠPEN_POP_UP_HOME_WORK(true);
    },
    handleAddCardForm(slotParent) {
      if (this.$vuetify.breakpoint.lgAndUp) {
        slotParent.openAddCardForm();
        return;
      }
      if (!this.showDialogCreateProCard) {
        this.showDialogCreateProCard = true;
        this.showAddForm = true;
      } else {
        this.showAddForm = !this.showAddForm;
      }
    },
    handleCancelAddCard() {
      this.showAddForm = false;
      this.handleCloseDialog();
      this.showDialogCreateProCard = false;
    },
  },
  async activated() {
    this.loading = true;
    await this.fetchStudentCardGroupList({ studentId: this.studentId });
    await this.fetchTimeStudy({
      studentId: this.studentId,
      role: this.isAdmin,
    });
    this.initListCardGroups();
    this.loading = false;
  },
  // deactivated() {
  //   console.log("deactivated");
  // },
};
</script>

<style lang="scss" scoped>
.menu-list-btn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: fixed;
  bottom: 60px;
  right: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  .icon {
    font-size: 60px;
  }
}
</style>
<style lang="scss">
.table-action {
  width: 100px;
}
.groupName span {
  margin-left: 8px;
}
.form-add {
  position: absolute !important;
  top: 80px;
  right: 50%;
  transform: translate(50%);
  z-index: 100;
  &.small-screen {
    width: 100%;
    top: 58px;
    border-radius: 0px;
    height: calc(100vh - 58px);
  }
}
</style>
