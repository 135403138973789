import Format from "../../../services/format";

export default {
  getCardSets(state) {
    return state.cardSets;
  },
  getCardSetDetails(state) {
    return state.cardSetDetails;
  },
  getAllClassesName(state) {
    const classes = [];
    state.classes.map((classItem) => {
      classes.push(classItem.name);
    });
    return classes;
  },
  getAllClasses(state) {
    return state.classes;
  },
  getAllProClasses(state) {
    return state.proClasses;
  },
  getAllProGroupCard:
    (state) =>
    (folderId = "", studentId = "") => {
      const allStudents = state.proClasses.flatMap((cls) => cls.students);
      const allGroupCards = allStudents.flatMap((student) =>
        student.folders.flatMap((folder) => folder.groupCards)
      );
      const allGroupCardsOfAStudents = allStudents
        .filter((student) => (studentId ? student.id == studentId : true))
        .flatMap((student) =>
          student.folders.flatMap((folder) => folder.groupCards)
        );

      if (folderId)
        return studentId
          ? allGroupCardsOfAStudents?.filter(
              (group) => group.folderId == folderId
            )
          : allGroupCards?.filter((group) => group.folderId == folderId);
      else if (studentId) return allGroupCardsOfAStudents;
      else return allGroupCards;
    },
  getProClassesToAssign(state) {
    const result = state.proClasses.map((c) => {
      const children = c.students.map((student) => {
        return {
          id: student.id,
          label: student.fullName,
        };
      });
      return {
        id: "C_" + c.classId,
        label: c.className,
        children: children,
      };
    });
    return result;
  },
  getProFoldersToMove(state) {
    const result = state.proClasses.map((c) => {
      const children = c.students.map((student) => {
        const grandChildren = student.folders.map((folder) => ({
          id: folder.id + "_" + student.id,
          label: folder.name,
          toStudentId: student.id,
          toFolderId: folder.id,
        }));
        return {
          id: student.id,
          label: student.fullName,
          children: grandChildren,
        };
      });
      return {
        id: "C_" + c.classId,
        label: c.className,
        children: children,
      };
    });
    return result;
  },
  getListProStudentInClass(state, classId) {
    return state.proClasses.find((proClass) => proClass.classId === classId)
      ?.students;
  },
  getUnassignedClasses(state) {
    const result = state.unassignedClasses.map((c) => {
      const children = c.students.map((student) => ({
        id: student.id,
        label: student.fullName,
      }));
      return {
        id: "C_" + c.id,
        label: c.className,
        children: children,
      };
    });
    return result;
  },
  getFolderList(state) {
    return state.folderList;
  },
  getNormalFoldersToMove(state) {
    return state.folderList.map((folder) => ({
      id: folder.id,
      label: folder.folderName,
    }));
  },
  getAccountList(state) {
    return state.accounts.map((item) => {
      return {
        ...item,
        createdAt: Format.FormatDate(item.createdAt),
      };
    });
  },
  getLearningResults(state) {
    return state.learningResults;
  },
};
