<template>
  <v-container fluid>
    <LayoutCard
      :title="getTitle"
      :haveFooter="true"
      subtitle="Bộ thẻ:"
      type="ĐÁP ÁN"
      :timeLeft="estimateTime"
      :disableActions="isProcessing"
      @openDeleteDialog="showDialog = true"
      @back="handleBackPage"
      @backCard="handleBackCard"
      @clickRelearn="handleNextCard(0)"
      @clickEasyType="handleNextCard(1)"
      @clickGoodType="handleNextCard(2)"
      @clickHardType="handleNextCard(3)"
      @saveCard="handleClickSaveCard"
      @unSaveCard="handleClickUnSaveCard"
      @clickDelete="handleDeleteCloneCard"
      @clickCloneType="handleNextCard(4)"
      @rightSingleTap="handleNextCard(2)"
      @leftSingleTap="handleNextCard(0)"
      @leftDoubleTap="handleBackCard"
      @rightDoubleTap="handlePlayAudio"
    >
      <div
        class="d-flex justify-content-center align-items-center"
        v-if="isProcessing"
        style="min-height: calc(100vh - 480px)"
      >
        <v-progress-circular color="primary" indeterminate size="64" />
      </div>
      <v-layout
        v-else
        class="d-flex flex-column align-center disable-copy"
        :class="{ 'mb-28': $vuetify.breakpoint.smAndUp }"
        style="min-height: calc(100vh - 480px)"
      >
        <template v-if="currentCard.typeCard == 1">
          <div class="justify-center d-flex mb-2">
            <p
              class="mean"
              :class="{ mobile: $vuetify.breakpoint.smAndDown }"
              v-html="currentCard.mean"
            ></p>
          </div>
          <div class="text-center">
            <p
              class="word"
              :class="{
                mobile: $vuetify.breakpoint.smAndDown,
                'mt-1': $vuetify.breakpoint.smAndUp,
              }"
              v-html="currentCard.word"
            ></p>
          </div>
          <div
            class="audio d-flex-center"
            :class="{
              mobile: $vuetify.breakpoint.smAndDown,
              'mt-2': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div :class="$vuetify.breakpoint.smAndDown && 'img-wrapper'">
              <img
                v-if="currentCard.audioLink !== null"
                class="cursor-pointer"
                @click="handlePlayAudio"
                src="@/assets/images/volumn.svg"
                :style="
                  $vuetify.breakpoint.smAndDown && {
                    width: '16px',
                    height: '16px',
                  }
                "
              />
            </div>
            <!-- <audio
              v-if="currentCard.audioLink"
              ref="audioPlayer"
              muted
              autoplay
            >
              <source :src="currentCard.audioLink" type="audio/mpeg" />
              <source :src="currentCard.audioLink" type="audio/ogg" />
              <source :src="currentCard.audioLink" type="audio/wav" />
              <source :src="currentCard.audioLink" type="audio/mp4" />
              Your browser does not support the audio element.
            </audio> -->
            <audio
              v-if="currentCard.audioLink"
              :src="currentCard.audioLink"
              ref="audioPlayer"
              muted
              autoplay
              type="audio/*"
            />
            <p v-if="currentCard.spelling !== 'null'" class="spelling">
              {{ currentCard.spelling }}
            </p>
            <p
              v-if="currentCard.wordType !== 'null'"
              style="color: #f56742"
              class="word-type"
            >
              ({{ currentCard.wordType }})
            </p>
          </div>
          <div
            class="text-center"
            :class="{
              'mobile mt-3': $vuetify.breakpoint.smAndDown,
              'mt-4': $vuetify.breakpoint.smAndUp,
            }"
          >
            <p v-html="currentCard.exampleFormatted" class="example"></p>
          </div>
        </template>

        <template v-else-if="currentCard.typeCard == 4">
          <div class="mt-1 text-center">
            <p
              class="word word28"
              :class="[
                currentCard.word == answerToLowerCase ? 'correct' : 'wrong',
                $vuetify.breakpoint.smAndDown ? 'mobile' : '',
              ]"
              style="
                display: flex;
                align-items: center;
                gap: 5px;
                font-weight: 700;
              "
            >
              {{ answerToLowerCase }}
              <v-icon
                :class="
                  currentCard.word == answerToLowerCase ? 'correct' : 'wrong'
                "
                >mdi-arrow-right</v-icon
              >
              {{ currentCard.word }}
            </p>
          </div>
          <div
            class="audio d-flex-center"
            :class="{
              mobile: $vuetify.breakpoint.smAndDown,
              'mt-2': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div :class="$vuetify.breakpoint.smAndDown && 'img-wrapper'">
              <img
                v-if="currentCard.audioLink !== null"
                class="cursor-pointer"
                @click="handlePlayAudio"
                src="@/assets/images/volumn.svg"
                :style="
                  $vuetify.breakpoint.smAndDown && {
                    width: '16px',
                    height: '16px',
                  }
                "
              />
            </div>
            <!-- <audio
              v-if="currentCard.audioLink"
              ref="audioPlayer"
              muted
              autoplay
            >
              <source :src="currentCard.audioLink" type="audio/mpeg" />
              <source :src="currentCard.audioLink" type="audio/ogg" />
              <source :src="currentCard.audioLink" type="audio/wav" />
              <source :src="currentCard.audioLink" type="audio/mp4" />
              Your browser does not support the audio element.
            </audio> -->
            <audio
              v-if="currentCard.audioLink"
              :src="currentCard.audioLink"
              ref="audioPlayer"
              muted
              autoplay
              type="audio/*"
            />
            <p v-if="currentCard.spelling !== 'null'" class="spelling">
              {{ currentCard.spelling }}
            </p>
            <p
              v-if="currentCard.wordType !== 'null'"
              style="color: #f56742"
              class="word-type"
            >
              ({{ currentCard.wordType }})
            </p>
          </div>
          <p
            class="mean mean22 mt-3"
            :class="{
              'mobile mb-3': $vuetify.breakpoint.smAndDown,
            }"
            v-html="currentCard.mean"
          ></p>
          <div
            class="text-center"
            :class="{ 'mt-3': $vuetify.breakpoint.smAndUp }"
          >
            <p v-html="currentCard.exampleFormatted" class="example"></p>
          </div>
        </template>

        <template v-else-if="currentCard.typeCard == 5">
          <p
            class="mean ft400"
            :class="{ mobile: $vuetify.breakpoint.smAndDown }"
            v-html="currentCard.meanFormatted"
            style="color: #1bb763"
          ></p>
          <div
            class="text-center"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-1'"
          >
            <p
              class="word ft400 word28"
              :class="{ mobile: $vuetify.breakpoint.smAndDown }"
              style="color: #3887fe"
              v-html="currentCard.wordFormatted"
            ></p>
          </div>
          <div class="text-center mt-3">
            <p v-html="currentCard.exampleFormatted" class="example"></p>
          </div>
        </template>

        <template v-else>
          <div class="mt-1 text-center">
            <p
              class="word ft400 word28"
              style="color: #1bb763"
              :class="{ 'mobile-type-23': $vuetify.breakpoint.smAndDown }"
              v-html="currentCard.wordFormatted"
            ></p>
          </div>
          <p
            class="mean ft400"
            :class="{
              'mobile-type-23 mt-3 mb-3': $vuetify.breakpoint.smAndDown,
              'mt-1': $vuetify.breakpoint.smAndUp,
            }"
            style="color: #3887fe"
            v-html="currentCard.meanFormatted"
          ></p>
          <div
            class="text-center"
            :class="{ 'mt-3': $vuetify.breakpoint.smAndUp }"
          >
            <p v-html="currentCard.exampleFormatted" class="example"></p>
          </div>
        </template>
      </v-layout>
      <DialogComponent
        :showDialogValue="showDialog"
        typeDialog="delete"
        @closeDialog="showDialog = false"
        @confirmRequest="handleConfirmRequest"
      />
    </LayoutCard>
  </v-container>
</template>
<script>
import LayoutCard from "@/components/layout/LayoutCard.vue";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import DialogComponent from "@/components/ui/DialogComponent.vue";
import TokenService from "@/services/token.service";
import { convertSecondsToTime, checkTimeMoreThanToday } from "@/uttil";
import { getLocalStore } from "@/uttil/localStorage";
import {
  GROUP_CARD_TYPE_STORE_NAME,
  group_card_type,
} from "@/constants/groupcard";
import { checkDoublePress } from "@/uttil";

export default {
  components: { DialogComponent, LayoutCard },
  data() {
    return {
      timeLeft: "Còn 1 giờ 16 phút",
      showDialog: false,
      isProcessing: false,
      openMenu: false,
    };
  },
  created() {
    if (!this.currentCard?.id) {
      this.handleBackPage();
    }
  },
  mounted() {
    this.handlePlayAudio();
    this.fetchTimeStudy({ studentId: this.studentId });

    window.addEventListener("keydown", this.handleKeyPressed);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("keydown", this.handleKeyPressed);
    });
  },
  methods: {
    ...mapActions("list", [
      "handleRemoveLearnCard",
      "fetchStudentCardGroupList",
      "fetchTimeStudy",
    ]),
    ...mapActions("card", [
      "handleLearnCard",
      "setTypeLearn",
      "getNextLearnCardFromGroup",
      "handleSaveCard",
      "handleUnSaveCard",
      "handleDeleteCardClone",
      "resetLearnCard",
    ]),
    ...mapMutations("card", [
      "SET_PREVIOUS_CARD_ID",
      "SET_CURRENT_CARD",
      "SET_SHOW_SUCCESS",
      "SET_PREVIOUS_CARD",
      "SET_NUM_NEW_CARDS_LEARNING",
      "SET_IS_BACK_LEARN_CARD",
      "SET_NUM_RVS_CARDS_LEARNING",
      "SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING",
      "PUSH_PREVIOUS_NUMBER_OF_CARD",
    ]),
    getIcon(name) {
      return require("@/assets/icons/" + name + ".svg");
    },
    handleBackPage() {
      this.$router.push(`/group-card/${this.account.id}`);
      this.SET_PREVIOUS_CARD_ID(null);
    },
    handleKeyPressed(e) {
      e.preventDefault();
      switch (e.key) {
        case "1":
          this.handleNextCard(0);
          break;
        case "2" || "Enter":
          this.handleNextCard(3);
          break;
        case "3":
          this.handleNextCard(2);
          break;
        case "4":
          this.handleNextCard(1);
          break;
        case "r":
          this.handlePlayAudio();
          break;
        case "x":
          checkDoublePress(() => (this.showDialog = true));
          break;
        case "s":
          checkDoublePress(this.handleClickSaveCard);
          break;
        case "z":
          if (this.havePreviousCard) this.handleBackCard();
          break;
        default:
          break;
      }
    },
    async handleConfirmRequest() {
      this.showDialog = false;
      await this.handleRemoveLearnCard(this.currentCard.id);
      this.SET_CURRENT_CARD({ id: 0 });
      this.fetchStudentCardGroupList({
        studentId: this.studentId,
        userGroupCardId: this.learningGroupCardId || "",
      });
      this.nextQuestion();
    },
    // handle study again
    async handleNextCard(btnValue) {
      // disable when get next card not complete
      if (this.isGetLearnCard) return;
      const groupCardType = getLocalStore(GROUP_CARD_TYPE_STORE_NAME);
      const isClone =
        groupCardType &&
        groupCardType == group_card_type.GROUP_CARD_CLONED_FROM_SAVED;
      this.isProcessing = true;
      try {
        const learnCardPayload = {
          ...this.currentCard,
          timeLearn: this.getTimeLearn,
          typeLearn: btnValue,
          userID: this.account.id,
        };
        this.setTypeLearn(this.currentCard.type);
        this.SET_PREVIOUS_CARD_ID(this.currentCard.detailCardId);
        if (
          +this.numberOfNewCard + +this.numberOfRevisingCard <= 2 ||
          this.doneCurrentGroupCard
        ) {
          await this.handleLearnCard(learnCardPayload);
          await this.getNextLearnCardFromGroup({
            userGroupCardId:
              this.learningProGroupCardIds ?? this.learningGroupCardId ?? "",
            typeOfCard: this.typeLearn,
            previousCardDetailId: this.currentCard.detailCardId,
            isClone,
          });
        } else {
          this.handleLearnCard(learnCardPayload);
        }

        let previousData = {
          newLearned: this.numOfNewCardsLearning,
          RVSCardStartLearning: this.numOfRVSCardsWhenStartingLearn,
          numOfRVSCardsLearned: this.numOfRVSCardsLearning,
        };
        this.PUSH_PREVIOUS_NUMBER_OF_CARD(previousData);
        const isTimeOverDay = checkTimeMoreThanToday(
          btnValue,
          this.currentCard
        );
        if (!this.disableSaveBtn || !this.disableUnSaveBtn || !isClone) {
          if (this.currentCard.Qn == 0 && this.currentCard.type == 1) {
            this.SET_NUM_NEW_CARDS_LEARNING(this.numOfNewCardsLearning + 1);
            if (!isTimeOverDay) {
              this.SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING(
                this.numOfRVSCardsWhenStartingLearn + 1
              );
            }
          }
          if (isTimeOverDay && this.currentCard.type != 1) {
            this.SET_NUM_RVS_CARDS_LEARNING(this.numOfRVSCardsLearning + 1);
          }
        }
        this.SET_CURRENT_CARD({ id: 0 });
        this.fetchStudentCardGroupList({
          studentId: this.studentId,
          userGroupCardId: this.learningGroupCardId || "",
        });
        this.nextQuestion();
      } catch (_) {
        console.log("catch");
      }
      this.isProcessing = false;
    },
    handlePlayAudio() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        audioPlayer.muted = false;
        audioPlayer.currentTime = 0;
        audioPlayer.play();
      }
    },
    async handleBackCard() {
      this.SET_IS_BACK_LEARN_CARD(true);
      let previousCard = this.getPreviousCard;
      this.resetLearnCard(previousCard);
      this.setTypeLearn(previousCard.type);
      const { newLearned, RVSCardStartLearning, numOfRVSCardsLearned } =
        this.getPreviousNumberOfCard;
      this.SET_NUM_NEW_CARDS_LEARNING(newLearned);
      this.SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING(RVSCardStartLearning);
      this.SET_NUM_RVS_CARDS_LEARNING(numOfRVSCardsLearned);
      this.nextQuestion();
    },
    async handleClickSaveCard() {
      if (this.disableSaveBtn) {
        return;
      }
      const payload = {
        studentId: this.account.id,
        learnCardIdList: [this.currentCard.id],
      };
      const mess = await this.handleUnSaveCard(payload);
      if (!mess) {
        this.reloadTotalCard();
      }
      this.openMenu = false;
    },
    async handleClickUnSaveCard() {
      if (this.disableUnSaveBtn) {
        return;
      }
      if (!this.currentCard.isBookmarked) {
        return;
      }
      const payload = {
        learnCardIdList: [this.currentCard.id],
      };
      const mess = await this.handleUnSaveCard(payload);
      if (!mess) {
        this.reloadTotalCard();
      }
      this.openMenu = false;
    },
    async handleDeleteCloneCard() {
      this.isProcessing = true;
      const payload = {
        userGroupCardId: this.learningGroupCardId,
        studentId: this.account.id,
        cardId: this.currentCard.id,
      };
      if (
        +this.numberOfNewCard + +this.numberOfRevisingCard <= 2 ||
        this.doneCurrentGroupCard
      ) {
        await this.handleDeleteCardClone(payload);
      } else {
        this.handleDeleteCardClone(payload);
      }

      this.SET_CURRENT_CARD({ id: 0 });
      this.fetchStudentCardGroupList({
        studentId: this.studentId,
        userGroupCardId: this.learningGroupCardId || "",
      });
      this.SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING(
        this.numOfRVSCardsWhenStartingLearn - 1
      );
      this.nextQuestion();
      this.isProcessing = false;
    },
    nextQuestion() {
      this.$router
        .push(
          `/learn-card/${this.studentId}/${this.learningGroupCardId}${
            this.learningProGroupCardIds
              ? "?learningGroupCardIds=" + this.learningProGroupCardIds
              : ""
          }`
        )
        .catch((err) => {
          console.log(err);
        });
    },
    async reloadTotalCard() {
      if (this.currentCard.Qn == 0 && this.currentCard.type == 1) {
        this.SET_NUM_NEW_CARDS_WHEN_STARTING_LEARNING(
          this.numOfNewCardsWhenStartingLearn - 1
        );
      } else {
        this.SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING(
          this.numOfRVSCardsWhenStartingLearn - 1
        );
      }
    },
  },
  computed: {
    ...mapState("list", ["cardGroupList"]),
    ...mapState("card", [
      "currentCard",
      "timeStartLearn",
      "answer",
      "previousCardId",
      "typeLearn",
      "previousCard",
      "disableSaveBtn",
      "disableUnSaveBtn",
      "numOfNewCardsLearning",
      "nextCard",
      "isGetLearnCard",
      "numOfRVSCardsLearning",
      "numOfRVSCardsWhenStartingLearn",
      "doneCurrentGroupCard",
    ]),
    ...mapGetters("card", [
      "getPreviousCard",
      "getPreviousNumberOfCard",
      "havePreviousCard",
    ]),
    ...mapState("list", ["timeStudyAvg"]),
    currentGroupCard() {
      if (this.learningProGroupCardIds) {
        const folders = this.cardGroupList?.proFolders || [];
        return folders?.find(
          (item) => item.folderId == this.learningGroupCardId
        );
      }
      const normalCardGroup = this.cardGroupList?.cardGroups?.find(
        (item) => item.id == this.learningGroupCardId
      );
      if (normalCardGroup) {
        return normalCardGroup;
      }
      return this.cardGroupList?.proFolders
        .map((folder) => folder.cardGroups)
        ?.flat()
        .find((item) => item.id == this.learningGroupCardId);
    },
    answerToLowerCase() {
      return this.answer.toLowerCase();
    },
    account() {
      return TokenService.getAccount();
    },
    learningProGroupCardIds() {
      return this.$route.query.learningGroupCardIds;
    },
    getTitle() {
      if (this.currentGroupCard) {
        return `${
          this.currentGroupCard.folderName ?? this.currentGroupCard.groupName
        }`;
      } else {
        return "Không có bộ thẻ";
      }
    },
    getTimeLearn() {
      // tính thời gian học
      let timeLearn = Math.floor(Date.now() / 1000) - this.timeStartLearn;
      // lớn hơn 1p thì đặt là 1p
      timeLearn = timeLearn > 60 ? 60 : Math.abs(timeLearn);
      return timeLearn;
    },
    learningGroupCardId() {
      return this.$route.params.learningGroupCardId;
    },
    studentId() {
      return this.$route.params.studentId;
    },
    numberOfNewCard() {
      if (this.currentGroupCard) {
        return `${this.currentGroupCard.newCard}`;
      } else {
        return 0;
      }
    },
    numberOfRevisingCard() {
      if (this.currentGroupCard != null) {
        return `${this.currentGroupCard.revisingCard}`;
      } else {
        return 0;
      }
    },
    estimateTime() {
      let numCardLeft =
        3 * parseInt(this.numberOfNewCard) +
        parseInt(this.numberOfRevisingCard);
      return "Còn " + convertSecondsToTime(numCardLeft * this.timeStudyAvg);
    },
  },
};
</script>

<style lang="scss" scoped>
.mb-28 {
  margin-bottom: 28px;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep p {
  margin-bottom: 0;
}
.mean {
  font-size: 28px;
  font-weight: 700;
  color: #3887fe;
  white-space: pre-line;
  text-align: center;
  &.mobile-type-23 {
    font-size: 21px;
    font-weight: 400;
  }
  &.mobile {
    font-size: 22px;
    font-weight: 600;
  }
  &.ft400 {
    font-weight: 400;
  }
  &.mean22 {
    font-size: 22px;
    font-weight: 600;
    color: #1bb763;
    &.ft400 {
      font-weight: 400;
    }
  }
  p {
    margin-bottom: 16px;
  }
}
.word {
  font-size: 22px;
  font-weight: 600;
  color: #1bb763;
  text-align: center;
  white-space: pre-line;
  &.mobile {
    font-size: 21px;
  }
  &.word28 {
    font-size: 28px;
    font-weight: 700;
    &.mobile {
      font-weight: 600;
      font-size: 21px;
      &.ft400 {
        font-weight: 400;
      }
    }
    &.mobile-type-23 {
      font-size: 22px;
      font-weight: 400;
    }
    &.ft400 {
      font-weight: 400;
    }
  }
  &.correct {
    color: #ffffff;
    background: #1bb763;
  }
  &.wrong {
    color: #384961;
    background: #cbd0dc;
  }
}
i.v-icon {
  &.correct {
    color: #ffffff;
    background: #1bb763;
  }
  &.wrong {
    color: #384961;
    background: #cbd0dc;
  }
}
.audio {
  z-index: 2;
  .img-wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  &.mobile {
    margin-top: 4px;
    .spelling {
      margin-left: 3px;
    }
  }
  .spelling {
    font-weight: 600;
    font-size: 16px;
    color: #384961;
    font-family: "Arial";
    margin-right: 10px;
    margin-left: 10px;
  }
  .word-type {
    font-weight: 600;
    font-size: 16px;
    color: #f56742;
  }
}
.example,
.example::v-deep p {
  font-size: 19px;
  line-height: 30px;
  margin-bottom: 0;
}
</style>

<style lang="scss">
.disable-copy {
  user-select: none;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="sass" scoped>
h4
  font-size: 28px
  font-weight: 400
</style>
