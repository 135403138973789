export const group_card_type = {
  GROUP_CARD_SAVED: 1,
  GROUP_CARD_CLONED_FROM_SAVED: 2,
  GROUP_CARD_DEFAULT: 0,
  PRO_GROUP_CARD: 3,
  FOLDER: 4,
  PRO_GROUP_CARD_FOR_OLD_PRO_STUDENT: 5,
};
export const card_detail_type = {
  ADMIN_BROWSE_TABLE: 0,
};

export const GROUP_CARD_TYPE_STORE_NAME = "group_card_type";
