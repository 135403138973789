<template>
  <v-btn
    elevation="0"
    :style="showBadge && { padding: '0 10px !important;' }"
    v-bind="$attrs"
    class="btn-cus"
    @click="handleClickBtn"
  >
    <img :src="getIcon(prependIcon)" class="mr-2" v-if="prependIcon" />
    <span>{{ title }}</span>
    <img :src="getIcon(appendIcon)" class="ml-2" v-if="appendIcon" />
  </v-btn>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: "",
    },
    prependIcon: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "",
    },
    showBadge: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getIcon(name) {
      return require("@/assets/icons/" + name + ".svg");
    },
    handleClickBtn() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-cus {
  min-width: 82px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  text-transform: initial;
  &.v-size--default {
    height: 40px;
  }
}
</style>
