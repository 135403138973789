/* eslint-disable */
class Format {
  FormatDate(dateString) {
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    // Return formatted date string
    return `${day}/${month}/${year}`;
  }
}

export default new Format();
 /* eslint-disable */
