import getters from "@/store/modules/list/getters";
import actions from "@/store/modules/list/actions";
import mutations from "@/store/modules/list/mutations";
export default {
  namespaced: true,
  state() {
    return {
      cardGroupList: [],
      cardList: [],
      learningResult: {},
      timeStudyAvg: 0,
      isLoading: false,
      slotParentEventController: 0,
      newProCardGroupPayload: null,
    };
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
};
