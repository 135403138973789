export default {
  SET_CARD_LIST(state, payload) {
    state.cardList = payload;
  },
  SET_PRO_CARD_LIST(state, payload) {
    state.proCardList = payload;
  },
  SET_CARD_DETAIL(state, payload) {
    state.cardDetail = payload;
  },
  handleGetElementIndex(state, payload) {
    state.nextElementId = payload.nextId;
    state.previousElementId = payload.previousId;
  },
  SET_CURRENT_GROUP_CARD_ID(state, payload) {
    state.currentGroupCardId = payload;
  },
  SET_CURRENT_GROUP_FOLDER_ID(state, payload) {
    state.currentFolderId = payload;
  },
  SET_LIST_CARDS(state, payload) {
    state.listCards = payload;
  },
  SET_START_TIME(state, payload) {
    state.timeStartLearn = payload;
  },
  SET_CURRENT_CARD(state, payload) {
    state.currentCard = payload;
    state.timeStartLearn = Math.floor(Date.now() / 1000);
  },
  SET_LEARNING_RESULT(state, payload) {
    state.learningResult = payload;
  },
  SET_DONE_CURRENT_GROUP_CARD(state, payload) {
    state.doneCurrentGroupCard = payload;
  },
  SET_PREVIOUS_TYPE_LEARN(state, payload) {
    state.typeLearn = payload;
  },
  SET_ANSWER(state, payload) {
    state.answer = payload;
  },
  SET_PREVIOUS_CARD_ID(state, payload) {
    state.previousCardId = payload;
  },
  SET_NEXT_CARD(state, payload) {
    state.nextCard = payload;
  },
  SET_SHOW_SUCCESS(state, payload) {
    state.showSuccessDiaLog = payload;
  },
  SET_PREVIOUS_CARD(state, payload) {
    state.previousCard = payload;
  },
  PUSH_PREVIOUS_CARD(state, payload) {
    state.previousCards.push(payload);
  },
  POP_PREVIOUS_CARD(state) {
    state.previousCards.pop();
  },
  PUSH_PREVIOUS_NUMBER_OF_CARD(state, payload) {
    state.previousNumberOfCards.push(payload);
  },
  POP_PREVIOUS_NUMBER_OF_CARD(state) {
    state.previousNumberOfCards.pop();
  },
  SET_PREVIOUS_NUMBER_OF_CARD_EMPTY(state) {
    state.previousNumberOfCards = [];
  },
  SET_PREVIOUS_CARDS_EMPTY(state) {
    state.previousCards = [];
  },
  SET_IS_BACK_LEARN_CARD(state, payload) {
    state.isBackLearnCard = payload;
  },
  SET_DISABLE_SAVE(state, payload) {
    state.disableSaveBtn = payload;
  },
  SET_DISABLE_UN_SAVE(state, payload) {
    state.disableUnSaveBtn = payload;
  },
  SET_NUM_NEW_CARDS_LEARNING(state, payload) {
    state.numOfNewCardsLearning = payload;
  },
  SET_NUM_NEW_CARDS_WHEN_STARTING_LEARNING(state, payload) {
    state.numOfNewCardsWhenStartingLearn = payload;
  },
  SET_IS_GET_LEARN_CARD(state, payload) {
    state.isGetLearnCard = payload;
  },
  SET_CARD_LIST_FILTER(state, payload) {
    state.cardListFilter = payload;
  },
  SET_PRO_CARD_LIST_FILTER(state, payload) {
    state.proCardListFilter = payload;
  },
  SET_LOADING_NEXT_CARD(state, payload) {
    state.isLoadingNextCard = payload;
  },
  SET_SHOW_COMPLETE(state, payload) {
    state.showCompleteDiaLog = payload;
  },
  SET_DONE_HOME_WORK(state, payload) {
    state.doneHomeWork = payload;
  },
  SET_NUM_RVS_CARDS_LEARNING(state, payload) {
    state.numOfRVSCardsLearning = payload;
  },
  SET_NUM_RVS_CARDS_WHEN_STARTING_LEARNING(state, payload) {
    state.numOfRVSCardsWhenStartingLearn = payload;
  },
  SET_IS_ƠPEN_POP_UP_HOME_WORK(state, payload) {
    state.isOpenPopUpHomeWork = payload;
  },
  SET_CARD_HISTORY(state, payload) {
    state.cardHistory = payload;
  },
};
