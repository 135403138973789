<template>
  <v-container fluid class="mt-5">
    <v-row class="justify-end">
      <v-col cols="auto">
        <button
          @click.prevent="page--"
          :disabled="loading"
          :style="page == 1 && 'visibility: hidden'"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
          <span> Mới hơn </span>
        </button>
      </v-col>
      <v-col cols="auto">
        <button
          @click.prevent="page++"
          :disabled="loading"
          :style="page == getLimitPage && 'visibility: hidden'"
        >
          <span> Cũ hơn </span>
          <v-icon>mdi-chevron-double-right</v-icon>
        </button>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-overlay
          v-model="loading"
          class="align-center justify-center"
          style="z-index: 10"
        >
          <v-progress-circular
            color="primary"
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-data-table
          :headers="headers"
          :items="getLearningResults"
          :hide-default-footer="true"
          hide-default-header
          disable-pagination
          mobile-breakpoint="0"
          class="table-cus"
          height="65vh"
          width="100%"
        >
          <thead slot="header">
            <tr class="v-data-table-header">
              <th
                v-for="header in headers"
                :key="header.text"
                :class="header.className"
              >
                <div class="text-center">
                  <span>{{ header.text }}</span>
                  <br v-if="header.subText" />
                  <span v-if="header.subText">{{ header.subText }}</span>
                  <template v-if="header.isFilter">
                    <v-menu
                      :close-on-content-click="false"
                      content-class="filter-classes"
                      location="end"
                      offset-y
                      transition="slide-y-transition"
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="btn-open-menu"
                          :disabled="classes.length === 0 || loading"
                        >
                          mdi-chevron-down
                        </v-icon>
                      </template>
                      <v-list v-if="classes.length">
                        <v-list-item
                          v-for="(option, index) in classes"
                          :key="option.id"
                          :value="index"
                          :disabled="loading"
                        >
                          <v-list-item-title>
                            <div>
                              <v-checkbox
                                v-model="listCheckBox"
                                :label="option.className"
                                :value="option.id"
                                @change="checkboxUpdated"
                                checked
                              >
                              </v-checkbox>
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </div>
              </th>
            </tr>
          </thead>
          <tbody slot="body">
            <template>
              <tr
                v-for="item in getLearningResults"
                :key="item.id + item.userName"
              >
                <th
                  v-for="header in headers"
                  :key="header.text"
                  class="pt-2 pb-2"
                >
                  <div class="text-center" :class="header.className">
                    <template v-if="header.subText">
                      {{ item.fullName }}
                      <br />
                      {{ item.userName }}
                    </template>
                    <template v-else-if="header.formatDate">
                      <div
                        v-if="item[header.value]"
                        class="text-center d-flex flex-column"
                      >
                        <div
                          :class="
                            item[header.value]?.isComplete ? 'success' : 'error'
                          "
                        >
                          <v-icon v-if="item[header.value]?.isComplete"
                            >mdi-check</v-icon
                          >
                          <v-icon v-else>mdi-close</v-icon>
                        </div>
                        <span>{{ item[header.value]?.totalCardsInDay }}</span>
                        <span>{{
                          item[header.value]?.totalLearnTimeInDay
                        }}</span>
                      </div>
                    </template>
                    <template v-else>
                      {{ item[header.value] }}
                    </template>
                  </div>
                </th>
              </tr>
            </template>
          </tbody>
        </v-data-table>
      </v-col>
    </v-row>
    <v-btn class="reset-assign-btn" @click="resetAssign"> Reset assiggn </v-btn>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import {
  LEARNING_RESULT_MAX_DAY,
  LEARNING_RESULT_RANGE,
  DEFAULT_TABLE_HEADER_MANAGE_LEARN,
  LEARNING_RESULT_DEFAULT_SORT,
  LEARNING_RESULT_DEFAULT_CHECKED,
} from "@/constants";
import { formatSubday } from "@/uttil";
import moment from "moment";
export default {
  data() {
    return {
      page: 1,
      loading: false,
      headers: DEFAULT_TABLE_HEADER_MANAGE_LEARN,
      listCheckBox: [],
    };
  },
  watch: {
    page() {
      this.getData();
    },
    immediate: true,
  },
  computed: {
    ...mapGetters("admin", ["getLearningResults"]),
    ...mapState("admin", ["classes"]),
    ...mapState("auth", ["account"]),
    getLimitPage() {
      return Math.ceil(LEARNING_RESULT_MAX_DAY / LEARNING_RESULT_RANGE);
    },
  },
  async created() {
    this.getNewListHeader(0, LEARNING_RESULT_RANGE);
    await this.fetchClasses();
    const getDefaultCheck = this.classes.find(
      (item) => item.id === LEARNING_RESULT_DEFAULT_CHECKED
    );
    if (getDefaultCheck) {
      this.listCheckBox = [getDefaultCheck.id];
    } else if (this.classes.length) {
      this.listCheckBox = [this.classes[0]?.id];
    }
    this.getData(true);
  },
  methods: {
    ...mapActions("admin", [
      "fetchClasses",
      "fetchLearningResults",
      "resetAssignCardPerDay",
    ]),
    checkboxUpdated() {
      this.getData();
    },
    getNewListHeader(startIndex, endIndex) {
      let result = [];
      for (let i = startIndex; i < endIndex; i++) {
        result.push({
          text: formatSubday(i, "DD / MM"),
          value: formatSubday(i, "YYYY-MM-DD"),
          sortable: false,
          formatDate: true,
          className: "colum-date",
        });
      }
      const leng = result.length;
      if (result.length < LEARNING_RESULT_RANGE) {
        for (let i = 0; i < LEARNING_RESULT_RANGE - leng; i++) {
          result.push("");
        }
      }
      this.headers = [...DEFAULT_TABLE_HEADER_MANAGE_LEARN, ...result];
    },
    async getData() {
      this.loading = true;
      const pageIndex = this.page ? this.page : 1;
      let startIndex = (pageIndex - 1) * LEARNING_RESULT_RANGE;
      let endIndex =
        startIndex + LEARNING_RESULT_RANGE <= LEARNING_RESULT_MAX_DAY
          ? startIndex + LEARNING_RESULT_RANGE
          : LEARNING_RESULT_MAX_DAY;
      const date = new Date();
      if (
        date < moment().toDate().setHours(4, 0, 0) &&
        date >= moment().toDate().setHours(0, 0, 0)
      ) {
        startIndex += 1;
        endIndex += 1;
      }
      const toDate = formatSubday(startIndex, "YYYY-MM-DD");
      const fromDate = formatSubday(endIndex - 1, "YYYY-MM-DD");
      const payload = {
        fromDate,
        toDate,
        sort: LEARNING_RESULT_DEFAULT_SORT,
        classId: this.listCheckBox,
      };
      await this.fetchLearningResults(payload);
      this.getNewListHeader(startIndex, endIndex);
      this.loading = false;
    },
    async resetAssign() {
      const payload = { userId: this.account.id };
      await this.resetAssignCardPerDay(payload);
    },
  },
};
</script>

<style lang="scss">
.hover-scale {
  width: 20px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
}

.v-data-table__wrapper {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #bdb8b8;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #726f6f;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.table-cus {
  .v-data-table-header {
    th {
      position: relative;
    }
    .btn-open-menu {
      color: white;
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      &:disabled {
        color: #f8f8f8;
        cursor: not-allowed;
      }
    }
    .colum-fullName {
      width: 200px;
    }
    .colum-date {
      min-width: 100px;
    }
    .colum-class {
      width: 200px;
      min-width: 200px;
    }
  }
  tbody {
    th {
      border-right: thin solid rgba(0, 0, 0, 0.12);
      .colum-class,
      .colum-fullName,
      .colum-date {
        font-size: 14px;
      }
      .success,
      .error {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        i {
          color: white;
          font-size: 12px;
        }
      }
    }
  }
}
.filter-classes {
  .v-list-item {
    &:hover {
      background-color: #f6f6f6;
    }
  }
  .v-messages {
    display: none;
  }
  .v-input--selection-controls {
    margin-top: 0;
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}
.reset-assign-btn {
  display: none;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 5;
}
@media screen and (min-width: 1200px) {
  .v-data-table__wrapper {
    .v-data-table-header {
      .colum-class {
        width: 20%;
      }
      .colum-fullName {
        width: 15%;
      }
    }
  }
}
</style>

<style lang="sass" scoped>
.v-data-table
  border: 1px solid rgba(0, 0, 0, 0.12)
  border-top-left-radius: 12px
  border-top-right-radius: 12px
::v-deep .v-data-table__wrapper
  .v-data-table-header
    background-color: #453FE3 !important
    th
      border-right: 1px solid
      color: #FFFFFF !important
      &:first-child
        border-top-left-radius: 12px
      &:last-child
        border-top-right-radius: 12px
        border-right: none
  tbody
    tr
      td
        font-weight: 600
        &:nth-child(2)
          color: #3887FE
        &:nth-child(3)
          color: #1BB763
</style>
