export default {
  SET_FOLDER_LIST(state, payload) {
    state.folderList = payload;
  },

  SET_CURRENT_FOLDER_ID(state, payload) {
    state.currentFolderId = payload;
  },

  SET_CARD_SETS(state, payload) {
    state.cardSets = payload;
  },

  SET_CLASSES(state, payload) {
    state.classes = payload;
  },

  SET_CLASSES_PRO(state, payload) {
    state.proClasses = payload;
  },

  SET_UNASSIGNED_CLASSES(state, payload) {
    state.unassignedClasses = payload;
  },

  SET_ACCOUNT_LIST(state, payload) {
    state.accounts = payload;
  },

  SET_ERROR_ACCOUNT(state, payload) {
    state.error = payload.error;
    switch (payload.errorCode) {
      case "500":
        state.errorMessage = payload.message;
        break;
      case "404":
        state.errorMessage = "Không tìm thấy tài khoản";
        break;
      case "400":
        state.errorMessage = "Tên tài khoản đã tồn tại";
        break;
      default:
        state.errorMessage = "";
        break;
    }
  },
  SET_LEARNING_RESULTS(state, payload) {
    state.learningResults = payload;
  },
};
