export default {
  getPreviousElementId(state) {
    return state.previousElementId;
  },
  getNextElementId(state) {
    return state.nextElementId;
  },
  getCurrentCard(state) {
    return state.currentCard;
  },
  getCurrentGroupCardId(state) {
    return state.currentGroupCardId;
  },
  getNextCard(state) {
    return state.nextCard;
  },
  getPreviousCard(state) {
    if (state.previousCards.length > 0) {
      let previousCard = state.previousCards[state.previousCards.length - 1];
      return previousCard;
    } else {
      return null;
    }
  },
  havePreviousCard(state) {
    return state.previousCards.length > 0;
  },
  getPreviousNumberOfCard(state) {
    if (state.previousNumberOfCards.length > 0) {
      let previousNumberOfCards =
        state.previousNumberOfCards[state.previousNumberOfCards.length - 1];
      return previousNumberOfCards;
    } else {
      return null;
    }
  },
};
