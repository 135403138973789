import actions from "@/store/modules/admin/actions";
import mutations from "@/store/modules/admin/mutations";
import getters from "@/store/modules/admin/getters";

export default {
  namespaced: true,
  state() {
    return {
      folderList: [],
      cardSets: [],
      cardSetDetails: [],
      classes: [],
      proClasses: [],
      unassignedClasses: [],
      accounts: [],
      learningResults: [],
      error: false,
      errorMessage: "",
      currentFolderId: "",
    };
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
};
