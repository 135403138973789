<template>
  <v-row>
    <template v-if="mobileTab == 'cardDetail'">
      <v-col
        v-if="!isProCard"
        class="button-mobile d-flex-center"
        :class="groupCardType ? 'unsave-btn' : 'save-btn'"
        @click="$emit('saveOrUnsave')"
      >
        <v-icon
          :title="groupCardType ? 'Bỏ lưu thẻ' : 'Lưu thẻ'"
          :style="{ color: groupCardType ? '#384961' : '#1bb763' }"
          >{{
            groupCardType ? "mdi-bookmark-off-outline" : "mdi-bookmark-outline"
          }}</v-icon
        >
        <p>{{ groupCardType ? "Bỏ lưu thẻ" : "Lưu thẻ" }}</p>
      </v-col>

      <v-col
        class="button-mobile d-flex-center delete-btn"
        @click="
          $emit(
            'unassign',
            cardList.find((card) => card.detailCardId === cardDetail.id)
          )
        "
      >
        <v-icon title="Xóa thẻ" style="color: #fd443a"
          >mdi-trash-can-outline</v-icon
        >
        <p>Xóa thẻ</p>
      </v-col>

      <v-col
        v-if="isProCard"
        class="button-mobile d-flex-center save-btn"
        @click="$emit('preview')"
      >
        <v-icon title="Preview" style="color: #1bb763">mdi-eye</v-icon>
        <p>Preview</p>
      </v-col>

      <v-col
        v-if="!isCreatedByAdmin"
        class="button-mobile d-flex-center"
        @click="$emit('update')"
      >
        <v-icon title="Update" style="color: #453fe3">mdi-update</v-icon>
        <p>Update</p>
      </v-col>
    </template>

    <template v-else>
      <v-col class="button-mobile d-flex-center" @click="$emit('multipleMove')">
        <p class="fs-12">Đổi thông tin</p>
      </v-col>
      <v-col
        class="button-mobile d-flex-center delete-btn"
        @click="$emit('unassign')"
      >
        <v-icon title="Xóa thẻ" style="color: #fd443a"
          >mdi-trash-can-outline</v-icon
        >
        <p>Xóa thẻ</p>
      </v-col>
      <v-col
        class="button-mobile d-flex-center"
        :class="groupCardType ? 'unsave-btn' : 'save-btn'"
        @click="
          () => {
            $emit('saveOrUnsave');
          }
        "
      >
        <v-icon
          :title="groupCardType ? 'Bỏ lưu thẻ' : 'Lưu thẻ'"
          :style="{ color: groupCardType ? '#384961' : '#1bb763' }"
          >{{
            groupCardType ? "mdi-bookmark-off-outline" : "mdi-bookmark-outline"
          }}</v-icon
        >
        <p>{{ groupCardType ? "Bỏ lưu thẻ" : "Lưu thẻ" }}</p>
      </v-col>
      <v-col class="button-mobile d-flex-center" @click="$emit('toggleSelect')">
        <p>{{ isSelecting ? "Chọn thẻ" : "Hủy chọn" }}</p>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    mobileTab: {
      type: String,
      default: "",
    },
    groupCardType: {
      type: Number,
      default: 0,
    },
    isSelecting: {
      type: Boolean,
      default: false,
    },
    isProCard: {
      type: Boolean,
      default: false,
    },
    isCreatedByAdmin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("card", ["cardDetail"]),
    ...mapState("list", ["cardList"]),
  },
};
</script>

<style lang="scss" scoped>
.mobile-toolbar {
  .button-mobile {
    padding: 6px;
    &.save-btn p {
      color: #1bb763;
    }
    &.unsave-btn p {
      color: #384961;
    }
    &.delete-btn p {
      color: #fd443a;
    }
    p {
      font-weight: 600;
      font-size: 14px;
      margin-left: 5px;
      color: #453fe3;
      &.fs-12 {
        font-size: 12px;
      }
    }
  }
}
</style>
