import axios from "@/services/axios";
const BASE_ADMIN_URL = "admin/";
const BASE_USER_URL = "user/";
const BASE_STUDENT_URL = "student/";

export default {
  async reschedule(context, payload) {
    await axios
      .put(
        `${BASE_ADMIN_URL}${BASE_STUDENT_URL}${payload.studentId}/learnCards/reschedule`,
        payload.body
      )
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async unassignCard(context, ids) {
    await axios
      .delete(`${BASE_ADMIN_URL}${BASE_STUDENT_URL}card`, {
        data: { ids },
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async deleteProCard(context, ids) {
    await axios
      .delete(`${BASE_STUDENT_URL}cards`, {
        data: { ids },
      })
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async fetchAllLearnCard(
    context,
    { isSearchByWord, isSearchByMeaning, isSearchByExample, studentId, search }
  ) {
    await axios
      .post(`${BASE_USER_URL}${studentId}/learn-cards`, {
        isSearchByWord: isSearchByWord,
        isSearchByMeaning: isSearchByMeaning,
        isSearchByExample: isSearchByExample,
        search: search,
      })
      .then((res) => {
        context.commit("SET_CARD_LIST", res.data.data);
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
