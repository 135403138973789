import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/services/axios";
import "@/assets/styles/style.sass";
import setupInterceptors from "./services/interceptor";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import { VueHammer } from "vue2-hammer";
import vClickOutside from "v-click-outside";

const doubleTap = { type: "Tap", event: "doubletap", taps: 2 };

VueHammer.customEvents = {
  doubletap: doubleTap,
};

Vue.use(vClickOutside);
Vue.use(VueHammer);
Vue.use(CKEditor);
setupInterceptors(store);

Vue.prototype.$isProStudent = false;
Vue.prototype.$isOldProStudent = false;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
