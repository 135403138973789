<template>
  <v-row justify="center">
    <!-- ----------Allocate Card Set------------ -->
    <v-dialog
      :value="showDialogValue"
      content-class="assign-dialog"
      max-width="700"
      @click:outside="handleCloseDialog"
    >
      <v-card class="pa-8" v-if="showSelect">
        <v-card-title class="d-flex pa-0 mb-2">
          <div>
            Học trong
            <input
              type="number"
              class="allocate-input"
              v-model="timeToComplete"
            />
            ngày
          </div>
          <div
            class="dialog-helper-text text-averta-400"
            v-text="`Nhập số ngày ≤ ${totalCards} (Tổng số thẻ)`"
            style="font-size: 14px"
          ></div>
        </v-card-title>
        <hr />
        <v-card-text class="pa-0 mb-7">
          <h6 class="pa-0 mt-2">Nạp cho lớp và học sinh:</h6>
          <treeselect
            v-model="selectedStudentList"
            :options="getUnassignedClasses"
            multiple
            clearable
            searchable
            showCount
            searchNested
            :maxHeight="250"
            openDirection="below"
            placeholder="Chọn lớp/học sinh muốn nạp thẻ."
          />
        </v-card-text>
        <v-card-actions class="d-flex align-center pa-0">
          <v-spacer></v-spacer>
          <v-btn class="cancel-btn" elevation="0" @click="handleCloseDialog">
            <h6>Hủy</h6>
          </v-btn>

          <ButtonComponent
            title="Nạp thẻ"
            class="ml-3"
            :disable="!selectedStudentList.length"
            @click="handleConfirmRequest"
          />
        </v-card-actions>
      </v-card>
      <v-card class="pa-8" v-else>
        <v-card-title class="pa-0 mb-7">
          <div>
            Học trong
            <input
              type="number"
              class="allocate-input"
              v-model="timeToComplete"
            />
            ngày
          </div>
          <div
            class="dialog-helper-text text-averta-400"
            v-text="`Nhập số ngày ≤ ${totalCards} (Tổng số thẻ)`"
            style="font-size: 14px"
          ></div>
          <template v-if="groupCard.id">
            <span
              class="dialog-helper-text text-averta-400"
              style="font-size: 14px; line-height: 1"
              >{{ assignGroupAt }}</span
            >
          </template>
        </v-card-title>
        <v-card-actions class="d-flex align-center pa-0">
          <v-spacer></v-spacer>
          <v-btn class="cancel-btn" elevation="0" @click="handleCloseDialog">
            <h6>Hủy</h6>
          </v-btn>

          <ButtonComponent
            title="Gán"
            class="ml-3"
            @click="handleClickReAssign"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import { mapGetters, mapActions } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import moment from "moment";

export default {
  components: { ButtonComponent, Treeselect },
  props: {
    showDialogValue: {
      type: Boolean,
      default: false,
    },
    groupCardId: {
      type: [String, Number],
      required: true,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    totalCards: {
      type: Number,
      default: 0,
    },
    groupCard: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeToComplete: 0,
      selectedStudentList: [],
    };
  },
  computed: {
    ...mapGetters("admin", ["getUnassignedClasses"]),
    studentId() {
      return this.$route.params.studentId;
    },
    assignGroupAt() {
      const timeToComplete = this.groupCard.timeToComplete;
      const createdAt = moment(
        moment(this.groupCard.userGroupCreatedAt).subtract(4, "hours")
      )
        .startOf("days")
        .add(4, "hours")
        .startOf("hours");
      const now = moment(moment().subtract(4, "hours"))
        .startOf("days")
        .add(4, "hours")
        .startOf("hours");
      const diffToToday = now.diff(createdAt, "days");
      return `Ngày gán: ${moment(createdAt).format("DD-MM-YYYY")} (${
        diffToToday + 1
      } / ${timeToComplete})`;
    },
  },
  methods: {
    ...mapActions("admin", ["assignProCardGroup"]),
    async handleConfirmRequest() {
      if (this.timeToComplete > this.totalCards) {
        alert("Vui lòng nhập số ngày ≤ tổng sổ thẻ là " + this.totalCards);
        return;
      }
      let selectedStudentList = [];
      this.selectedStudentList.forEach((selected) => {
        if (selected.toString().includes("C_")) {
          let selectedClass = this.getUnassignedClasses.find(
            (item) => item.id == selected
          );
          if (selectedClass != null) {
            if (selectedClass.children.length > 0) {
              selectedClass.children.forEach((student) => {
                selectedStudentList.push({ studentId: student.id });
              });
            }
          }
        } else {
          selectedStudentList.push({ studentId: selected });
        }
      });
      await this.assignProCardGroup({
        groupCardId: this.groupCardId,
        body: {
          timeToComplete: +this.timeToComplete,
          selectedStudents: selectedStudentList,
        },
      });
    },
    handleCloseDialog() {
      this.$emit("closeDialog");
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
    async handleClickReAssign() {
      if (this.timeToComplete > this.totalCards) {
        alert("Vui lòng nhập số ngày ≤ tổng sổ thẻ là " + this.totalCards);
        return;
      }
      await this.assignProCardGroup({
        groupCardId: this.groupCard.groupCardId,
        body: {
          timeToComplete: this.timeToComplete,
          selectedStudents: [
            {
              studentId: this.studentId,
            },
          ],
        },
      });
      this.handleCloseDialog();
      this.timeToComplete = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  flex-direction: column;
  align-items: baseline;
}
::v-deep .vue-treeselect {
  max-width: calc(100% - 160px);
  .vue-treeselect__menu {
    max-height: 250px;
  }
}
::v-deep .assign-dialog {
  overflow-y: visible !important;
}
.allocate-input {
  margin: 0 10px;
  width: 50px;
  border: 1px solid black;
  padding: 1px;
}
</style>

<style lang="sass" scoped>
.cancel-btn
  background-color: transparent !important
  text-transform: initial
  color: #453FE3
.v-card
  border-radius: 12px !important
</style>
