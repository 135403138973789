export const getLocalStore = (storeName) => {
  if (!storeName) {
    return "";
  }
  return localStorage.getItem(storeName) ?? "";
};

export const setLocalStore = (storeName, value = 0) => {
  if (storeName) {
    localStorage.setItem(storeName, value);
  }
};
