<template>
  <v-container fluid>
    <v-row
      class="d-flex justify-center align-center"
      :class="{
        'mt-4': $vuetify.breakpoint.smAndUp,
      }"
      @keyup.enter="$emit('pressEnter')"
    >
      <v-col
        cols="12"
        md="8"
        :class="{
          'pt-4 px-1': $vuetify.breakpoint.xsOnly,
        }"
      >
        <!--  ----------Header---------- -->
        <v-card elevation="0">
          <v-flex class="d-flex mt-1">
            <v-flex class="d-flex align-center mb-2">
              <img
                src="@/assets/icons/button-back.svg"
                @click="$emit('back')"
                style="cursor: pointer; width: 40px"
              />
              <v-card-subtitle class="pa-0 d-flex align-center ml-2">
                <p class="mb-0 captionOne">
                  {{ subtitle }}
                </p>
              </v-card-subtitle>
            </v-flex>
            <ButtonComponent
              class="mb-2"
              title="Browse"
              isBrowseButton
              isGrayBtn
              @click="$router.push(`/browse/${studentId}`)"
            />
          </v-flex>

          <v-card-title
            class="pa-0 align-baseline"
            :class="{
              'd-flex flex-column align-start': $vuetify.breakpoint.xsOnly,
            }"
          >
            <h4
              :class="[
                'mobile-title' || $vuetify.breakpoint.smAndDown,
                'text-overflow',
                'cardGroupTitle',
              ]"
              :title="title"
            >
              {{ title }}
            </h4>
            <p
              :class="[
                $vuetify.breakpoint.smAndUp ? '' : 'mobile',
                'text-averta-400',
              ]"
            >
              {{ timeLeft }}
            </p>
          </v-card-title>
          <!-- ----------Start header of content---------- -->
          <v-row class="d-flex justify-center">
            <v-col cols="12" md="12" style="padding: 8px 6px 6px">
              <v-card
                class="layout-card-wrapper overflow-scroll"
                :class="{
                  'pa-1': $vuetify.breakpoint.xsOnly,
                  'pb-80':
                    $route.name == 'cardDetailsAnswer' &&
                    $vuetify.breakpoint.xsOnly,
                  'pb-100':
                    $route.name == 'cardDetailsAnswer' &&
                    $vuetify.breakpoint.smAndUp,
                  coccoc: isNormalBrowser,
                }"
                elevation="0"
              >
                <v-row v-if="$vuetify.breakpoint.smAndDown" class="mask">
                  <v-col
                    ref="left-col"
                    v-hammer:tap="
                      () => {
                        handleMobileTouch('leftSingleTap');
                      }
                    "
                    v-hammer:doubletap="
                      () => {
                        handleMobileTouch('leftDoubleTap');
                      }
                    "
                  ></v-col>
                  <v-col
                    ref="right-col"
                    v-hammer:tap="
                      () => {
                        handleMobileTouch('rightSingleTap');
                      }
                    "
                    v-hammer:doubletap="
                      () => {
                        handleMobileTouch('rightDoubleTap');
                      }
                    "
                  ></v-col>
                </v-row>
                <v-row
                  class="ma-0"
                  :class="{
                    'mb-4': $vuetify.breakpoint.smAndUp,
                  }"
                >
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pa-0 layout-type-wrapper"
                  >
                    <h6 class="layout-type">{{ type }}</h6>
                    <!-- --------------------Menu item------------------- -->
                    <v-menu
                      offset-y
                      transition="slide-y-transition"
                      location="end"
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <div class="btn-area" v-bind="attrs" v-on="on">
                          <img src="@/assets/icons/menu.svg" alt="" />
                        </div>
                      </template>

                      <v-list class="pa-0 layout-list">
                        <v-list-item
                          class="px-5 py-1 cursor-pointer"
                          v-for="(item, index) in items"
                          :class="
                            item.icon == 'back' && !havePreviousCard
                              ? 'disable'
                              : ''
                          "
                          :key="index"
                          link
                          @click="$emit(item.emitFunction)"
                          :disable="disableActions"
                        >
                          <v-img :src="getIcon(item.icon)" />
                          <v-list-item-title class="ml-4 d-flex">
                            <h6 :style="item.color">{{ item.title }}</h6>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
                <slot></slot>
                <div
                  class="mt-2 footer"
                  :class="{ mobile: $vuetify.breakpoint.xsOnly }"
                >
                  <v-divider v-if="haveFooter" class="mb-2"></v-divider>
                  <FooterCardView
                    v-if="haveFooter"
                    @clickRelearn="$emit('clickRelearn')"
                    @clickEasyType="$emit('clickEasyType')"
                    @clickNormalType="$emit('clickGoodType')"
                    @clickHardType="$emit('clickHardType')"
                    @clickDelete="$emit('clickDelete')"
                    @clickCloneType="$emit('clickCloneType')"
                    :disableActions="disableActions"
                  />
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import FooterCardView from "@/views/card/FooterCardView.vue";
import { mapGetters, mapState } from "vuex";
import {
  GROUP_CARD_TYPE_STORE_NAME,
  group_card_type,
} from "@/constants/groupcard";
import { MENU_CARD_DETAIL } from "@/constants/menu";
import { getListMenuGroupCard } from "@/uttil/menu";
import { getLocalStore } from "@/uttil/localStorage";
import Hammer from "hammerjs";

export default {
  props: {
    subtitle: String,
    title: String,
    type: String,
    timeLeft: String,
    haveFooter: {
      type: Boolean,
      default: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  components: { FooterCardView, ButtonComponent },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.customDoubleTapEvent();
  },
  created() {
    this.setMenuAction();
  },
  computed: {
    ...mapState("card", ["previousCard"]),
    ...mapGetters("card", ["getCurrentCard", "havePreviousCard"]),
    isNormalBrowser() {
      const isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && window["safari"].pushNotification)
        );
      const isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);
      const isOpera =
        !!window.opr ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;
      const isFirefox = typeof InstallTrigger !== "undefined";
      if (isSafari || isChrome || isOpera || isFirefox) {
        return true;
      } else return false;
    },
    studentId() {
      return this.$route.params.studentId;
    },
  },
  methods: {
    customDoubleTapEvent() {
      let leftCol = this.$refs["left-col"]?.hammer;
      let rightCol = this.$refs["right-col"]?.hammer;

      if (leftCol) {
        leftCol.add(new Hammer.Tap({ event: "doubletap", taps: 2 }));
        leftCol.add(new Hammer.Tap({ event: "tap" }));

        leftCol.get("doubletap").recognizeWith("tap");
        leftCol.get("tap").requireFailure("doubletap");
      }

      if (rightCol) {
        rightCol.add(new Hammer.Tap({ event: "doubletap", taps: 2 }));
        rightCol.add(new Hammer.Tap({ event: "tap" }));

        rightCol.get("doubletap").recognizeWith("tap");
        rightCol.get("tap").requireFailure("doubletap");
      }
    },
    handleMobileTouch(name) {
      this.$emit(name);
    },
    getIcon(name) {
      return require("@/assets/icons/" + name + ".svg");
    },
    setMenuAction() {
      const groupCardType = getLocalStore(GROUP_CARD_TYPE_STORE_NAME)
        ? getLocalStore(GROUP_CARD_TYPE_STORE_NAME)
        : group_card_type.GROUP_CARD_DEFAULT;

      const menu = getListMenuGroupCard(groupCardType, MENU_CARD_DETAIL);
      this.items = menu;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-type-wrapper {
  align-items: center;
}
.cardGroupTitle {
  width: 100vw;
}
.mobile-title {
  font-weight: 600;
  font-size: 23px;
}
.text-averta-400.mobile {
  font-size: 14px;
  font-weight: 400;
}
.layout-card-wrapper {
  overflow: auto;
  height: calc(100vh - 250px);
  overflow-x: hidden;
  position: relative;
  &.pb-80 {
    padding-bottom: 80px !important;
  }
  .mask {
    position: absolute;
    width: 100%;
    top: 12px;
    left: 12px;
    height: 100%;
  }
  &.coccoc {
    height: calc(100vh - 260px);
  }
  .footer {
    position: sticky;
    background: #f9fbfc;
    bottom: 0px;
    &.mobile {
      width: calc(100vw - 24px);
      position: fixed;
      bottom: 40px;
    }
  }
}
.cursor-pointer.disable {
  cursor: not-allowed;
  pointer-events: none;
}
</style>

<style lang="sass" scoped>
.btn-area
  background: #D9DFEA
  border-radius: 8px
  width: 40px
  height: 40px
  display: flex
  justify-content: center
  align-items: center
  z-index: 2
.layout-card-wrapper
  border-radius: 16px
  background-color: #F9FBFC
  padding: 24px 24px 0px

.layout-type
  padding: 4px 8px
  background-color: #E9EDF5
  border-radius: 6px
  color: #384961
  font-size: 16px
  font-weight: 600
  line-height: 24px
  height: 32px
.layout-list
  min-width: 185px
  min-height: 50px

.v-menu__content
  border-radius: 12px
  //left: 62vw !important
  //top: 25vh !important

.v-list-item__title
  font-style: normal
  font-weight: 600
  font-size: 16px
  line-height: 150%

// ------------------- Mobile ------------------------ //
.layout-card-wrapper-xs
  padding: 8px
  margin-top: 16px
</style>
