<template>
  <div class="container">
    <div class="d-flex folder-table-header" @click="toggleFolderList()">
      <div v-text="folderTitle === '' ? 'Chọn thư mục' : folderTitle"></div>
      <img :src="getImgUrl('icons/arrow-down.svg')" />
    </div>
    <transition name="fade">
      <div
        v-if="showDropdownFolderList"
        class="folder-table-list overflow-scroll"
      >
        <div class="folder-table-row">
          <input
            type="text"
            placeholder="Nhập tên thư mục"
            class="add-new-folder"
            v-model="newFolderName"
            @keyup.enter="addFolder"
          />
          <img
            v-if="newFolderName != ''"
            class="cursor-pointer"
            :src="getImgUrl('icons/trash.svg')"
            @click="newFolderName = ''"
          />
        </div>
        <div
          v-for="folder in folderList"
          :key="folder.id"
          class="folder-table-row cursor-pointer"
          @click.self="openFolder(folder.id, folder.folderName)"
        >
          <p
            v-if="!folder.isEditing"
            class="folder-name"
            @dblclick="toggleRenameFolder(folder.id)"
          >
            {{ folder.folderName }}
          </p>
          <input
            v-else
            id="rename-folder"
            type="text"
            class="add-new-folder"
            @keyup.enter="updateFolderName(folder.id, folder.folderName)"
            @blur="quitRenameFolder(folder.id)"
            v-model="folder.folderName"
          />
          <div class="button-group d-flex">
            <p
              v-if="folder.isEditing"
              class="cursor-pointer cancel-rename"
              @click="quitRenameFolder(folder.id)"
            >
              X
            </p>
            <img
              class="cursor-pointer"
              :src="getImgUrl('icons/trash.svg')"
              @click="onDeleteFolderDialog(folder.id)"
            />
          </div>
        </div>
      </div>
    </transition>
    <DialogComponent
      typeDialog="confirm"
      description="Bạn chắc chắn muốn xóa thư mục?"
      :showDialogValue="isShowDialog"
      @closeDialog="isShowDialog = false"
      @confirmRequest="onDeleteFolder"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DialogComponent from "@/components/ui/DialogComponent.vue";

export default {
  components: {
    DialogComponent,
  },
  data() {
    return {
      folderTitle: "",
      showDropdownFolderList: false,
      folderList: [],
      newFolderName: "",
      isShowDialog: false,
      deletingFolderId: "",
    };
  },
  computed: {
    ...mapGetters("admin", ["getFolderList"]),
  },
  created() {
    this.toggleFolderList();
  },
  methods: {
    ...mapActions("admin", [
      "fetchFolderList",
      "createNewFolder",
      "updateFolder",
      "deleteFolder",
      "selectFolder",
    ]),
    async updateFolderName(id, newFolderName) {
      if (newFolderName != "") {
        await this.updateFolder({ folderId: id, folderName: newFolderName });
        await this.fetchFolderList();
        document.getElementById("rename-folder").blur();
      }
    },
    toggleRenameFolder(folderId) {
      this.$set(
        this.folderList.find((folder) => folder.id == folderId),
        "isEditing",
        true
      );
      this.$nextTick(() => {
        document.getElementById("rename-folder").focus();
      });
    },
    quitRenameFolder(folderId) {
      const currentFolder = this.folderList.find(
        (folder) => folder.id == folderId
      );
      currentFolder.isEditing = false;
      currentFolder.folderName = this.getFolderList.find(
        (folder) => folder.id == folderId
      ).folderName;
    },
    async openFolder(folderId, folderName) {
      this.folderTitle = folderName;
      this.selectFolder({ folderId: folderId });
      this.$emit("chooseFolder", folderId);
      this.showDropdownFolderList = false;
    },
    async addFolder() {
      if (this.newFolderName != "") {
        await this.createNewFolder(this.newFolderName);
        await this.fetchFolderList();
        this.folderList = JSON.parse(JSON.stringify(this.getFolderList));
        this.newFolderName = "";
      }
    },
    async onDeleteFolder() {
      await this.deleteFolder(this.deletingFolderId);
      this.folderList = this.folderList.filter(
        (folder) => folder.id != this.deletingFolderId
      );
      this.isShowDialog = false;
    },
    async toggleFolderList() {
      if (!this.showDropdownFolderList) {
        await this.fetchFolderList();
        this.folderList = JSON.parse(JSON.stringify(this.getFolderList));
      }
      this.showDropdownFolderList = !this.showDropdownFolderList;
    },
    onDeleteFolderDialog(folderId) {
      this.deletingFolderId = folderId;
      this.isShowDialog = true;
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 8px 0 26px;
  position: relative;
}
.folder-table-header {
  background-color: #453fe3;
  height: 48px;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 10px;
  cursor: pointer;
}

.folder-table-list {
  position: absolute;
  background: white;
  border: 1.5px solid #453fe3;
  border-top: none;
  border-radius: 4px;
  width: 100%;
  z-index: 2;
  max-height: 400px;
  overflow: auto;
  .folder-table-row {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    &.cursor-pointer:hover {
      background-color: #ccc;
    }
    .folder-name {
      margin: 0;
      padding: 0 10px;
      z-index: 2;
    }

    .button-group {
      p {
        padding: 0px;
        text-align: center;
        margin: 0px;
      }
      * {
        width: 20px;
        transition: transform 0.2s;
      }
      *:hover {
        transform: scale(1.2);
      }
    }
  }
  .add-new-folder {
    padding: 0 5px;
    &:focus {
      outline: none;
    }
  }
}
</style>
