<template>
  <v-app style="overflow-x: hidden; overflow-y: auto">
    <v-main>
      <v-overlay v-if="overlay" class="align-center justify-center">
        <v-progress-circular
          color="primary"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <NavBarComponent v-if="isLoggedIn || this.$route.path === '/login'" />
      <keep-alive include="ListView, LayoutList, FormAddNewCard, TabsView">
        <router-view></router-view>
      </keep-alive>
      <v-chip
        v-if="isTestEnv"
        class="ma-2 env"
        color="warning"
        variant="outlined"
        label
      >
        TEST
      </v-chip>
    </v-main>
    <DialogComponent
      v-if="isOpenDialog"
      :typeDialog="configDialog.typeDialog"
      :showDialogValue="isOpenDialog"
      :description="configDialog.description"
      :autoClose="configDialog.autoClose"
      @closeDialog="SET_DIALOG(false)"
    />
  </v-app>
</template>

<script>
import NavBarComponent from "@/components/ui/NavBarComponent.vue";
import DialogComponent from "@/components/ui/DialogComponent.vue";
import "@/assets/styles/style.sass";
import { mapMutations, mapState } from "vuex";
import TokenService from "@/services/token.service";
import { STUDENT_PRO_STATUS_LIST } from "./constants";
import Vue from "vue";

export default {
  name: "App",
  components: { NavBarComponent, DialogComponent },
  data: () => ({
    mobileScreen: false,
    overlay: false,
  }),
  computed: {
    ...mapState("auth", ["isLoggedIn"]),
    ...mapState("common", ["isOpenDialog", "configDialog"]),
    isTestEnv() {
      const hostname = window.location.hostname;
      return (
        hostname.indexOf("test.") != -1 || hostname.indexOf("localhost") == 0
      );
    },
  },
  methods: {
    ...mapMutations("auth", ["SET_IS_LOGGED_IN"]),
    ...mapMutations("common", ["SET_DIALOG"]),
  },
  mounted() {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message === "Script error."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
    this.mobileScreen = this.$vuetify.breakpoint.xsOnly;
    localStorage.setItem("mobileScreen", this.mobileScreen);
    this.SET_IS_LOGGED_IN({
      status: !!localStorage.getItem("accessToken"),
      account: TokenService.getAccount(),
    });
    Vue.prototype.$isProStudent =
      TokenService.getAccount().status === STUDENT_PRO_STATUS_LIST.PRO;
    Vue.prototype.$isOldProStudent =
      TokenService.getAccount().status === STUDENT_PRO_STATUS_LIST.USED_TO_PRO;
  },
};
</script>

<style lang="scss">
.disable-copy {
  user-select: none;
}
* {
  word-break: break-word;
  // white-space: nowrap;
}
.ps-abs {
  position: absolute;
}
.ps-rlt {
  position: relative;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input:focus-visible {
  border-color: hsl(218, 81.6%, 56.9%) !important;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<style lang="sass">
// ---------- convert 1 rem = 10px ---------- //
html
  box-sizing: border-box
  overflow: auto
.v-application
  font-family: AvertaRegular
.v-chip.env
  position: fixed
  bottom: 30px
  left: 20px
</style>
