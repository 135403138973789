<template>
  <div class="mt-1 mb-4 d-flex align-center audio-recorder">
    <p>Thu âm</p>
    <v-btn
      @click="startRecording"
      v-if="!isRecording"
      class="mx-2 audio-record-control"
      fab
      dark
      small
      color="pink"
      min-width="40"
      width="40"
    >
      <v-icon dark> mdi-microphone </v-icon>
    </v-btn>
    <v-btn
      @click="stopRecording"
      v-else
      class="mx-2 audio-record-control"
      fab
      dark
      small
      color="pink"
    >
      <v-icon dark> mdi-stop-circle </v-icon>
    </v-btn>

    <div v-if="isRecording" class="music-waves-2">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
// import { createFFmpeg } from "@ffmpeg/ffmpeg";
export default {
  data() {
    return {
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      audioFile: null,
      stream: null, // Save the stream to stop microphone access later
      // ffmpeg: createFFmpeg({ log: true }),
    };
  },
  methods: {
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
          this.audioUrl = URL.createObjectURL(audioBlob);
          this.audioChunks = [];
          this.audioFile = new File([audioBlob], "recording.wav", {
            type: "audio/wav",
          });
        };

        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error("Error accessing media devices.", error);
        alert("Error accessing media devices.");
      }
    },
    async stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.isRecording = false;

        // Stop all tracks of the media stream to release the microphone
        // this.stream.getTracks().forEach((track) => track.stop());
        setTimeout(() => {
          this.$emit("stopRecord", {
            audioUrl: this.audioUrl,
            audioFile: this.audioFile,
          });
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
button {
  margin-right: 10px;
}
.audio-recorder {
  gap: 10px;
}
.music-waves-2 {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.music-waves-2 > span {
  display: block;
  position: relative;
  background: #000;
  height: 100%;
  width: 1rem;
  border-radius: 1rem;
  margin: 0 0.2rem;
  animation: waves-2 1.2s linear infinite;
}

.music-waves-2 > span:nth-child(1) {
  animation-delay: 0s;
  background: #ff8c00;
}

.music-waves-2 > span:nth-child(2) {
  animation-delay: 0.3s;
  background: #ffff00;
}

.music-waves-2 > span:nth-child(3) {
  animation-delay: 0.6s;
  background: #26d53a;
}

.music-waves-2 > span:nth-child(4) {
  animation-delay: 0.9s;
  background: #26e6a3;
}

.music-waves-2 > span:nth-child(5) {
  animation-delay: 0.6s;
  background: #1da8e2;
}

.music-waves-2 > span:nth-child(6) {
  animation-delay: 0.3s;
  background: #5916ba;
}

.music-waves-2 > span:nth-child(7) {
  animation-delay: 0s;
  background: #d418d9;
}

@keyframes waves-2 {
  50% {
    height: 20%;
  }

  100% {
    height: 100%;
  }
}
</style>
