<template>
  <v-container fluid>
    <v-overlay
      :value="loading"
      class="align-center justify-center"
      style="z-index: 10"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row v-if="!loading" class="mt-0">
      <v-col :md="showDialogCreateProCard ? 8 : 12" :sm="12">
        <v-card elevation="0" class="d-flex justify-space-between mb-3">
          <ButtonComponent
            v-if="$route.name == 'manageStudentGroupCard'"
            title="Back"
            @click="$router.go(-1)"
          />
          <v-card-title
            class="pa-0"
            :class="{
              'd-flex align-items-baseline': $vuetify.breakpoint.smOnly,
            }"
          >
            <h4 v-if="$vuetify.breakpoint.smAndUp">{{ title }}</h4>
            <h5 v-if="$vuetify.breakpoint.xsOnly">{{ title }}</h5>
            <p
              v-if="!$route.meta.role"
              class="remain"
              :class="[
                $vuetify.breakpoint.smAndDown && 'mobile',
                $vuetify.breakpoint.xsOnly && 'fs13',
              ]"
            >
              Còn {{ this.learnCardLeft }} câu hỏi trong {{ this.estimateTime }}
            </p>
          </v-card-title>
          <ButtonComponent title="Browse" isGrayBtn @click="handleGoBrowse" />
        </v-card>
        <slot :openAddCardForm="() => (showDialogCreateProCard = true)"></slot>
        <v-flex class="mt-9 d-flex" :class="'justify-center'">
          <p class="captionOne" style="margin-bottom: 0px">
            <strong
              >Đã trả lời {{ cardCount }} câu hỏi trong
              {{ studyTime.toFixed(1) }} phút</strong
            >
            ({{ studySpeed }}s/câu)
          </p>
        </v-flex>
      </v-col>
      <v-col
        v-if="isListForProStudent && showDialogCreateProCard"
        md="4"
        sm="7"
      >
        <transition-group name="fade" v-if="isListForProStudent">
          <template v-if="showDialogCreateProCard">
            <FormAddNewCard
              key="add"
              @close="
                hasUnsavedNewCard
                  ? (showDialogCancelAddCard = true)
                  : (showDialogCreateProCard = false)
              "
              @onCardCreated="$emit('onCardCreated')"
              @change="(hasChange) => (hasUnsavedNewCard = hasChange)"
              @createNewCardGroup="setSlotParentEventController"
            />
          </template>
        </transition-group>
      </v-col>
    </v-row>

    <DialogComponent
      typeDialog="confirm"
      description="Loại bỏ thay đổi và không tạo thẻ nữa?"
      :showDialogValue="showDialogCancelAddCard"
      @closeDialog="showDialogCancelAddCard = false"
      @confirmRequest="
        showDialogCancelAddCard = false;
        showDialogCreateProCard = false;
      "
    />
  </v-container>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import { mapGetters, mapState, mapMutations } from "vuex";
import { convertSecondsToTime } from "@/uttil";
import FormAddNewCard from "@/views/admin/FormAddNewCard.vue";
import DialogComponent from "@/components/ui/DialogComponent.vue";
export default {
  name: "LayoutList",
  components: { ButtonComponent, FormAddNewCard, DialogComponent },
  props: {
    title: {
      type: String,
      default: "",
    },
    learningResult: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDialogCreateProCard: false,
      showDialogCancelAddCard: false,
      hasUnsavedNewCard: false,
    };
  },
  computed: {
    ...mapGetters("list", ["getListCardGroup"]),
    ...mapState("list", ["timeStudyAvg"]),
    cardCount() {
      return this.learningResult?.cardCount;
    },
    studyTime() {
      return Math.round((this.learningResult?.studyTime / 60) * 100) / 100;
    },
    studySpeed() {
      return this.learningResult?.studyTime
        ? (this.learningResult?.studyTime / this.cardCount).toFixed(1)
        : 0;
    },
    learnCardLeft() {
      let newCard = 0;
      let revisingCard = 0;
      let cardGroups = this.getListCardGroup?.cardGroups || [];
      for (let userGroupCard of cardGroups) {
        newCard += userGroupCard.newCard || 0;
        revisingCard += userGroupCard.revisingCard || 0;
      }
      let proFolders = this.getListCardGroup?.proFolders || [];
      for (let folder of proFolders) {
        newCard += folder.newCard || 0;
        revisingCard += folder.revisingCard || 0;
      }
      return 3 * newCard + revisingCard;
    },
    estimateTime() {
      return convertSecondsToTime(this.learnCardLeft * this.timeStudyAvg);
    },
    isListForProStudent() {
      return this.getListCardGroup?.proFolders?.length || this.$isProStudent;
    },
  },
  methods: {
    ...mapMutations("list", ["setSlotParentEventController"]),
    handleGoBrowse() {
      this.$emit("goBrowse");
    },
  },
};
</script>

<style lang="scss" scoped>
.align-items-baseline {
  gap: 10px;
  align-items: baseline;
}
.remain {
  margin: 5px 0 0 8px;
  font-size: 16px;
  color: #384961;
  &.mobile {
    margin: 0px;
    font-size: 16px;
    line-height: 21px;
    &.fs13 {
      font-size: 13px;
    }
  }
}
</style>
