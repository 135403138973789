var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-between align-center"},[_c('p',[_vm._v(" Kiểu "+_vm._s([1, 4].includes(_vm.currentTypeCard) ? 1 : [2, 5].includes(_vm.currentTypeCard) ? 2 : 3)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.currentStep.title)+" ")])]),(_vm.currentStep.isFrontSide)?[_c('div',{staticClass:"mb-2 justify-center d-flex front-side",class:{
        'mb-4': _vm.$vuetify.breakpoint.xsOnly,
      }},[_c('div',{staticClass:"col justify-center"},[(_vm.currentTypeCard == 1 && _vm.currentCard.audioLink)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require("@/assets/icons/audio.svg")},on:{"click":_vm.handlePlayAudio}}),(_vm.currentCard.audioLink !== null)?_c('audio',{ref:"audioPlayer",attrs:{"src":_vm.currentCard.audioLink,"preload":"auto","autoplay":"","muted":""}}):_vm._e()]):(
            [4, 5].includes(_vm.currentTypeCard) ||
            ([1, 2].includes(_vm.currentTypeCard) && _vm.currentStepNo == 3)
          )?_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"word",class:{
              fw400: _vm.currentTypeCard == 5,
              fw600:
                _vm.currentTypeCard == 4 ||
                (_vm.currentTypeCard == 1 && _vm.currentStepNo == 3),
              mobile: _vm.$vuetify.breakpoint.xsOnly,
            },domProps:{"innerHTML":_vm._s(
              _vm.currentTypeCard == 4 ||
              (_vm.currentTypeCard == 1 && _vm.currentStepNo == 3)
                ? _vm.currentCard.mean
                : _vm.currentCard.meanFormatted
            )}})]):([2, 3].includes(_vm.currentTypeCard))?_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"word fw400",class:{
              mobile: _vm.$vuetify.breakpoint.xsOnly,
            },domProps:{"innerHTML":_vm._s(_vm.currentCard.wordFormatted)}})]):_vm._e(),(
            ([1, 4].includes(_vm.currentTypeCard) || _vm.currentStepNo == 3) &&
            _vm.currentCard.wordType != 'null' &&
            !!_vm.currentCard.wordType
          )?_c('div',[_c('p',{staticClass:"text-center word-type",class:{
              mobile: _vm.$vuetify.breakpoint.xsOnly,
            },staticStyle:{"color":"#f56742"}},[_vm._v(" ("+_vm._s(_vm.currentCard.wordType)+") ")])]):_vm._e()])]),_c('div',{staticClass:"front-side"},[_vm._m(0),_c('InputComponent',{attrs:{"inputProps":_vm.answerInput,"autoFocus":(_vm.currentTypeCard == 4 ||
            (_vm.currentStepNo == 3 && _vm.currentTypeCard == 1)) &&
          _vm.$vuetify.breakpoint.smAndUp},on:{"blur":function($event){_vm.isFocus = false},"focus":function($event){_vm.isFocus = true}},model:{value:(_vm.answerValue),callback:function ($$v) {_vm.answerValue=$$v},expression:"answerValue"}})],1)]:[(_vm.currentTypeCard == 1)?[_c('div',{staticClass:"justify-center d-flex mb-2"},[_c('p',{staticClass:"mean",class:{ mobile: _vm.$vuetify.breakpoint.smAndDown },domProps:{"innerHTML":_vm._s(_vm.currentCard.mean)}})]),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"word",class:{
            mobile: _vm.$vuetify.breakpoint.smAndDown,
            'mt-1': _vm.$vuetify.breakpoint.smAndUp,
          },domProps:{"innerHTML":_vm._s(_vm.currentCard.word)}})]),_c('div',{staticClass:"audio d-flex-center",class:{
          mobile: _vm.$vuetify.breakpoint.smAndDown,
          'mt-2': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown && 'img-wrapper'},[(_vm.currentCard.audioLink)?_c('img',{staticClass:"cursor-pointer",style:(_vm.$vuetify.breakpoint.smAndDown && {
                width: '16px',
                height: '16px',
              }),attrs:{"src":require("@/assets/images/volumn.svg")},on:{"click":_vm.handlePlayAudio}}):_vm._e()]),(_vm.currentCard.audioLink !== null)?_c('audio',{ref:"audioPlayer",attrs:{"src":_vm.currentCard.audioLink,"preload":"auto","autoplay":"","muted":""}}):_vm._e(),(_vm.currentCard.spelling !== 'null')?_c('p',{staticClass:"spelling"},[_vm._v(" "+_vm._s(_vm.currentCard.spelling)+" ")]):_vm._e(),(_vm.currentCard.wordType !== 'null')?_c('p',{staticClass:"word-type",staticStyle:{"color":"#f56742"}},[_vm._v(" ("+_vm._s(_vm.currentCard.wordType)+") ")]):_vm._e()]),_c('div',{staticClass:"text-center",class:{
          'mobile mt-3': _vm.$vuetify.breakpoint.smAndDown,
          'mt-4': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('p',{staticClass:"example",domProps:{"innerHTML":_vm._s(_vm.currentCard.exampleFormatted)}})])]:(
        _vm.currentTypeCard == 4 || (_vm.currentTypeCard == 1 && _vm.currentStepNo == 4)
      )?[_c('div',{staticClass:"mt-1 text-center"},[_c('p',{staticClass:"word word28 d-flex-center correct",class:_vm.$vuetify.breakpoint.smAndDown ? 'mobile' : '',staticStyle:{"gap":"5px","font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.currentCard.word)+" "),_c('v-icon',{staticClass:"correct"},[_vm._v("mdi-arrow-right")]),_vm._v(" "+_vm._s(_vm.currentCard.word)+" ")],1)]),_c('div',{staticClass:"audio d-flex-center",class:{
          mobile: _vm.$vuetify.breakpoint.smAndDown,
          'mt-2': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown && 'img-wrapper'},[(_vm.currentCard.audioLink !== null)?_c('img',{staticClass:"cursor-pointer",style:(_vm.$vuetify.breakpoint.smAndDown && {
                width: '16px',
                height: '16px',
              }),attrs:{"src":require("@/assets/images/volumn.svg")},on:{"click":_vm.handlePlayAudio}}):_vm._e()]),(_vm.currentCard.audioLink !== null)?_c('audio',{ref:"audioPlayer",attrs:{"src":_vm.currentCard.audioLink,"preload":"auto","autoplay":"","muted":""}}):_vm._e(),(_vm.currentCard.spelling !== 'null')?_c('p',{staticClass:"spelling"},[_vm._v(" "+_vm._s(_vm.currentCard.spelling)+" ")]):_vm._e(),(_vm.currentCard.wordType !== 'null')?_c('p',{staticClass:"word-type",staticStyle:{"color":"#f56742"}},[_vm._v(" ("+_vm._s(_vm.currentCard.wordType)+") ")]):_vm._e()]),_c('p',{staticClass:"mean mean22 mt-3",class:{
          'mobile mb-3': _vm.$vuetify.breakpoint.smAndDown,
        },domProps:{"innerHTML":_vm._s(_vm.currentCard.mean)}}),_c('div',{staticClass:"text-center",class:{ 'mt-3': _vm.$vuetify.breakpoint.smAndUp }},[_c('p',{staticClass:"example",domProps:{"innerHTML":_vm._s(_vm.currentCard.exampleFormatted)}})])]:(_vm.currentTypeCard == 5)?[_c('p',{staticClass:"mean ft400",class:{ mobile: _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"color":"#1bb763"},domProps:{"innerHTML":_vm._s(_vm.currentCard.meanFormatted)}}),_c('div',{staticClass:"text-center",class:_vm.$vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-1'},[_c('p',{staticClass:"word ft400 word28",class:{ mobile: _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"color":"#3887fe"},domProps:{"innerHTML":_vm._s(_vm.currentCard.wordFormatted)}})]),_c('div',{staticClass:"text-center mt-3"},[_c('p',{staticClass:"example",domProps:{"innerHTML":_vm._s(_vm.currentCard.exampleFormatted)}})])]:[_c('div',{staticClass:"mt-1 text-center"},[_c('p',{staticClass:"word ft400 word28",class:{ 'mobile-type-23': _vm.$vuetify.breakpoint.smAndDown },staticStyle:{"color":"#1bb763"},domProps:{"innerHTML":_vm._s(_vm.currentCard.wordFormatted)}})]),_c('p',{staticClass:"mean ft400",class:{
          'mobile-type-23 mt-3 mb-3': _vm.$vuetify.breakpoint.smAndDown,
          'mt-1': _vm.$vuetify.breakpoint.smAndUp,
        },staticStyle:{"color":"#3887fe"},domProps:{"innerHTML":_vm._s(_vm.currentCard.meanFormatted)}}),_c('div',{staticClass:"text-center",class:{ 'mt-3': _vm.$vuetify.breakpoint.smAndUp }},[_c('p',{staticClass:"example",domProps:{"innerHTML":_vm._s(_vm.currentCard.exampleFormatted)}})])]],_c('ButtonComponent',{staticClass:"btn-switch",class:{ 'fs-16': _vm.$vuetify.breakpoint.smAndDown },attrs:{"title":_vm.PREVIEW_CARD_STEP_LIST[_vm.currentStepNo - 1].btnLabel},on:{"click":_vm.changeStep}})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2"},[_c('label',{attrs:{"for":"answer"}},[_c('h6',[_vm._v("Câu trả lời:")])])])
}]

export { render, staticRenderFns }