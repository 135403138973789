<template>
  <v-container fluid class="mt-5">
    <v-row :class="{ 'mt-1': $vuetify.breakpoint.xsOnly }" class="px-4">
      <v-col cols="12" md="3" sm="12" class="pa-1">
        <FolderManagement @chooseFolder="handleChooseFolder" />
        <CardSetManagement
          v-if="!!selectedFolderId"
          :folderId="selectedFolderId"
          @chooseCardSet="handleChooseCardSet"
        />
      </v-col>
      <v-col
        cols="12"
        md="5"
        sm="12"
        :class="{ 'mt-5': $vuetify.breakpoint.smAndDown }"
      >
        <CardManagement
          @chooseCard="handleChooseCard"
          :selectedCardSetId="currentGroupCardId"
        ></CardManagement>
      </v-col>
      <!-- ------------------Add new card----------------- -->
      <v-col
        cols="12"
        md="4"
        sm="6"
        class="pa-1 pr-0 col-create-card"
        :style="'margin-top: 8px'"
      >
        <v-card class="browse-card-detail" elevation="0">
          <v-card-actions class="pa-0 ml-3 mb-3 d-flex">
            <ButtonComponent
              title="Tạo thẻ mới"
              @click="handleAddCardForm"
              appendIcon="white-plus"
              :showBadge="hasUnsavedNewCard"
            />
          </v-card-actions>
          <!-- ------------------Add new card--------------------- -->
          <transition-group name="fade">
            <template v-if="activeAddForm">
              <FormAddNewCard
                v-show="showAddForm"
                key="add"
                :folderId="selectedFolderId"
                :currentGroupCardId="currentGroupCardId"
                @minimize="showAddForm = false"
                @close="handleShowDialogCancelAddCard"
                @change="(hasChange) => (hasUnsavedNewCard = hasChange)"
              />
            </template>
            <FormViewAndEditCard
              v-if="showCardDetail"
              key="edit"
              :folderId="selectedFolderId"
              :currentCardSetId="currentGroupCardId"
              :selectedCardId="selectedCardId"
              @close="handleShowDialogCancelEditCard"
            />
          </transition-group>
        </v-card>
      </v-col>
    </v-row>
    <DialogComponent
      typeDialog="confirm"
      description="Loại bỏ thay đổi và không tạo thẻ nữa?"
      :showDialogValue="showDialogCancelAddCard"
      @closeDialog="handleCloseDialog"
      @confirmRequest="handleCancelAddCard"
    />
    <DialogComponent
      typeDialog="confirm"
      description="Chắc chắn hủy bỏ sửa thẻ?"
      :showDialogValue="showDialogCancelEditCard"
      @closeDialog="handleCloseDialog"
      @confirmRequest="handleCancelAddCard"
    />
  </v-container>
</template>
<script>
import DialogComponent from "@/components/ui/DialogComponent.vue";
import { mapActions } from "vuex";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import FormAddNewCard from "@/views/admin/FormAddNewCard.vue";
import FolderManagement from "@/components/FolderManagement.vue";
import CardSetManagement from "@/components/CardSetManagement.vue";
import CardManagement from "@/components/CardManagement.vue";
import FormViewAndEditCard from "@/components/FormViewAndEditCard.vue";

export default {
  components: {
    FormAddNewCard,
    ButtonComponent,
    DialogComponent,
    FolderManagement,
    CardSetManagement,
    CardManagement,
    FormViewAndEditCard,
  },
  data() {
    return {
      selectedFolderId: "",
      currentGroupCardId: "",
      selectedCardId: "",
      showDialogCancelAddCard: false,
      showDialogCancelEditCard: false,
      activeAddForm: false,
      showAddForm: false,
      hasUnsavedNewCard: false,
      showCardDetail: false,
    };
  },
  methods: {
    ...mapActions("card", ["fetchCardDetail"]),
    async handleChooseCard(card) {
      await this.fetchCardDetail(card.id);
      this.showCardDetail = true;
      this.selectedCardId = card.id;
      this.showAddForm = false;
    },
    handleChooseFolder(folderId) {
      this.selectedFolderId = folderId;
      this.currentGroupCardId = "";
    },
    handleChooseCardSet(cardSetId) {
      this.currentGroupCardId = cardSetId;
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
    handleShowDialogCancelEditCard() {
      this.showDialogCancelEditCard = true;
    },
    handleShowDialogCancelAddCard() {
      this.showDialogCancelAddCard = true;
    },
    handleCloseDialog() {
      this.showDialogCancelAddCard = false;
      this.showDialogCancelEditCard = false;
    },
    handleAddCardForm() {
      if (!this.activeAddForm) {
        this.activeAddForm = true;
        this.showAddForm = true;
      } else {
        this.showAddForm = !this.showAddForm;
      }
      this.showCardDetail = false;
    },
    handleCancelAddCard() {
      this.showCardDetail = false;
      this.activeAddForm = false;
      this.showAddForm = false;
      this.hasUnsavedNewCard = false;
      this.handleCloseDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.col-create-card {
  max-height: calc(100vh - 112px);
}
</style>
<style lang="sass" scoped>
.browse-card-detail
  min-height: 70vh
  height: 100%

.browse-card-title
  color: #1BB763
.browse-card-title-des
  color: #F56742

li
  font-weight: bold
.cancel-position
  justify-content: end
</style>
<style lang="scss">
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
