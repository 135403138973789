<template>
  <v-container>
    <div class="input-container">
      <v-text-field
        v-model.trim="searchValues"
        label="Tìm kiếm thẻ"
        append-icon="mdi-chevron-down"
        :prepend-icon="isSearch ? 'mdi-close-circle' : ''"
        append-outer-icon="mdi-magnify"
        @click:append-outer="handleSearch"
        @click:append="showPopup = !showPopup"
        @click:prepend="clearInput"
        @keyup.enter="handleSearch"
        maxlength="50"
      ></v-text-field>
      <div
        class="popup"
        v-if="showPopup"
        v-click-outside="
          () => {
            showPopup = false;
          }
        "
      >
        <v-checkbox
          class="mt-1"
          v-model="isOnlySearchFromSelectedStudent"
          label="Chỉ thẻ của học sinh đang chọn"
        ></v-checkbox>
        <v-divider class="ma-2"></v-divider>
        <v-checkbox
          v-model="isSearchByWord"
          label="Từ"
          class="mt-0"
        ></v-checkbox>
        <v-checkbox
          v-model="isSearchByMeaning"
          label="Nghĩa"
          class="mt-0"
        ></v-checkbox>
        <v-checkbox
          v-model="isSearchByExample"
          label="Ví dụ"
          class="mt-0"
        ></v-checkbox>
      </div>
    </div>
    <TableComponent
      :items="isSearch ? this.filteredListCard : this.cardListFormatted"
      :headers="tableHeaderArr"
      :show-select="isSelecting"
      :options="this.tableActionArr"
      :isLoading="this.isLoading"
      @delete="handleShowDialogDelete"
      @select="updateSelectedList"
      @rowClick="(card) => $emit('chooseCard', card)"
    />
    <v-row class="d-flex justify-end mt-3">
      <v-col
        cols="12"
        md="9"
        sm="12"
        class="d-flex justify-end"
        style="padding-top: 0px; padding-bottom: 0px"
      >
        <div class="mr-2">
          <ButtonComponent
            v-if="isSelecting"
            title="Xóa thẻ"
            @click="showDialogDelete = true"
            smallText
          >
          </ButtonComponent>
        </div>
        <div class="mr-2">
          <ButtonComponent
            v-if="isSelecting"
            title="Convert"
            smallText
            @click="showDialogSetReminderTime = true"
          >
          </ButtonComponent>
        </div>
        <div>
          <ButtonComponent
            :title="!isSelecting ? 'Chọn thẻ' : 'Hủy chọn'"
            smallText
            @click="toggleSelect"
          >
          </ButtonComponent>
        </div>
      </v-col>
    </v-row>
    <DialogComponent
      typeDialog="delete"
      :showDialogValue="showDialogDelete"
      @closeDialog="showDialogDelete = false"
      @confirmRequest="handleDeleteCard"
    />
  </v-container>
</template>

<script>
import DialogComponent from "@/components/ui/DialogComponent.vue";
import TableComponent from "@/components/ui/TableComponent.vue";
import { mapState, mapActions } from "vuex";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import {
  TABLE_HEADER_CARD_MANAGEMENT,
  TABLE_LIST_ACTION_CARD_MANAGEMENT,
} from "@/constants";
export default {
  components: {
    ButtonComponent,
    TableComponent,
    DialogComponent,
  },
  data() {
    return {
      isSelecting: false,
      selectedList: [],
      isLoading: false,
      showPopup: false,
      isSearch: false,
      isSearchByWord: true,
      isSearchByMeaning: false,
      isSearchByExample: false,
      isOnlySearchFromSelectedStudent: false,
      showDialogDelete: false,
      filteredListCard: [],
      searchValues: "",
      //   ----------card details data table----------
      tableHeaderArr: TABLE_HEADER_CARD_MANAGEMENT,
      tableActionArr: TABLE_LIST_ACTION_CARD_MANAGEMENT,
      deletingCardId: "",
    };
  },
  props: {
    selectedStudentId: {
      type: [String, Number],
      default: () => "",
    },
  },
  computed: {
    ...mapState("card", [
      "cardList",
      "proCardList",
      "cardListFilter",
      "proCardListFilter",
    ]),
    ...mapState("admin", ["currentFolderId"]),
    currentCardList() {
      return this.isPro ? this.proCardList : this.cardList;
    },
    cardListFormatted() {
      let arr = this.currentCardList.map((card) => {
        let typeCard =
          card.typeCard == 4 ? 1 : card.typeCard == 5 ? 2 : card.typeCard;
        return {
          id: card.id,
          word: card.word,
          // TODO dueDate
          dueDate: "New",
          cardSetName: card.groupCardName,
          cardType: "Kiểu " + typeCard,
          studentName: card.studentName,
        };
      });
      return arr;
    },
    deletingGroupCardId() {
      return this.currentCardList[0].groupCardId;
    },
    isPro() {
      return this.$route.query.tab === "manage-pro-card-set";
    },
  },
  methods: {
    ...mapActions("card", [
      "fetchAllCards",
      "deleteCard",
      "filterCards",
      "filterProCards",
    ]),
    ...mapActions("admin", ["fetchCardSets"]),
    ...mapActions("student", ["deleteProCard"]),
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    toggleSelect() {
      if (this.isSelecting) {
        this.selectedList = [];
      }
      this.isSelecting = !this.isSelecting;
    },
    async updateSelectedList(list) {
      this.selectedList = list;
    },
    async handleSearch() {
      this.isLoading = true;
      if (this.searchValues !== "") {
        this.isSearch = true;
        let arr = [];
        if (this.isPro) {
          await this.filterProCards({
            isSearchByWord: this.isSearchByWord,
            isSearchByMeaning: this.isSearchByMeaning,
            isSearchByExample: this.isSearchByExample,
            search: this.searchValues,
            searchFromstudentId: this.isOnlySearchFromSelectedStudent
              ? this.selectedStudentId ?? null
              : null,
          });
          arr = this.proCardListFilter;
        } else {
          await this.filterCards({
            isSearchByWord: this.isSearchByWord,
            isSearchByMeaning: this.isSearchByMeaning,
            isSearchByExample: this.isSearchByExample,
            search: this.searchValues,
          });
          arr = this.cardListFilter;
        }
        this.filteredListCard = arr.map((card) => {
          let typeCard =
            card.typeCard == 4 ? 1 : card.typeCard == 5 ? 2 : card.typeCard;
          return {
            id: card.id,
            word: card.word,
            // TODO dueDate
            dueDate: "New",
            cardSetName: card.groupCardName,
            cardType: "Kiểu " + typeCard,
            studentName: card.studentName,
          };
        });
      } else {
        this.isSearch = false;
        this.filteredListCard = this.currentCardList;
      }
      this.isLoading = false;
    },
    clearInput() {
      this.isSearch = false;
      this.searchValues = "";
      this.filteredListCard = this.currentCardList;
    },
    handleShowDialogDelete(item) {
      this.showDialogDelete = true;
      this.selectedList = [item];
    },
    async handleDeleteCard() {
      this.isLoading = true;
      this.isPro
        ? await this.deleteProCard(this.selectedList.map((item) => item.id))
        : await this.deleteCard(this.selectedList.map((item) => item.id));
      if (this.isSearch) {
        this.handleSearch();
      } else {
        await this.fetchAllCards({
          id: this.deletingGroupCardId,
          isPro: this.isPro,
        });
      }
      this.showDialogDelete = false;
      this.selectedList = [];
      if (this.isPro) await this.$emit("refresh");
      else await this.fetchCardSets(this.currentFolderId);
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.container {
  padding: 0 12px;
}

.input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.popup {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 200px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 20000;
  border-radius: 12px;
}
</style>

<style lang="scss" scoped>
.popup ::v-deep .v-input__slot {
  margin-bottom: 0px;
}
::v-deep .v-text-field {
  margin-top: 0px;
}

::v-deep .v-text-field__details {
  display: none;
}
</style>
