class TokenService {
  getLocalRefreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    return refreshToken;
  }

  getLocalAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    return accessToken;
  }

  updateLocalAccessToken(token) {
    localStorage.setItem("accessToken", JSON.stringify(token));
  }

  getAccount() {
    return JSON.parse(localStorage.getItem("account"));
  }

  setAccount(account) {
    localStorage.setItem("account", JSON.stringify(account));
  }

  setNewToken(accessToken, refreshToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  }

  removeAccount() {
    localStorage.removeItem("account");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
}

export default new TokenService();
