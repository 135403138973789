import actions from "@/store/modules/card/actions";
import mutations from "@/store/modules/card/mutations";
import getters from "@/store/modules/card/getters";

export default {
  namespaced: true,
  state() {
    return {
      cardList: [],
      cardDetail: {},
      previousElementId: "",
      nextElementId: "",
      currentGroupCardId: "",
      currentFolderId: "",
      listCards: [],
      cardListFilter: [],
      proCardListFilter: [],
      learningResult: {},
      currentCard: { id: 0 },
      timeStartLearn: 0,
      answer: "",
      previousCardId: null,
      doneCurrentGroupCard: false,
      showSuccessDiaLog: false,
      typeLearn: -1,
      nextCard: { id: 0 },
      previousCard: {},
      previousCards: [],
      previousNumberOfCards: [],
      isBackLearnCard: false,
      disableSaveBtn: false,
      disableUnSaveBtn: false,
      numOfNewCardsLearning: 0,
      numOfNewCardsWhenStartingLearn: 0,
      isGetLearnCard: false,
      isLoadingNextCard: false,
      showCompleteDiaLog: false,
      doneHomeWork: false,
      numOfRVSCardsLearning: 0,
      numOfRVSCardsWhenStartingLearn: 0,
      isOpenPopUpHomeWork: false,
      proCardList: [],
      cardHistory: [],
    };
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
};
