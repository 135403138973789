<template>
  <div>
    <v-data-table
      :height="
        $vuetify.breakpoint.smAndUp
          ? '66vh'
          : type === 'browse-table'
          ? 'calc(66vh - 60px)'
          : 'calc(66vh - 20px)'
      "
      :headers="headers"
      :items="type === 'browse-table' ? itemsFormatted : items"
      :hide-default-footer="true"
      disable-pagination
      mobile-breakpoint="0"
      :loading="isLoading"
      :show-select="showSelect"
      return-object
      v-model="selectedList"
      :item-value="keyValue"
      @click:row="(item) => $emit('rowClick', item)"
    >
      <template
        v-if="type === 'group-card-table'"
        v-slot:[`item.customGroupName`]="{ item, index }"
      >
        <div class="d-flex align-center justify-space-between">
          <span :style="{ color: item.isFolder && 'blue' }">{{
            item.customGroupName
          }}</span>
          <v-icon
            v-if="item.isFolder"
            @click.stop="$emit('showProCardGroups', index)"
            size="large"
            color="green-darken-2"
            class="show-menu-toggler"
            >{{
              item.showCardGroupList
                ? "mdi-arrow-up-left-bold"
                : "mdi-arrow-down-right-bold"
            }}</v-icon
          >
          <v-menu
            v-if="item.isProGroupCard"
            v-model="item.showMenu"
            offset-y
            transition="slide-y-transition"
            location="end"
          >
            <!-- -------------------Menu list-------------------- -->
            <template v-slot:activator="{ attrs, on }">
              <div v-bind="attrs" v-on="on">
                <img
                  src="@/assets/icons/menu.svg"
                  class="hover-scale"
                  style="cursor: pointer; padding: 5px 2px"
                />
              </div>
            </template>
            <template>
              <v-list
                class="pa-0 layout-list"
                style="min-width: 180px"
                v-if="item?.actions?.length > 0"
              >
                <v-list-item
                  :class="item.groupCardId && 'new-item'"
                  class="px-5 py-1"
                  v-for="(option, index) in item?.actions"
                  :key="index"
                  link
                  @click="$emit(option.emitFunction, item)"
                >
                  <v-img :src="option.icon && getIcon(option.icon)" />
                  <v-list-item-title class="ml-4 d-flex">
                    {{ option.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </template>
          </v-menu>
        </div>
      </template>
      <template v-if="type === 'browse-table'" v-slot:[`item.word`]="{ item }">
        <div class="d-flex justify-between">
          <span>{{ item.word }}</span>
          <span v-if="item.typeCardText" class="textType"
            >({{ item.typeCardText }})</span
          >
          <v-tooltip
            v-if="item.isCreatedByAdmin"
            v-model="item.showTooltip"
            bottom
            close-delay="1000"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click.stop="item.showTooltip = true"
              >
                <v-icon color="#ffeb3b"> mdi-shield-alert-outline </v-icon>
              </v-btn>
            </template>
            <span>Thẻ này được tạo bởi admin, lưu ý trước khi xoá thẻ.</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          v-if="$route.name !== 'manageStudentGroupCard'"
          offset-y
          transition="slide-y-transition"
          location="end"
        >
          <!-- -------------------Menu list-------------------- -->
          <template v-slot:activator="{ attrs, on }">
            <img
              src="@/assets/icons/menu.svg"
              class="hover-scale"
              style="cursor: pointer; padding: 15px 2px"
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-list class="pa-0 layout-list" style="min-width: 180px">
            <v-list-item
              class="px-5 py-1"
              v-for="(option, index) in options"
              :key="index"
              link
              @click="handleEmit(option.emitFunction, item)"
            >
              <v-img :src="option.icon && getIcon(option.icon)" />
              <v-list-item-title class="ml-4 d-flex">
                {{ option.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <img
          v-else
          src="@/assets/icons/trash.svg"
          class="hover-scale"
          style="cursor: pointer; padding: 15px 2px"
          @click.stop="deleteItem(item)"
        />
      </template>

      <template v-slot:loading>
        <v-skeleton-loader
          class="w-loading"
          type="table-row@10"
        ></v-skeleton-loader>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedList: [],
      itemsFormatted: [],
    };
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Number,
      default: null,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    keyValue: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedList: {
      handler() {
        this.handleSelect();
      },
      deep: true,
    },
    items: {
      handler() {
        this.itemsFormatted =
          this.type === "browse-table"
            ? this.items.map((item) => {
                return {
                  ...item,
                  showTooltip: false,
                };
              })
            : this.items;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getIcon(name) {
      return require("@/assets/icons/" + name + ".svg");
    },
    handleEmit(action, item) {
      return this.$emit(action, item);
    },
    handleSelect() {
      this.$emit("select", this.selectedList);
    },
    deleteItem(item) {
      this.$emit("delete", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.textType {
  margin-left: 2px;
  color: #adabab;
  font-size: 8px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
}
.show-menu-toggler {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
<style lang="scss">
.w-loading {
  max-width: 418px;
  width: calc(100vw - 100px);
}
.text-start {
  position: relative;
}
.hover-scale {
  width: 20px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.2);
  }
}
.v-data-table__wrapper {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #bdb8b8;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #726f6f;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
<style lang="sass" scoped>
.v-data-table
  border: 1px solid rgba(0, 0, 0, 0.12)
  border-top-left-radius: 12px
  border-top-right-radius: 12px
::v-deep .v-data-table__wrapper
  .v-data-table-header
    background-color: #453FE3 !important
    th
      border-right: 1px solid
      color: #FFFFFF !important
      padding: 0 8px
      &:first-child
        border-top-left-radius: 12px
      &:last-child
        border-top-right-radius: 12px
        border-right: none
  tbody
    tr
      cursor: pointer
      td
        font-weight: 600
        padding: 0 8px !important
        &:nth-child(1)
          padding-top: 6px !important
          padding-bottom: 6px !important
        &:nth-child(2)
          color: #3887FE
        &:nth-child(3)
          color: #1BB763
</style>
