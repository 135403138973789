<template>
  <div class="container d-flex flex-column justify-end">
    <div
      v-for="(card, index) in cardHistory"
      :key="index"
      class="card-table-row"
      :class="card.isDelete ? 'cursor-none' : 'cursor-pointer'"
      @click="redirectToEditCard(card)"
    >
      <p class="card-name text-overflow">
        {{ card.isDelete ? "Đã bị xóa" : card.word }}
      </p>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "CardCreatingHistory",
  components: {},
  props: {},
  data: () => ({}),
  computed: {
    ...mapState("card", ["cardHistory"]),
  },
  methods: {
    redirectToEditCard(card) {
      if (card.isDelete) return;
      this.$router.push(
        `/browse/${this.$route.params.studentId}?detailCardId=${card.detailCardId}&word=${card.word}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px 7px;
  border-radius: 10px;
  border: 2px solid black;
  background-color: white;
  min-height: 400px;
  min-width: 350px;
}
.card-table-row {
  border-radius: 5px;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  &:last-of-type {
    border-bottom: none;
  }
  &.cursor-pointer:hover {
    background-color: #ccc;
  }
  .card-name {
    margin: 0;
    padding: 0 10px;
    z-index: 2;
    max-width: 322px;
  }
}
</style>
<style lang="scss">
.cursor-none {
  cursor: no-drop;
}
</style>
