import moment from "moment";
const isEmpty = (value) => {
  return (
    typeof value == "undefined" ||
    (typeof value == "string" && value.length == 0) ||
    (typeof value == "number" && value == 0) ||
    (typeof value == "boolean" && value == false) ||
    value === null
  );
};
const formateTime = (seconds) => {
  if (seconds < 60) {
    return seconds + " giây";
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return minutes + " phút";
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return hours + " giờ";
  } else {
    const days = Math.floor(seconds / 86400);
    return days + " ngày";
  }
};
const convertSecondsToAcronymTime = (seconds) => {
  if (seconds < 60) {
    return seconds + "s";
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return minutes + "p";
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return hours + "h";
  } else {
    const days = Math.floor(seconds / 86400);
    return days + "d";
  }
};
const formatBreakLineInEditor = (txt) => {
  if (!txt) return "";
  const newStr = txt
    .replace(/\n{2,}\s*/g, "\n")
    .replace(/\r\n{2,}\s*/g, "\r\n")
    .replace(/<p><br data-cke-filler="true"><p>/g, "")
    .replace(/<br data-cke-filler="true">/g, "")
    .replaceAll("<p><br>&nbsp;</p>", "")
    .replaceAll("<p>&nbsp;</p>", "")
    .replaceAll("<br>&nbsp;</p>", "</p>")
    .replaceAll("<p></p>", "")
    .replace(`style="color:rgb(0,0,0);"`, "");
  return newStr;
};
const formatSubday = (subday, formatDate) => {
  return moment().subtract(subday, "d").format(formatDate);
};

const convertSecondsToTime = (seconds) => {
  if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.ceil((seconds % 3600) / 60);
    return `${hours} giờ ${minutes} phút`;
  } else {
    const minutes = Math.ceil(seconds / 60);
    return `${minutes} phút`;
  }
};
const checkTimeMoreThanToday = (btnValue, card) => {
  const fromCurr = new Date();
  let endOfToday = moment().toDate().setHours(4, 0, 0);
  if (fromCurr > endOfToday) {
    endOfToday = moment().add(1, "days").toDate().setHours(4, 0, 0);
  }
  const btnObj = {
    0: "QnRelearn",
    1: "QnEasy",
    2: "QnGood",
    3: "QnHard",
  };
  const qn = card[btnObj[btnValue]] ?? 600;
  return moment(fromCurr).add(qn, "seconds") >= endOfToday;
};
// Custom function to format the date
function formatDateyyyyMMddHHmmss(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
}

const checkDoublePress = (callback) => {
  // Kiểm tra nếu đã có lần nhấn phím trước đó
  let keyPressTime = null;
  const doubleKeyPressInterval = 500;
  if (keyPressTime) {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - keyPressTime;
    // Kiểm tra thời gian giữa hai lần nhấn phím
    if (elapsedTime <= doubleKeyPressInterval) {
      // Thực hiện các hành động khi double keydown (X) xảy ra
      callback();
    }
    // Reset lại biến keyPressTime
    keyPressTime = null;
  } else {
    // Gán thời gian cho lần nhấn phím đầu tiên
    keyPressTime = new Date().getTime();
  }
};

function searchWithoutAccentAndCase(text, query) {
  // Loại bỏ dấu tiếng Việt và chuyển về chữ thường
  const normalize = (str) =>
    str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

  const normalizedText = normalize(text);
  const normalizedQuery = normalize(query);

  // Tìm kiếm
  return normalizedText.includes(normalizedQuery);
}

export {
  isEmpty,
  formateTime,
  formatBreakLineInEditor,
  formatSubday,
  convertSecondsToTime,
  checkTimeMoreThanToday,
  convertSecondsToAcronymTime,
  formatDateyyyyMMddHHmmss,
  checkDoublePress,
  searchWithoutAccentAndCase,
};
