<template>
  <v-container fluid class="container-navbar bg-blue nav-login" pa-0 ma-0>
    <v-row class="d-flex justify-center" style="height: 60px; margin: 0">
      <v-col
        cols="12"
        md="8"
        pa-0
        ma-0
        class="d-flex align-center"
        style="padding: 0 12px !important"
      >
        <v-flex class="nav-login" @click="handleBackList">
          <img src="@/assets/images/miniLogo.png" class="center" />
        </v-flex>
        <!--        User info and logout for SM and up-->
        <v-flex
          class="d-flex"
          style="max-width: 300px"
          v-if="isLoggedIn && $vuetify.breakpoint.smAndUp"
        >
          <v-flex class="d-flex justify-start align-center">
            <img
              src="@/assets/icons/account.svg"
              alt=""
              style="width: 32px; height: 32px"
            />
            <p
              class="ml-3"
              style="color: #ffffff; font-weight: 600; font-size: 18px"
            >
              {{ account.fullName }}
            </p>
          </v-flex>
          <v-flex
            class="d-flex justify-end align-center"
            @click="handleShowDialog"
          >
            <p class="captionTwo" style="color: #ffffff; margin-bottom: 0px">
              Đăng xuất
            </p>
            <img
              src="@/assets/icons/logout.svg"
              alt=""
              class="ml-3"
              style="width: 13px; height: 16px; cursor: pointer"
            />
          </v-flex>
        </v-flex>
        <!--        User info and logout for XS only-->
        <v-flex v-if="isLoggedIn && $vuetify.breakpoint.xsOnly">
          <v-flex class="d-flex justify-end align-center">
            <v-menu
              offset-y
              transition="slide-y-transition"
              location="end"
              min-width="200"
            >
              <!-- -------------------Menu list-------------------- -->
              <template v-slot:activator="{ attrs, on }">
                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                  <img
                    src="@/assets/icons/account.svg"
                    style="width: 32px; height: 32px"
                  />
                  <img
                    src="@/assets/icons/arrow-down.svg"
                    style="width: 16px; height: 16px"
                  />
                </div>
              </template>
              <v-list class="pa-0 layout-list">
                <v-list-item
                  class="d-flex justify-end align-center"
                  style="border-bottom: 1px solid #0000001a"
                >
                  <p>{{ account.fullName }}</p>
                </v-list-item>
                <v-list-item>
                  <v-flex
                    class="d-flex justify-end align-center mb-1 logout-mobile"
                    @click="handleShowDialog"
                  >
                    <p
                      class="captionTwo"
                      style="color: #453fe3; font-size: 14px; font-weight: 600"
                    >
                      Đăng xuất
                    </p>
                    <img
                      src="@/assets/icons/logout-mobile.svg"
                      alt=""
                      class="ml-3"
                      style="width: 13px; height: 16px"
                    />
                  </v-flex>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-flex>
      </v-col>
    </v-row>
    <DialogComponent
      style="margin: 12px 12px 0px"
      type-dialog="confirm"
      description="Đăng xuất"
      sub-description="Bạn sẽ đăng xuất khỏi tài khoản hiện tại"
      confirm-btn-label="Đăng xuất"
      :showDialogValue="showDialog"
      @closeDialog="handleCloseDialog"
      @confirmRequest="logOut"
    />
  </v-container>
</template>

<script>
import DialogComponent from "@/components/ui/DialogComponent.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import TokenService from "@/services/token.service";

export default {
  name: "NavBarComponent",
  components: { DialogComponent },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    ...mapActions("auth", ["handleLogout"]),
    ...mapMutations("card", [
      "SET_IS_ƠPEN_POP_UP_HOME_WORK",
      "SET_DONE_HOME_WORK",
    ]),
    handleShowDialog() {
      this.showDialog = true;
    },
    handleCloseDialog() {
      this.showDialog = false;
    },
    async logOut() {
      this.handleCloseDialog();
      await this.handleLogout();
      this.SET_IS_ƠPEN_POP_UP_HOME_WORK(false);
      this.SET_DONE_HOME_WORK(false);
      this.$router.replace(this.isAdmin ? "/admin" : "/login");
    },
    handleBackList() {
      if (this.isAdmin) {
        if (this.$route.path !== "/manage") this.$router.push("/manage");
      } else {
        if (this.$route.name !== "list")
          this.$router.push(`/group-card/${TokenService.getAccount().id}`);
      }
    },
  },
  computed: {
    ...mapState("auth", ["isLoggedIn", "account"]),
    isAdmin() {
      return this.$route.meta.role;
    },
  },
};
</script>

<style lang="sass" scoped>
p
  margin-bottom: 0px
.bg-blue
  background: #453FE3
  img
    height: 60px
    width: 103px
.nav-login
  cursor: pointer
  height: 60px
.logout-mobile
  cursor: pointer
</style>
