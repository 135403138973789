import axios from "@/services/axios";
import Vue from "vue";

const BASE_ADMIN_URL = "admin/";
const BASE_STUDENT_URL = "user/";

export default {
  async fetchStudentCardGroupList(context, payload) {
    await axios
      .get(
        `${BASE_ADMIN_URL}student/${payload.studentId}${
          payload.userGroupCardId
            ? "?userGroupCardId=" + payload.userGroupCardId
            : ""
        }`
      )
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_CARD_GROUP_LIST", res.data.data);
          Vue.prototype.$isProStudent = res.data.data.isPro;
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async fetchTimeStudy(context, payload) {
    await axios
      .post(`${BASE_STUDENT_URL}timeStudyAvg`, {
        userId: payload.studentId,
      })
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_TIME_STUDY_AVG", res.data.data);
        } else {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async fetchStudentCardDetailInAGroupCard(context, groupCardId) {
    await axios
      .get(`${BASE_ADMIN_URL}student/groupCard/${groupCardId}`)
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_CARD_LIST", res.data.data);
          return "";
        } else {
          const error = res.data.message || "Something is wrong!";
          return error;
        }
      })
      .catch((error) => {
        console.log(error);
        return "Something is wrong!";
      });
  },
  async fetchCardListInAFolder(context, { folderId, studentId }) {
    await axios
      .get(
        `${BASE_ADMIN_URL}folders/learnCards?folderId=${folderId}&studentId=${studentId}`
      )
      .then((res) => {
        if (res.data.status_code == "200") {
          context.commit("SET_CARD_LIST", res.data.data);
          return "";
        } else {
          const error = res.data.message || "Something is wrong!";
          return error;
        }
      })
      .catch((error) => {
        console.log(error);
        return "Something is wrong!";
      });
  },
  async unassignGroupCard(context, groupCardId) {
    await axios
      .put(`${BASE_ADMIN_URL}student/groupCard/${groupCardId}`)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async handleRemoveLearnCard(context, id) {
    await axios
      .delete(`${BASE_STUDENT_URL}cards/${id}`)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async createRandomStudy(context, payload) {
    await axios
      .post("user/cards/save-card/copy", payload)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          return error;
        }
      })
      .catch((error) => {
        console.log(error);
        return "Something is wrong!";
      });
    return "";
  },
  async deleteRandomStudy(context, payload) {
    await axios
      .post("user/cards/save-card/delete-copy", payload)
      .then((res) => {
        if (res.data.status_code !== "200") {
          const error = res.data.message || "Something is wrong!";
          return error;
        }
      })
      .catch((error) => {
        console.log(error);
        return "Something is wrong!";
      });
    return "";
  },
  async reviewAhead(context, payload) {
    context.commit("SET_LOADING", true);
    let mess = "";
    try {
      const res = await axios.post("user/cards-review-ahead", payload);
      if (res.data.status_code != "200") {
        mess = "Đã có lỗi xảy ra, vui lòng thử lại";
      }
    } catch (error) {
      console.log(error);
      mess = "Đã có lỗi xảy ra, vui lòng thử lại";
    }
    context.commit("SET_LOADING", false);
    return mess;
  },
  async reviewAheadFolder(context, payload) {
    context.commit("SET_LOADING", true);
    let mess = "";
    try {
      const res = await axios.post("user/folders-review-ahead", payload);
      if (res.data.status_code != "200") {
        mess = "Đã có lỗi xảy ra, vui lòng thử lại";
      }
    } catch (error) {
      console.log(error);
      mess = "Đã có lỗi xảy ra, vui lòng thử lại";
    }
    context.commit("SET_LOADING", false);
    return mess;
  },
};
