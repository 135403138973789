export default {
  SET_DIALOG(
    state,
    payload = {
      typeDialog: "warning",
      description: "",
      autoClose: false,
    }
  ) {
    if (payload) {
      state.isOpenDialog = true;
      state.configDialog = {
        typeDialog: payload.typeDialog ?? "warning",
        description: payload.description,
        autoClose: payload.autoClose,
      };
    } else state.isOpenDialog = false;
  },
};
