import axiosInstance from "./axios";
import TokenService from "./token.service";
import router from "@/router";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.url !== "login") {
        const token = TokenService.getLocalAccessToken();
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "login" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          if (originalConfig.url !== "refresh-token") {
            originalConfig._retry = true;
            try {
              await store.dispatch("auth/handleRefreshToken");
              return axiosInstance(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          } else {
            const role = TokenService.getAccount().role;
            TokenService.removeAccount();
            router.push(role == "admin" ? "/admin" : "/login");
            return Promise.reject(err);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
