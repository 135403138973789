<template>
  <v-container fluid class="mt-5">
    <v-row :class="{ 'mt-1': $vuetify.breakpoint.xsOnly }" class="px-4">
      <v-col cols="12" md="3" sm="12" class="pa-1">
        <ProClassManagement
          @chooseClass="handleChooseClass"
          :refreshCount="refreshCount"
          :classId="selectedClassId"
          :currentClassName="selectedClassName"
          :students="filteredStudentList"
        />
        <ProStudentManagement
          v-if="!!selectedClassId"
          :classId="selectedClassId"
          :students="filteredStudentList"
          @chooseStudent="handleChooseStudent"
          @searchStudent="searchStudent"
        />
        <ProCardSetManagement
          v-if="!!selectedStudentId"
          :studentId="selectedStudentId"
          :folders="selectedStudentFolders"
          @chooseCardSet="handleChooseCardSet"
          @refresh="refresh"
        />
      </v-col>
      <v-col
        cols="12"
        md="5"
        sm="12"
        :class="{ 'mt-5': $vuetify.breakpoint.smAndDown }"
      >
        <CardManagement
          @chooseCard="handleChooseCard"
          :selectedCardSetId="currentGroupCardId"
          :selectedStudentId="selectedStudentId"
          @refresh="$emit('refresh')"
        ></CardManagement>
      </v-col>
      <!-- ------------------Add new card----------------- -->
      <v-col
        cols="12"
        md="4"
        sm="6"
        class="pa-1 pr-0 col-create-card"
        :style="'margin-top: 8px'"
      >
        <v-card class="browse-card-detail" elevation="0">
          <v-card-actions class="pa-0 ml-3 mb-3 d-flex">
            <ButtonComponent
              title="Tạo thẻ mới"
              @click="handleAddCardForm"
              appendIcon="white-plus"
              :showBadge="hasUnsavedNewCard"
            />
          </v-card-actions>
          <!-- ------------------Add new card--------------------- -->
          <transition-group name="fade">
            <template v-if="activeAddForm">
              <FormAddNewCard
                v-show="showAddForm"
                key="add"
                :folderId="currentFolderId"
                :currentGroupCardId="currentGroupCardId"
                :currentStudentId="selectedStudentId"
                @minimize="showAddForm = false"
                @close="handleShowDialogCancelAddCard"
                @change="(hasChange) => (hasUnsavedNewCard = hasChange)"
              />
            </template>
            <FormViewAndEditCard
              v-if="showCardDetail"
              key="edit"
              :folderId="currentFolderId"
              :currentCardSetId="currentGroupCardId"
              :selectedCardId="selectedCardId"
              :selectedStudentId="selectedStudentId"
              @close="handleShowDialogCancelEditCard"
            />
          </transition-group>
        </v-card>
      </v-col>
    </v-row>
    <DialogComponent
      typeDialog="confirm"
      description="Loại bỏ thay đổi và không tạo thẻ nữa?"
      :showDialogValue="showDialogCancelAddCard"
      @closeDialog="handleCloseDialog"
      @confirmRequest="handleCancelAddCard"
    />
    <DialogComponent
      typeDialog="confirm"
      description="Chắc chắn hủy bỏ sửa thẻ?"
      :showDialogValue="showDialogCancelEditCard"
      @closeDialog="handleCloseDialog"
      @confirmRequest="handleCancelAddCard"
    />
  </v-container>
</template>
<script>
import DialogComponent from "@/components/ui/DialogComponent.vue";
import { mapActions, mapGetters } from "vuex";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import FormAddNewCard from "@/views/admin/FormAddNewCard.vue";
import ProClassManagement from "@/components/ProClassManagement.vue";
import ProStudentManagement from "@/components/ProStudentManagement.vue";
import CardManagement from "@/components/CardManagement.vue";
import FormViewAndEditCard from "@/components/FormViewAndEditCard.vue";
import ProCardSetManagement from "@/components/ProCardSetManagement.vue";
import { searchWithoutAccentAndCase } from "@/uttil";

export default {
  components: {
    FormAddNewCard,
    ButtonComponent,
    DialogComponent,
    ProClassManagement,
    ProStudentManagement,
    CardManagement,
    FormViewAndEditCard,
    ProCardSetManagement,
  },
  data() {
    return {
      selectedClassId: "",
      selectedClassName: "",
      selectedClassStudentList: [],
      selectedStudentId: "",
      selectedStudentName: "",
      currentFolderId: "",
      selectedStudentFolders: [],
      currentGroupCardId: "",
      selectedCardId: "",
      showDialogCancelAddCard: false,
      showDialogCancelEditCard: false,
      activeAddForm: false,
      showAddForm: false,
      hasUnsavedNewCard: false,
      showCardDetail: false,
      searchText: "",
      filteredStudentList: [],
    };
  },
  props: {
    refreshCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("admin", ["getAllProClasses"]),
  },
  methods: {
    ...mapActions("card", ["fetchCardDetail"]),
    searchStudent(searchText) {
      this.searchText = searchText;
      this.filteredStudentList = this.selectedClassStudentList.filter(
        (student) => searchWithoutAccentAndCase(student.fullName, searchText)
      );
    },
    refresh() {
      this.selectedClassStudentList = JSON.parse(
        JSON.stringify(this.getAllProClasses)
      ).find(
        (classroom) => classroom.classId === this.selectedClassId
      ).students;
      this.selectedStudentFolders = this.selectedClassStudentList.find(
        (student) => student.id === this.selectedStudentId
      ).folders;
      this.searchStudent(this.searchText);
    },
    handleChooseClass({ classId, className, studentList, isRefresh = false }) {
      this.selectedClassId = classId;
      this.selectedClassName = className;
      this.selectedClassStudentList = studentList;
      this.searchStudent(this.searchText);
      this.currentGroupCardId = "";
      if (isRefresh) {
        console.log(
          studentList.find((student) => student.id === this.selectedStudentId)
            .folders
        );
        this.handleChooseStudent({
          id: this.selectedStudentId,
          name: this.selectedStudentName,
          folders: studentList.find(
            (student) => student.id === this.selectedStudentId
          ).folders,
        });
      }
    },
    handleChooseStudent({
      id: studentId,
      name: studentName,
      folders: studentFolders,
    }) {
      this.selectedStudentId = studentId;
      this.selectedStudentName = studentName;
      this.selectedStudentFolders = studentFolders;
    },
    handleChooseCardSet(cardSetId, folderId) {
      this.currentGroupCardId = cardSetId;
      this.currentFolderId = folderId;
    },

    // Create, Edit Card
    async handleChooseCard(card) {
      await this.fetchCardDetail(card.id);
      this.showCardDetail = true;
      this.selectedCardId = card.id;
      this.showAddForm = false;
    },
    getImgUrl(url) {
      return require("@/assets/" + url);
    },
    handleShowDialogCancelEditCard() {
      this.showDialogCancelEditCard = true;
    },
    handleShowDialogCancelAddCard() {
      this.showDialogCancelAddCard = true;
    },
    handleCloseDialog() {
      this.showDialogCancelAddCard = false;
      this.showDialogCancelEditCard = false;
    },
    handleAddCardForm() {
      if (!this.activeAddForm) {
        this.activeAddForm = true;
        this.showAddForm = true;
      } else {
        this.showAddForm = !this.showAddForm;
      }
      this.showCardDetail = false;
    },
    handleCancelAddCard() {
      this.showCardDetail = false;
      this.activeAddForm = false;
      this.showAddForm = false;
      this.hasUnsavedNewCard = false;
      this.handleCloseDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.col-create-card {
  max-height: calc(100vh - 112px);
}
</style>

<style lang="sass" scoped>
.browse-card-detail
  min-height: 70vh
  height: 100%

.browse-card-title
  color: #1BB763
.browse-card-title-des
  color: #F56742

li
  font-weight: bold
.cancel-position
  justify-content: end
</style>

<style lang="scss">
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
