export default {
  handleRemoveProduct(state, payload) {
    state.listCards = state.listCards.filter((card) => {
      return card.id !== payload;
    });
  },
  SET_CARD_GROUP_LIST(state, payload) {
    state.cardGroupList = payload;
  },
  SET_LEARNING_RESULT(state, payload) {
    state.learningResult = payload;
  },
  SET_CARD_LIST(state, payload) {
    state.cardList = payload;
  },
  SET_TIME_STUDY_AVG(state, payload) {
    state.timeStudyAvg = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  setSlotParentEventController(state, payload) {
    state.slotParentEventController++;
    state.newProCardGroupPayload = payload;
  },
};
