var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{class:[
      _vm.isGrayBtn ? 'gray-btn' : 'blue-btn',
      _vm.disable && 'disable',
      // $route.name == 'cardDetailsAnswer' && 'relearn-btn',
      _vm.isBrowseButton && 'mobile',
      _vm.classBtn,
    ],style:(_vm.showBadge && { padding: '0 10px !important;' }),attrs:{"elevation":"0","type":_vm.type},on:{"click":_vm.handleClickBtn}},[(_vm.showBadge)?_c('v-badge',{staticStyle:{"display":"flex"},attrs:{"color":"success"}},[(!_vm.smallText)?_c('h6',{domProps:{"innerHTML":_vm._s(_vm.title)}}):_c('p',{domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.appendIcon)?_c('img',{staticClass:"ml-3",attrs:{"src":_vm.getIcon(_vm.appendIcon)}}):_vm._e()]):[(_vm.prependIcon)?_c('img',{staticClass:"mr-2",attrs:{"src":_vm.getIcon(_vm.prependIcon)}}):_vm._e(),(!_vm.smallText)?_c('h6',{domProps:{"innerHTML":_vm._s(_vm.title)}}):_c('p',{domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.appendIcon)?_c('img',{staticClass:"ml-2",attrs:{"src":_vm.getIcon(_vm.appendIcon)}}):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }