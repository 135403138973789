export default {
  getListCardGroup(state) {
    return state.cardGroupList;
  },
  getListCardGroupInFolder: (state) => (folderId) => {
    return state.cardGroupList.proFolders.find(
      (folder) => folder.folderId === folderId
    ).cardGroups;
  },
  studentGetAllProGroupCard(state) {
    let arr = [];
    state.cardGroupList.proFolders.forEach((folder) => {
      arr = [...arr, ...folder.cardGroups];
    });
    return arr;
  },
  getLearningResult(state) {
    return state.learningResult;
  },
  getListCard(state) {
    return state.cardList;
  },
};
