var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-overlay',{staticClass:"align-center justify-center",staticStyle:{"z-index":"10"},attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64"}})],1),(!_vm.loading)?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"md":_vm.showDialogCreateProCard ? 8 : 12,"sm":12}},[_c('v-card',{staticClass:"d-flex justify-space-between mb-3",attrs:{"elevation":"0"}},[(_vm.$route.name == 'manageStudentGroupCard')?_c('ButtonComponent',{attrs:{"title":"Back"},on:{"click":function($event){return _vm.$router.go(-1)}}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0",class:{
            'd-flex align-items-baseline': _vm.$vuetify.breakpoint.smOnly,
          }},[(_vm.$vuetify.breakpoint.smAndUp)?_c('h4',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('h5',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(!_vm.$route.meta.role)?_c('p',{staticClass:"remain",class:[
              _vm.$vuetify.breakpoint.smAndDown && 'mobile',
              _vm.$vuetify.breakpoint.xsOnly && 'fs13',
            ]},[_vm._v(" Còn "+_vm._s(this.learnCardLeft)+" câu hỏi trong "+_vm._s(this.estimateTime)+" ")]):_vm._e()]),_c('ButtonComponent',{attrs:{"title":"Browse","isGrayBtn":""},on:{"click":_vm.handleGoBrowse}})],1),_vm._t("default",null,{"openAddCardForm":() => (_vm.showDialogCreateProCard = true)}),_c('v-flex',{staticClass:"mt-9 d-flex",class:'justify-center'},[_c('p',{staticClass:"captionOne",staticStyle:{"margin-bottom":"0px"}},[_c('strong',[_vm._v("Đã trả lời "+_vm._s(_vm.cardCount)+" câu hỏi trong "+_vm._s(_vm.studyTime.toFixed(1))+" phút")]),_vm._v(" ("+_vm._s(_vm.studySpeed)+"s/câu) ")])])],2),(_vm.isListForProStudent && _vm.showDialogCreateProCard)?_c('v-col',{attrs:{"md":"4","sm":"7"}},[(_vm.isListForProStudent)?_c('transition-group',{attrs:{"name":"fade"}},[(_vm.showDialogCreateProCard)?[_c('FormAddNewCard',{key:"add",on:{"close":function($event){_vm.hasUnsavedNewCard
                ? (_vm.showDialogCancelAddCard = true)
                : (_vm.showDialogCreateProCard = false)},"onCardCreated":function($event){return _vm.$emit('onCardCreated')},"change":(hasChange) => (_vm.hasUnsavedNewCard = hasChange),"createNewCardGroup":_vm.setSlotParentEventController}})]:_vm._e()],2):_vm._e()],1):_vm._e()],1):_vm._e(),_c('DialogComponent',{attrs:{"typeDialog":"confirm","description":"Loại bỏ thay đổi và không tạo thẻ nữa?","showDialogValue":_vm.showDialogCancelAddCard},on:{"closeDialog":function($event){_vm.showDialogCancelAddCard = false},"confirmRequest":function($event){_vm.showDialogCancelAddCard = false;
      _vm.showDialogCreateProCard = false;}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }