<template>
  <v-data-table
    height="66vh"
    :headers="headers"
    :items="items"
    :hide-default-footer="true"
    disable-pagination
    mobile-breakpoint="0"
    :show-select="showSelect"
    return-object
    v-model="selectedList"
    :item-value="keyValue"
    @click:row="(item) => $emit('navigate', item)"
  >
    <template
      v-if="
        $vuetify.breakpoint.lgAndUp &&
        $route.name !== 'manageStudentGroupCard' &&
        $isProStudent
      "
      v-slot:[`header.actions`]="{}"
    >
      <v-menu
        :set="(openActions = false)"
        v-model="openActions"
        offset-y
        transition="slide-y-transition"
        location="end"
      >
        <template v-slot:activator="{ attrs, on }">
          <div v-bind="attrs" v-on="on">
            <v-icon color="white" class="cursor-pointer hover-scale"
              >mdi-plus-circle-outline</v-icon
            >
          </div>
        </template>
        <v-list class="pa-0 layout-list" style="min-width: 180px">
          <v-list-item
            class="px-5 py-1"
            link
            @click="$emit('createNewGroupCard')"
          >
            <v-list-item-title class="d-flex align-center">
              <v-icon color="#3887FE" class="mr-2"
                >mdi-book-plus-multiple-outline</v-icon
              >
              Tạo bộ thẻ mới
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="px-5 py-1" link @click="$emit('createNewCard')">
            <v-list-item-title class="d-flex align-center">
              <v-icon color="#1bb763" class="mr-2"
                >mdi-card-plus-outline</v-icon
              >
              Tạo thẻ mới
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:[`header.count`]="{}">
      <p>Thẻ mới</p>
      <p>Thẻ ôn tập</p>
    </template>
    <template v-slot:[`item.groupName`]="{ item, index }">
      <td style="padding: 0">
        <div class="d-flex align-center" style="height: 100%">
          <div style="min-width: 24px; height: 100%">
            <v-menu
              v-if="item.remainNewCardNeed || item.remainReviCardNeed"
              open-on-click
              offset-y
              offset-x
              transition="slide-y-transition"
              location="end"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-flex justify-center align-center"
                  v-bind="attrs"
                  v-on="on"
                  style="width: 100%; height: 100%"
                >
                  <img
                    src="@/assets/icons/exclamation.svg"
                    style="cursor: pointer"
                  />
                </div>
              </template>
              <v-list class="menu-home-work">
                <v-list-item>
                  <v-list-item-title> Cần phải học thêm: </v-list-item-title>
                </v-list-item>
                <v-list-item class="new-card">
                  <v-list-item-title>
                    Thẻ mới:
                    {{ item.remainNewCardNeed }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="revising-card">
                  <v-list-item-title>
                    Thẻ ôn tập:
                    {{ item.remainReviCardNeed }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <span :style="{ color: item.isFolder && 'blue' }">{{
            item.groupName || item.folderName
          }}</span>
          <div
            v-if="item.isFolder"
            class="d-flex align-center justify-center ml-2"
            style="height: 40px; width: 30px"
            @click.stop="$emit('showProCardGroups', index)"
          >
            <v-icon size="large" color="green-darken-2"
              >{{
                item.showMenu
                  ? "mdi-arrow-up-left-bold"
                  : "mdi-arrow-down-right-bold"
              }}
            </v-icon>
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.count`]="{ item }">
      <div>
        <p>{{ item.newCard }}</p>
        <p style="color: #1bb763">{{ item.revisingCard }}</p>
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-menu
        v-if="$route.name !== 'manageStudentGroupCard'"
        v-model="item.openMenu"
        offset-y
        transition="slide-y-transition"
        location="end"
      >
        <!-- -------------------Menu list-------------------- -->
        <template v-slot:activator="{ attrs, on }">
          <div v-bind="attrs" v-on="on">
            <img
              src="@/assets/icons/menu.svg"
              class="hover-scale"
              style="cursor: pointer; padding: 15px 2px"
            />
          </div>
        </template>
        <template>
          <v-list
            class="pa-0 layout-list"
            style="min-width: 180px"
            v-if="item?.actions?.length > 0"
          >
            <v-list-item
              :class="item.groupCardId && 'new-item'"
              class="px-5 py-1"
              v-for="(option, index) in item?.actions"
              :key="index"
              link
              @click="$emit(option.emitFunction, item)"
            >
              <v-img :src="option.icon && getIcon(option.icon)" />
              <v-list-item-title class="ml-4 d-flex">
                {{ option.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-menu>
      <div v-else-if="!item.isFolder" class="flex align-center justify-center">
        <img
          src="@/assets/icons/pencil.svg"
          class="hover-scale"
          style="cursor: pointer; padding: 15px 2px; margin-right: 0.5rem"
          @click.stop="$emit('reAssign', item)"
        />
        <img
          src="@/assets/icons/trash.svg"
          class="hover-scale"
          style="cursor: pointer; padding: 15px 2px"
          @click.stop="$emit('delete', item)"
        />
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      selectedList: [],
    };
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: Number,
      default: null,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    keyValue: {
      type: String,
      default: "",
    },
  },
  watch: {
    selectedList: {
      handler() {
        this.$emit("select", this.selectedList);
      },
      deep: true,
    },
  },
  methods: {
    getIcon(name) {
      return require("@/assets/icons/" + name + ".svg");
    },
  },
};
</script>
<style lang="scss">
.v-application p {
  margin-bottom: 0px;
}
.hover-scale {
  width: 20px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
}

.v-data-table__wrapper {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #bdb8b8;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #726f6f;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.menu-home-work {
  .v-list-item {
    min-height: 32px !important;
    font-weight: 600;
    &.new-card {
      color: #3887fe !important;
      font-weight: 500;
    }
    &.revising-card {
      color: #1bb763 !important;
      font-weight: 500;
    }
    .v-list-item__title {
      font-size: 14px;
    }
  }
}
</style>
<style lang="sass" scoped>
.v-data-table
  border: 1px solid rgba(0, 0, 0, 0.12)
  border-top-left-radius: 12px
  border-top-right-radius: 12px
::v-deep .v-data-table__wrapper
  .v-data-table-header
    background-color: #453FE3 !important
    th
      border-right: 1px solid
      color: #FFFFFF !important
      &:first-child
        border-top-left-radius: 12px
      &:last-child
        border-top-right-radius: 12px
        border-right: none
  tbody
    tr
      cursor: pointer
      td
        font-weight: 600
        &:nth-child(2)
          color: #3887FE
        &:nth-child(3)
          color: #1BB763
</style>
